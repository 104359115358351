import React from "react";
import "./jsFunControls.scss";
import Editor from "../../../../../components/Editor";
import { useSelector, useDispatch } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";

import { addJSFunc, removeJSFunc } from "../../../../../slices/docsSlice";
import OpenInNewWindow from "../../../../../components/PortableDiv";

const JsFunControls = ({ path, dynEle }) => {
  const dispatch = useDispatch();
  const { docs, indexPath, RRIP_P, RRIP_TB } = useSelector(
    (state) => state.docs
  );
  const { userForms, forms } = useSelector((state) => state.forms);
  const { document } = useSelector((state) => state.document);

  let ele = eval(dynEle);

  console.log(RRIP_P, RRIP_TB);

  return (
    <div className="editor relative w-full">
      {!ele.jsFun ? (
        <button
          className="h-[40px] px-2 bg-cyan-950 text-light-500"
          onClick={() =>
            dispatch(
              addJSFunc({
                path: path,
                value: `// You Have Access to the following Internal and External Dependencies.\n//${userForms.map(
                  (dep) => ` ${dep}`
                )}\nfunction(E, ${
                  ele.type === "para" ? "P" : "T"
                }, user, manager) {\n  \n}`,
              })
            )
          }
        >
          Add Function
        </button>
      ) : null}

      {ele.jsFun ? (
        <>
          <div
            style={{ width: "16px" }}
            className={`absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50 z-50`}
            onClick={() =>
              dispatch(
                removeJSFunc({
                  path: path,
                })
              )
            }
          >
            <IoCloseSharp />
          </div>
          <Editor
            path={path}
            value={
              ele.jsFun
                ? ele.jsFun
                : `function(CES, C, XT, MXT, XTS) {
  console.log(CES, C, XT, MXT, XTS);
}`
            }
          />
        </>
      ) : null}
    </div>
  );
};

export default JsFunControls;
