import React from "react";
import { removeEle } from "../../../../slices/docsSlice";
import { useDispatch } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const DeleteElement = ({ NIP, RRIP, index, path }) => {
  // console.log(RRIP);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <div
      className={`${
        NIP.startsWith(`${index}`) ? "flex" : "hidden"
      } absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50 z-30`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(removeEle(RRIP));
        let newRRIP = RRIP.slice(0, -1);
        let lastIndex = newRRIP[newRRIP.length - 1];
        let SLI = lastIndex.split("_");
        switch (SLI[1]) {
          case "P":
            navigate(`${path}/${newRRIP.join("/")}`);
            break;
          case "TC":
            navigate(`${path}/${newRRIP.join("/")}/SP/TBP/TR/TC`);
            break;
          case "TR":
            navigate(`${path}/${newRRIP.join("/")}`);
            break;
          case "S":
            navigate(`${path}/${newRRIP.join("/")}/SP`);
            break;
          case "Doc":
            navigate(`${path}/${newRRIP.join("/")}/DP`);
        }
      }}
    >
      <IoCloseSharp />
    </div>
  );
};

export default DeleteElement;
