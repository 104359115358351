import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userForms: undefined,
  forms: undefined,
  temp: undefined,
};

const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    setForms: (state, { payload }) => {
      let newUserForms = [];
      let newForms = {};
      // if (payload.internalDependency) {
      payload.internalDependency?.map((doc) => {
        newForms[doc.category] = doc.entryForm;
        if (doc.externalDependency.length > 0) {
          doc.externalDependency.map((dep) => {
            if (
              (dep.role === "User" && payload.userInfo.role === "User") ||
              payload.userInfo.role === "Manager"
            ) {
              newUserForms.push(dep.name);
              // Location for setting the type of dep
              if (dep.name === "Cibil") {
                newForms[dep.name] = [];
              } else {
                newForms[dep.name] = [];
              }
            }
          });
        }
        newUserForms.push(`${doc.category}_EF`);
        newForms[`${doc.category}_EF`] = doc.filledEF;
      });
      payload.document.externalDependency.map((dep) => {
        if (
          (dep.role === "User" && payload.userInfo.role === "User") ||
          payload.userInfo.role === "Manager"
        ) {
          newUserForms.push(dep.name);
          // Location for setting the type of dep
          if (dep.name === "Cibil") {
            newForms[dep.name] = [];
          } else {
            newForms[dep.name] = [];
          }
        }
      });
      newUserForms.push(`${payload.document.category}_EF`);
      newForms[payload.document.category] = payload.document.entryForm;
      newForms[`${payload.document.category}_EF`] = payload.document.filledEF;
      // } else {
      //   payload.document.externalDependency.map((dep) => {
      //     if (dep.role === "User" && payload.userInfo.role === "User") {
      //       newUserForms.push(dep.name);
      //     }
      //   });
      //   newUserForms.push(`${payload.document.category}_EF`);
      // }
      if (payload.userInfo.role === "User") {
        newUserForms.push("Submit");
      }
      state.userForms = newUserForms;
      state.forms = newForms;
    },
    resetForms: (state, { payload }) => {
      state.userForms = undefined;
      state.forms = undefined;
    },
    updateXT: (state, { payload }) => {
      const { EF, doc, sec, ele, xt, role, value } = payload;
      state.forms[EF][doc].ele[sec].ele[ele][role].XT[xt].name = value;
    },
    updateMXT: (state, { payload }) => {
      const { EF, doc, sec, ele, row, mxt, role, value } = payload;
      state.forms[EF][doc].ele[sec].ele[ele][role].MXT[row][mxt].name = value;
    },
    updateCondition: (state, { payload }) => {
      const { EF, doc, sec, ele, role, value } = payload;
      state.forms[EF][doc].ele[sec].ele[ele][role].condition = value;

      let arr = [];
      let MXT = state.forms[EF][doc].ele[sec].ele[ele][role].MXT[0];
      for (let i = 0; i < value; i++) {
        arr.push(MXT);
      }
      state.forms[EF][doc].ele[sec].ele[ele][role].MXT = arr;
    },
    setTemp: (state, { payload }) => {
      state.temp = payload;
    },
    getForms: (state, { payload }) => {
      state.userForms = payload.userForms;
      state.forms = payload.forms;
    },
    setFormsRunStatus: (state, { payload }) => {
      state.forms[payload.category].runStatus = payload.value;
    },
  },
});

export const {
  setForms,
  resetForms,
  getForms,
  updateXT,
  updateCondition,
  updateMXT,
  setTemp,
  setFormsRunStatus,
} = formsSlice.actions;

export default formsSlice.reducer;
