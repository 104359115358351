import { createSlice } from "@reduxjs/toolkit";
import set from "lodash/set";
import get from "lodash/get";

const initialState = {
  docId: undefined,
  docs: undefined,
  entryForm: [],
  category: "",
  runStatus: false,
  indexPath: "",
  dev: false,
  SIP: undefined,
  NIP_I: undefined,
  NIP_T: undefined,
  NIP_P: undefined,
  NIP_TC: undefined,
  NIP_TR: undefined,
  NIP_TB: undefined,
  NIP_H: undefined,
  NIP_F: undefined,
  NIP_S: undefined,
  NIP_DOC: undefined,
  RRIP_I: undefined,
  RRIP_T: undefined,
  RRIP_P: undefined,
  RRIP_TC: undefined,
  RRIP_TR: undefined,
  RRIP_TB: undefined,
  RRIP_H: undefined,
  RRIP_F: undefined,
  RRIP_S: undefined,
  RRIP_DOC: undefined,
};

const TEXT = {
  t: "",
  properties: {},
  type: "text",
};

const PARA = {
  ele: [],
  properties: {},
  name: 0,
  type: "para",
};

const CELL = {
  ele: [],
  properties: {},
  name: "",
  type: "cell",
};

const ROW = {
  ele: [],
  properties: {},
  name: "",
  type: "row",
};

const TABLE = {
  ele: [],
  properties: {},
  name: "",
  nor: 0,
  noc: 0,
  type: "table",
};

const SECTION = {
  ele: [],
  properties: {
    page: {
      size: {
        orientation: "portrait",
      },
      margin: {
        left: "1in",
        right: "1in",
        top: "1in",
        bottom: "1in",
      },
      pageWidth: "8.27in",
      pageHeight: "11.69in",
      borders: {
        pageBorderLeft: {
          style: "single",
          color: "000000",
          size: 1,
          space: 20,
        },
        pageBorderRight: {
          style: "single",
          color: "000000",
          size: 1,
          space: 20,
        },
        pageBorderTop: {
          style: "single",
          color: "000000",
          size: 1,
          space: 1,
        },
        pageBorderBottom: {
          style: "single",
          color: "000000",
          size: 1,
          space: 1,
        },
      },
    },
    column: 1,
    type: "continuous",
  },
  headers: {},
  footers: {},
  name: "",
  type: "section",
};

const returnEle = (state, path) => {
  const splitIndexPath = path;
  let newIndexPath = splitIndexPath
    .map((index) => index.split("_")[0])
    .join("_");

  let mainEle = null;
  let conditionMet = false;

  const setProperty = (ele, currIndex, currPath = "") => {
    const newPath =
      currPath !== "" ? `${currPath}_${currIndex}` : `${currIndex}`;
    if (!conditionMet) {
      if (newPath === newIndexPath) {
        mainEle = ele;
        conditionMet = true;
      } else if (ele.ele?.length > 0) {
        ele.ele.forEach((E, i) => setProperty(E, i, newPath));
      }
    }
  };

  state.docs.forEach((doc, i) => setProperty(doc, i));
  return mainEle;
};

export const returnHFEle = (state, path, layoutEle) => {
  let elePath = "docs";
  path.map((ele, i) => {
    let SE = ele.split("_");
    if (SE[1] === "S") {
      elePath =
        elePath +
        `[${SE[0]}].${layoutEle === "0_H" ? "headers.ele" : "footers.ele"}`;
    } else if (SE[1] !== "H" && SE[1] !== "F") {
      elePath =
        elePath + (i === path.length - 1 ? `[${SE[0]}]` : `[${SE[0]}].ele`);
    }
  });
  return get(state, elePath);
};

// const returnHFEI = (state, path, layoutEle) => {
//   let elePath = "docs";
//   let currIndex;
//   path.map((ele, i) => {
//     let SE = ele.split("_");
//     if (SE[1] === "S") {
//       elePath =
//         elePath +
//         `[${SE[0]}].${layoutEle === "0_H" ? "headers.ele" : "footers.ele"}`;
//     } else if (SE[1] !== "H" && SE[1] !== "F") {
//       elePath =
//         elePath + (i === path.length - 1 ? `[${SE[0]}]` : `[${SE[0]}].ele`);
//     }
//     currIndex = SE[0];
//   });
//   let ele = get(state, elePath);
//   return { ele, index: currIndex };
// };

const returnEI = (state, path) => {
  const splitIndexPath = path;
  let newIndexPath = splitIndexPath
    .map((index) => index.split("_")[0])
    .join("_");

  let mainEle = null;
  let mainIndex = null;
  let conditionMet = false;

  const setProperty = (ele, currIndex, currPath = "") => {
    const newPath =
      currPath !== "" ? `${currPath}_${currIndex}` : `${currIndex}`;
    if (!conditionMet) {
      if (newPath === newIndexPath) {
        mainEle = ele;
        mainIndex = currIndex;
        conditionMet = true;
      } else if (ele.ele?.length > 0) {
        ele.ele.forEach((E, i) => setProperty(E, i, newPath));
      }
    }
  };

  state.docs.forEach((doc, i) => setProperty(doc, i));
  return {
    ele: mainEle,
    index: mainIndex,
  };
};

const AliSwitch = (value) => {
  switch (value) {
    case "START":
      return "start";
    case "CENTER":
      return "center";
    case "END":
      return "end";
    case "LEFT":
      return "left";
    case "RIGHT":
      return "right";
    case "JUSTIFIED":
      return "both";
  }
};

const ShaTypSwitch = (value) => {
  switch (value) {
    case "SOLID":
      return "solid";
    case "CLEAR":
      return "clear";
    case "DIAGONAL_CROSS":
      return "diagCross";
    case "DIAGONAL_STRIPE":
      return "diagStripe";
    case "HORIZONTAL_CROSS":
      return "horzCross";
    case "HORIZONTAL_STRIPE":
      return "horzStripe";
    case "NIL":
      return "nil";
    case "PERCENT_5":
      return "pct5";
    case "PERCENT_10":
      return "pct10";
    case "PERCENT_12":
      return "pct12";
    case "PERCENT_15":
      return "pct15";
    case "PERCENT_20":
      return "pct20";
    case "PERCENT_25":
      return "pct25";
    case "PERCENT_30":
      return "pct30";
    case "PERCENT_35":
      return "pct35";
    case "PERCENT_37":
      return "pct37";
    case "PERCENT_40":
      return "pct40";
    case "PERCENT_45":
      return "pct45";
    case "PERCENT_50":
      return "pct50";
    case "PERCENT_55":
      return "pct55";
    case "PERCENT_60":
      return "pct60";
    case "PERCENT_62":
      return "pct62";
    case "PERCENT_65":
      return "pct65";
    case "PERCENT_70":
      return "pct70";
    case "PERCENT_75":
      return "pct75";
    case "PERCENT_80":
      return "pct80";
    case "PERCENT_85":
      return "pct85";
    case "PERCENT_87":
      return "pct87";
    case "PERCENT_90":
      return "pct90";
    case "PERCENT_95":
      return "pct95";
    case "REVERSE_DIAGONAL_STRIPE":
      return "reverseDiagStripe";
    case "THIN_DIAGONAL_CROSS":
      return "thinDiagCross";
    case "THIN_DIAGONAL_STRIPE":
      return "thinDiagStripe";
    case "THIN_HORIZONTAL_CROSS":
      return "thinHorzCross";
    case "THIN_REVERSE_DIAGONAL_STRIPE":
      return "thinReverseDiagStripe";
    case "THIN_VERTICAL_STRIPE":
      return "thinVertStripe";
    case "VERTICAL_STRIPE":
      return "vertStripe";
  }
};

const consoleFun = (value) => {
  console.log(JSON.parse(JSON.stringify(value)));
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setDocId: (state, { payload }) => {
      state.docId = payload;
    },
    setDocs: (state, { payload }) => {
      state.docs = payload;
    },
    setEntryForm(state, { payload }) {
      state.entryForm = payload;
    },
    setCategory: (state, { payload }) => {
      state.category = payload;
    },
    setRunStatus: (state, { payload }) => {
      state.runStatus = payload;
    },
    setDev: (state, { payload }) => {
      state.dev = !state.dev;
    },
    addDoc: (state, { payload }) => {
      state.docs.push({
        XTS: [],
        ele: [],
        properties: {
          font: "Trebuchet MS",
          fontSize: "11pt",
          tableWidth: {
            size: "100%",
          },
          verticalAlignment: "top",
          horizontalAlignment: "left",

          cellMargin: {
            left: "0.08in",
            right: "0.08in",
            top: "0.05in",
            bottom: "0.05in",
          },
          border: true,
          tableBorder: true,
          spacing: {
            before: "0pt",
            after: "8pt",
            // line: 0
          },
        },
        name: payload,
        type: "doc",
      });
    },
    setIndexPath: (state, { payload }) => {
      state.indexPath = payload;
      if (payload) {
        const splitIndexPath = payload.split("/");
        state.SIP = splitIndexPath;

        let RIP_I = [...splitIndexPath].reverse();
        let RIP_T = [...splitIndexPath].reverse();
        let RIP_P = [...splitIndexPath].reverse();
        let RIP_TC = [...splitIndexPath].reverse();
        let RIP_TR = [...splitIndexPath].reverse();
        let RIP_TB = [...splitIndexPath].reverse();
        let RIP_H = [...splitIndexPath].reverse();
        let RIP_F = [...splitIndexPath].reverse();
        let RIP_S = [...splitIndexPath].reverse();
        let RIP_DOC = [...splitIndexPath].reverse();

        let LII = RIP_I.findIndex((index) => index.split("_")[1] === "I");
        let LIT = RIP_T.findIndex((index) => index.split("_")[1] === "T");
        let LIP = RIP_P.findIndex((index) => index.split("_")[1] === "P");
        let LITC = RIP_TC.findIndex((index) => index.split("_")[1] === "TC");
        let LITR = RIP_TR.findIndex((index) => index.split("_")[1] === "TR");
        let LITB = RIP_TB.findIndex((index) => index.split("_")[1] === "TB");
        let LIH = RIP_H.findIndex((index) => index.split("_")[1] === "H");
        let LIF = RIP_F.findIndex((index) => index.split("_")[1] === "F");
        let LIS = RIP_S.findIndex((index) => index.split("_")[1] === "S");
        let LIDOC = RIP_DOC.findIndex((index) => index.split("_")[1] === "Doc");

        RIP_I.splice(0, LII);
        RIP_T.splice(0, LIT);
        RIP_P.splice(0, LIP);
        RIP_TC.splice(0, LITC);
        RIP_TR.splice(0, LITR);
        RIP_TB.splice(0, LITB);
        RIP_H.splice(0, LIH);
        RIP_F.splice(0, LIF);
        RIP_S.splice(0, LIS);
        RIP_DOC.splice(0, LIDOC);

        const RRIP_I = [...RIP_I].reverse();
        const RRIP_T = [...RIP_T].reverse();
        const RRIP_P = [...RIP_P].reverse();
        const RRIP_TC = [...RIP_TC].reverse();
        const RRIP_TR = [...RIP_TR].reverse();
        const RRIP_TB = [...RIP_TB].reverse();
        const RRIP_H = [...RIP_H].reverse();
        const RRIP_F = [...RIP_F].reverse();
        const RRIP_S = [...RIP_S].reverse();
        const RRIP_DOC = [...RIP_DOC].reverse();

        state.NIP_I = RRIP_I.map((index) => index.split("_")[0]).join("_");
        state.NIP_T = RRIP_T.map((index) => index.split("_")[0]).join("_");
        state.NIP_P = RRIP_P.map((index) => index.split("_")[0]).join("_");
        state.NIP_TC = RRIP_TC.map((index) => index.split("_")[0]).join("_");
        state.NIP_TR = RRIP_TR.map((index) => index.split("_")[0]).join("_");
        state.NIP_TB = RRIP_TB.map((index) => index.split("_")[0]).join("_");
        state.NIP_H = RRIP_H.map((index) => index.split("_")[0]).join("_");
        state.NIP_F = RRIP_F.map((index) => index.split("_")[0]).join("_");
        state.NIP_S = RRIP_S.map((index) => index.split("_")[0]).join("_");
        state.NIP_DOC = RRIP_DOC.map((index) => index.split("_")[0]).join("_");

        state.RRIP_I = LII !== -1 ? RRIP_I : undefined;
        state.RRIP_T = LIT !== -1 ? RRIP_T : undefined;
        state.RRIP_P = LIP !== -1 ? RRIP_P : undefined;
        state.RRIP_TC = LITC !== -1 ? RRIP_TC : undefined;
        state.RRIP_TR = LITR !== -1 ? RRIP_TR : undefined;
        state.RRIP_TB = LITB !== -1 ? RRIP_TB : undefined;
        state.RRIP_H = LIH !== -1 ? RRIP_H : undefined;
        state.RRIP_F = LIF !== -1 ? RRIP_F : undefined;
        state.RRIP_S = LIS !== -1 ? RRIP_S : undefined;
        state.RRIP_DOC = LIDOC !== -1 ? RRIP_DOC : undefined;

        // console.log(
        //   RRIP_I,
        //   RRIP_T,
        //   RRIP_P,
        //   RRIP_TC,
        //   RRIP_TR,
        //   RRIP_TB,
        //   RRIP_H,
        //   RRIP_F,
        //   RRIP_S,
        //   RRIP_DOC
        // );
      }
    },
    removeDoc: (state, { payload }) => {
      state.docs = state.docs.filter((doc) => doc.name !== payload);
      state.indexPath = "";
    },
    removeEle: (state, { payload }) => {
      const splitIndexPath = payload;
      let newIndexPath = splitIndexPath
        .map((index) => index.split("_")[0])
        .join("_");

      let conditionMet = false;

      const setProperty = (ele, currIndex, currPath = "", parentEle) => {
        const newPath =
          currPath !== "" ? `${currPath}_${currIndex}` : `${currIndex}`;
        if (!conditionMet) {
          if (newPath === newIndexPath) {
            let indexArr = newIndexPath.split("_");
            let eleIndex = indexArr[indexArr.length - 1];
            // state.indexPath = "";
            parentEle.ele.splice(eleIndex, 1);
            conditionMet = true;
          } else if (ele.ele?.length > 0) {
            ele.ele.forEach((E, i) => setProperty(E, i, newPath, ele));
          }
        }
      };

      state.docs.forEach((doc, i) => setProperty(doc, i));
      let newRRIP = payload.slice(0, -1);
      console.log(newRRIP.join("/"));
      state.indexPath = newRRIP.join("/");
    },
    addSection: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      const section = structuredClone(SECTION);
      section.name = payload.name ? payload.name : Date.now();
      ele.ele.push(section);
    },
    addPara: (state, { payload }) => {
      let ele;
      if (payload.layoutEle) {
        ele = returnHFEle(state, payload.path, payload.layoutEle);
      } else {
        ele = returnEle(state, payload.path);
      }
      let text = structuredClone(TEXT);
      text.t = "R";
      let para = structuredClone(PARA);
      para.ele.push(text);
      para.name = payload.name ? payload.name : ele.ele.length;
      if (payload.name) {
        para.user = {};
        para.manager = {};
      }
      console.log(payload.loc, payload.index);
      if (payload.loc) {
        console.log(payload.index);
        if (payload.loc === "after") {
          ele.ele.splice(payload.index + 1, 0, { ...para });
        } else {
          ele.ele.splice(payload.index, 0, { ...para });
        }
      } else {
        ele.ele.push({
          ...para,
        });
      }
    },
    addTable: (state, { payload }) => {
      let ele;
      if (payload.layoutEle) {
        ele = returnHFEle(state, payload.path, payload.layoutEle);
      } else {
        ele = returnEle(state, payload.path);
      }
      let Rows = [];
      for (let i = 0; i < payload.rows; i++) {
        let Cols = [];
        for (let j = 0; j < payload.cols; j++) {
          let text = structuredClone(TEXT);
          let para = structuredClone(PARA);
          let cell = structuredClone(CELL);
          para.name = cell.ele.length;
          para.ele.push(text);
          cell.ele.push(para);
          Cols.push({
            ...cell,
            name: j,
          });
        }
        const row = structuredClone(ROW);
        Rows.push({
          ...row,
          ele: Cols,
          name: i,
        });
      }
      const table = structuredClone(TABLE);
      if (payload.name) {
        table.user = {};
        table.manager = {};
      }
      ele.ele.push({
        ...table,
        ele: Rows,
        name: payload.name ? payload.name : ele.ele.length,
        nor: payload.rows,
        noc: payload.cols,
      });
      if (payload.path.length === 2) {
        let text = structuredClone(TEXT);
        text.t = "";
        let para = structuredClone(PARA);
        para.ele.push(text);
        para.name = "gap";
        ele.ele.push({
          ...para,
        });
      }
    },
    replaceTable: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      ele.ele.pop();
      let Rows = [];
      for (let i = 0; i < payload.rows; i++) {
        let Cols = [];
        for (let j = 0; j < payload.cols; j++) {
          let text = structuredClone(TEXT);
          let para = structuredClone(PARA);
          let cell = structuredClone(CELL);
          para.name = cell.ele.length;
          para.ele.push(text);
          cell.ele.push(para);
          Cols.push({
            ...cell,
            name: j,
          });
        }
        const row = structuredClone(ROW);
        Rows.push({
          ...row,
          ele: Cols,
          name: i,
        });
      }
      const table = structuredClone(TABLE);
      ele.ele = [
        {
          ...table,
          ele: Rows,
          name: payload.name ? payload.name : ele.ele.length,
          nor: payload.rows,
          noc: payload.cols,
        },
      ];
    },

    resetDocs: (state, { payload }) => {
      state.docId = undefined;
      state.docs = [];
      state.entryForm = [];
      state.category = "";
      state.indexPath = "";
      state.SIP = undefined;
      state.NIP_I = undefined;
      state.NIP_T = undefined;
      state.NIP_P = undefined;
      state.NIP_TC = undefined;
      state.NIP_TR = undefined;
      state.NIP_TB = undefined;
      state.NIP_S = undefined;
      state.NIP_DOC = undefined;
      state.RRIP_I = undefined;
      state.RRIP_T = undefined;
      state.RRIP_P = undefined;
      state.RRIP_TC = undefined;
      state.RRIP_TR = undefined;
      state.RRIP_TB = undefined;
      state.RRIP_S = undefined;
      state.RRIP_DOC = undefined;
    },

    setDocTabBorder: (state, { payload }) => {
      const doc = returnEle(state, payload.doc);
      doc.properties.tableBorder = payload.value === "false" ? false : true;
    },
    setDocTabWidth: (state, { payload }) => {
      const doc = returnEle(state, payload.doc);
      if (doc.properties.tableWidth) {
        doc.properties.tableWidth[payload.loc] = payload.value;
      } else {
        doc.properties.tableWidth = {
          [payload.loc]: payload.value,
        };
      }
    },
    setDocVerAlignment: (state, { payload }) => {
      const doc = returnEle(state, payload.doc);
      doc.properties.verticalAlignment = payload.value;
    },
    setDocHorAlignment: (state, { payload }) => {
      const doc = returnEle(state, payload.doc);
      doc.properties.horizontalAlignment = payload.value;
    },
    setDocFont: (state, { payload }) => {
      const doc = returnEle(state, payload.doc);
      doc.properties.font = payload.value;
    },
    setDocFontSize: (state, { payload }) => {
      const doc = returnEle(state, payload.doc);
      doc.properties.fontSize = payload.value;
    },
    setSpacing: (state, { payload }) => {
      const doc = returnEle(state, payload.doc);
      doc.properties.spacing[payload.loc] = payload.value;
    },
    setDocCellMargin: (state, { payload }) => {
      const doc = returnEle(state, payload.doc);
      doc.properties.cellMargin[payload.loc] = payload.value;
    },
    // Section Properties
    setSecOrientation: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      section.properties.page.size.orientation = payload.value;
    },
    setSecBorderStyle: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      if (section.properties.page.borders) {
        section.properties.page.borders[payload.loc] = {
          ...section.properties.page.borders[payload.loc],
          style: payload.value,
        };
      } else {
        section.properties.page.borders = {
          [payload.loc]: {
            style: payload.value,
          },
        };
      }
    },
    setSecBorderColor: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      if (section.properties.page.borders) {
        section.properties.page.borders[payload.loc] = {
          ...section.properties.page.borders[payload.loc],
          color: payload.value.replace("#", "").toUpperCase(),
        };
      } else {
        section.properties.page.borders = {
          [payload.loc]: {
            color: payload.value.replace("#", "").toUpperCase(),
          },
        };
      }
    },

    setSecBorderSize: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      if (section.properties.page.borders) {
        section.properties.page.borders[payload.loc] = {
          ...section.properties.page.borders[payload.loc],
          size: parseFloat(payload.value),
        };
      } else {
        section.properties.page.borders = {
          [payload.loc]: {
            size: parseFloat(payload.value),
          },
        };
      }
    },

    setSecBorderSpace: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      if (section.properties.page.borders) {
        section.properties.page.borders[payload.loc] = {
          ...section.properties.page.borders[payload.loc],
          space: parseFloat(payload.value),
        };
      } else {
        section.properties.page.borders = {
          [payload.loc]: {
            space: parseFloat(payload.value),
          },
        };
      }
    },

    setSecMargin: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      section.properties.page.margin[payload.loc] = payload.value;
    },
    setSecType: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      section.properties.type = payload.value;
    },
    setSecHeaderType: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      section.headers = {
        type: payload.type,
        ele: [],
      };
    },
    addHeaderPara: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      let text = structuredClone(TEXT);
      text.t = "R";
      let para = structuredClone(PARA);
      para.ele.push(text);
      para.name = payload.name ? payload.name : section.headers.ele.length;
      section.headers.ele.push({ ...para });
    },
    addHeaderTable: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      let Rows = [];
      for (let i = 0; i < payload.rows; i++) {
        let Cols = [];
        for (let j = 0; j < payload.cols; j++) {
          // let text = structuredClone(TEXT);
          // let para = structuredClone(PARA);
          let cell = structuredClone(CELL);
          // para.name = cell.ele.length;
          // para.ele.push(text);
          // cell.ele.push(para);
          Cols.push({
            ...cell,
            name: j,
          });
        }
        const row = structuredClone(ROW);
        Rows.push({
          ...row,
          ele: Cols,
          name: i,
        });
      }
      const table = structuredClone(TABLE);
      if (payload.name) {
        table.user = {};
        table.manager = {};
      }
      section.headers.ele.push({
        ...table,
        ele: Rows,
        name: payload.name ? payload.name : section.headers.ele.length,
        nor: payload.rows,
        noc: payload.cols,
      });
    },
    setSecFooterType: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      section.footers = {
        type: payload.type,
        ele: [],
      };
    },
    addFooterPara: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      let text = structuredClone(TEXT);
      text.t = "R";
      let para = structuredClone(PARA);
      para.ele.push(text);
      para.name = payload.name ? payload.name : section.footers.ele.length;
      section.footers.ele.push({ ...para });
    },
    addFooterTable: (state, { payload }) => {
      const section = returnEle(state, payload.section);
      let Rows = [];
      for (let i = 0; i < payload.rows; i++) {
        let Cols = [];
        for (let j = 0; j < payload.cols; j++) {
          // let text = structuredClone(TEXT);
          // let para = structuredClone(PARA);
          let cell = structuredClone(CELL);
          // para.name = cell.ele.length;
          // para.ele.push(text);
          // cell.ele.push(para);
          Cols.push({
            ...cell,
            name: j,
          });
        }
        const row = structuredClone(ROW);
        Rows.push({
          ...row,
          ele: Cols,
          name: i,
        });
      }
      const table = structuredClone(TABLE);
      if (payload.name) {
        table.user = {};
        table.manager = {};
      }
      section.footers.ele.push({
        ...table,
        ele: Rows,
        name: payload.name ? payload.name : section.footers.ele.length,
        nor: payload.rows,
        noc: payload.cols,
      });
    },
    // Para Properties
    updateAlignment: (state, { payload }) => {
      let para;
      if (payload.layoutEle) {
        para = returnHFEle(state, payload.para, payload.layoutEle);
      } else {
        para = returnEle(state, payload.para);
      }
      para.properties.alignment = payload.value;
    },
    addThematicBreak: (state, { payload }) => {
      let para;
      if (payload.layoutEle) {
        para = returnHFEle(state, payload.para, payload.layoutEle);
      } else {
        para = returnEle(state, payload.para);
      }
      if (payload.value === "true") {
        para.properties.thematicBreak = true;
      } else {
        delete para.properties.thematicBreak;
      }
    },
    addPageBreak: (state, { payload }) => {
      let para;
      if (payload.layoutEle) {
        para = returnHFEle(state, payload.para, payload.layoutEle);
      } else {
        para = returnEle(state, payload.para);
      }
      if (payload.value === "true") {
        para.properties.pageBreak = true;
      } else {
        delete para.properties.pageBreak;
      }
    },
    updateBorder: (state, { payload }) => {
      let para;
      if (payload.layoutEle) {
        para = returnHFEle(state, payload.para, payload.layoutEle);
      } else {
        para = returnEle(state, payload.para);
      }
      if (payload.value === "true") {
        para.properties.border = true;
      } else {
        delete para.properties.border;
      }
    },
    updateSpacing: (state, { payload }) => {
      let para;
      if (payload.layoutEle) {
        para = returnHFEle(state, payload.para, payload.layoutEle);
      } else {
        para = returnEle(state, payload.para);
      }
      if (para.properties.spacing) {
        para.properties.spacing[payload.loc] = payload.value;
      } else {
        para.properties.spacing = {
          [payload.loc]: payload.value,
        };
      }
    },
    // Para Indentation
    updateParaIndent: (state, { payload }) => {
      let para;
      if (payload.layoutEle) {
        para = returnHFEle(state, payload.para, payload.layoutEle);
      } else {
        para = returnEle(state, payload.para);
      }
      if (para.properties.indent) {
        para.properties.indent[payload.loc] = payload.value;
      } else {
        para.properties.indent = {
          [payload.loc]: payload.value,
        };
      }
    },
    addTextToPara: (state, { payload }) => {
      let para;
      if (payload.layoutEle) {
        para = returnHFEle(state, payload.para, payload.layoutEle);
      } else {
        para = returnEle(state, payload.para);
      }
      let text = structuredClone(TEXT);
      text.t = "R";
      para.ele.push(text);
    },
    addImageToPara: (state, { payload }) => {
      let para;
      if (payload.layoutEle) {
        para = returnHFEle(state, payload.para, payload.layoutEle);
      } else {
        para = returnEle(state, payload.para);
      }
      let image = { ...payload.image };
      delete image.agency;
      image.type = "image";
      image.properties = {
        transformation: image.transformation,
      };
      delete image._id;
      delete image.transformation;
      para.ele.push(image);
    },
    // Common Function
    updateShading: (state, { payload }) => {
      let ele;
      if (payload.layoutEle) {
        ele = returnHFEle(state, payload.path, payload.layoutEle);
      } else {
        ele = returnEle(state, payload.path);
      }

      if (ele.properties.shading) {
        if (payload.loc === "color" || payload.loc === "fill") {
          ele.properties.shading[payload.loc] = payload.value
            .replace("#", "")
            .toUpperCase();
        } else {
          ele.properties.shading[payload.loc] = ShaTypSwitch(payload.value);
        }
      } else {
        switch (payload.loc) {
          case "color":
            ele.properties.shading = {
              color: payload.value.replace("#", "").toUpperCase(),
              fill: "FFFFFF",
              type: "solid",
            };
            break;
          case "fill":
            ele.properties.shading = {
              color: "000000",
              fill: payload.value.replace("#", "").toUpperCase(),
              type: "solid",
            };
            break;
          case "type":
            ele.properties.shading = {
              color: "000000",
              fill: "FFFFFF",
              type: ShaTypSwitch(payload.value),
            };
            break;
        }
      }
    },
    // Image Properties
    updateImageTransformation: (state, { payload }) => {
      let image;
      if (payload.layoutEle) {
        image = returnHFEle(state, payload.image, payload.layoutEle);
      } else {
        image = returnEle(state, payload.image);
      }
      image.properties.transformation[payload.dimension] = payload.value;
    },
    // Text Properties
    updateText: (state, { payload }) => {
      let text;
      if (payload.layoutEle) {
        text = returnHFEle(state, payload.text, payload.layoutEle);
      } else {
        text = returnEle(state, payload.text);
      }
      text.t = payload.value;
    },
    updateTextFont: (state, { payload }) => {
      let text;
      if (payload.layoutEle) {
        text = returnHFEle(state, payload.text, payload.layoutEle);
      } else {
        text = returnEle(state, payload.text);
      }
      text.properties.font = payload.value;
    },
    updateTextSize: (state, { payload }) => {
      let text;
      if (payload.layoutEle) {
        text = returnHFEle(state, payload.text, payload.layoutEle);
      } else {
        text = returnEle(state, payload.text);
      }
      text.properties.fontSize = payload.value;
    },
    updateTextCasing: (state, { payload }) => {
      let text;
      if (payload.layoutEle) {
        text = returnHFEle(state, payload.text, payload.layoutEle);
      } else {
        text = returnEle(state, payload.text);
      }
      if (payload.value === "true") {
        text.properties.allCaps = true;
      } else {
        delete text.properties.allCaps;
      }
    },
    updateTextBold: (state, { payload }) => {
      let text;
      if (payload.layoutEle) {
        text = returnHFEle(state, payload.text, payload.layoutEle);
      } else {
        text = returnEle(state, payload.text);
      }
      if (payload.value === "true") {
        text.properties.bold = true;
      } else {
        delete text.properties.bold;
      }
    },
    updateTextItalics: (state, { payload }) => {
      let text;
      if (payload.layoutEle) {
        text = returnHFEle(state, payload.text, payload.layoutEle);
      } else {
        text = returnEle(state, payload.text);
      }
      if (payload.value === "true") {
        text.properties.italics = true;
      } else {
        delete text.properties.italics;
      }
    },
    updateTextUnderline: (state, { payload }) => {
      let text;
      if (payload.layoutEle) {
        text = returnHFEle(state, payload.text, payload.layoutEle);
      } else {
        text = returnEle(state, payload.text);
      }
      if (payload.value === "true") {
        text.properties.underline = true;
      } else {
        delete text.properties.underline;
      }
    },
    updateTextColor: (state, { payload }) => {
      let text;
      if (payload.layoutEle) {
        text = returnHFEle(state, payload.text, payload.layoutEle);
      } else {
        text = returnEle(state, payload.text);
      }
      text.properties.color = payload.value.replace("#", "").toUpperCase();
    },
    // Table Properties
    updateTableWidth: (state, { payload }) => {
      let table;
      if (payload.layoutEle) {
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        table = returnEle(state, payload.table);
      }
      if (table.properties.width) {
        table.properties.width[payload.loc] = payload.value;
      } else {
        table.properties.width = {
          [payload.loc]: payload.value,
        };
      }
    },
    updateTableAlignment: (state, { payload }) => {
      let table;
      if (payload.layoutEle) {
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        table = returnEle(state, payload.table);
      }
      table.properties.alignment = AliSwitch(payload.value);
    },
    updateTableVerAlignment: (state, { payload }) => {
      let table;
      if (payload.layoutEle) {
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        table = returnEle(state, payload.table);
      }
      table.properties.verticalAlignment = payload.value;
    },
    updateTableHorAlignment: (state, { payload }) => {
      let table;
      if (payload.layoutEle) {
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        table = returnEle(state, payload.table);
      }
      table.properties.horizontalAlignment = payload.value;
    },
    updateTableBorder: (state, { payload }) => {
      let table;
      if (payload.layoutEle) {
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        table = returnEle(state, payload.table);
      }
      table.properties.borders = payload.value === "false" ? false : true;
    },
    updateTableTextFont: (state, { payload }) => {
      let table;
      if (payload.layoutEle) {
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        table = returnEle(state, payload.table);
      }
      table.properties.font = payload.value;
    },
    updateTableTextSize: (state, { payload }) => {
      let table;
      if (payload.layoutEle) {
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        table = returnEle(state, payload.table);
      }
      table.properties.fontSize = payload.value;
    },
    setTableCellMargin: (state, { payload }) => {
      let table;
      if (payload.layoutEle) {
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        table = returnEle(state, payload.table);
      }
      const doc = returnEle(state, payload.doc);
      if (table.properties.margins) {
        table.properties.margins[payload.loc] = payload.value;
      } else {
        switch (payload.loc) {
          case "left":
            table.properties.margins = {
              marginUnitType: doc.properties.cellMargin.marginUnitType,
              left: payload.value,
              right: doc.properties.cellMargin.right,
              top: doc.properties.cellMargin.top,
              bottom: doc.properties.cellMargin.bottom,
            };
            break;
          case "right":
            table.properties.margins = {
              marginUnitType: doc.properties.cellMargin.marginUnitType,
              left: doc.properties.cellMargin.left,
              right: payload.value,
              top: doc.properties.cellMargin.top,
              bottom: doc.properties.cellMargin.bottom,
            };
            break;
          case "top":
            table.properties.margins = {
              marginUnitType: doc.properties.cellMargin.marginUnitType,
              left: doc.properties.cellMargin.left,
              right: doc.properties.cellMargin.right,
              top: payload.value,
              bottom: doc.properties.cellMargin.bottom,
            };
            break;
          case "bottom":
            table.properties.margins = {
              marginUnitType: doc.properties.cellMargin.marginUnitType,
              left: doc.properties.cellMargin.left,
              right: doc.properties.cellMargin.right,
              top: doc.properties.cellMargin.top,
              bottom: payload.value,
            };
            break;
        }
      }
    },
    addColumnToTable: (state, { payload }) => {
      let table;
      if (payload.layoutEle) {
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        table = returnEle(state, payload.table);
      }
      table.ele.map((R) => {
        let c = structuredClone(CELL);
        c.name = table.noc;
        R.ele.push(c);
      });
      table.noc = table.noc + 1;
    },
    addRowToTable: (state, { payload }) => {
      let table;
      if (payload.layoutEle) {
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        table = returnEle(state, payload.table);
      }
      let arr = [];
      for (let i = 0; i < table.noc; i++) {
        let text = structuredClone(TEXT);
        let para = structuredClone(PARA);
        let c = structuredClone(CELL);
        para.name = c.ele.length;
        para.ele.push(text);
        c.ele.push(para);
        c.name = i;
        arr.push(c);
      }
      let row = structuredClone(ROW);
      row.ele = arr;
      row.name = table.nor;
      table.ele.push(row);
      table.nor = table.nor + 1;
    },
    // Row Properties
    updateRowHeight: (state, { payload }) => {
      let row;
      if (payload.layoutEle) {
        row = returnHFEle(state, payload.row, payload.layoutEle);
      } else {
        row = returnEle(state, payload.row);
      }
      if (row.properties.height) {
        row.properties.height[payload.loc] = payload.value;
      } else {
        row.properties.height = {
          [payload.loc]: payload.value,
        };
      }
    },
    setDefaultCellWidth: (state, { payload }) => {
      let row;
      if (payload.layoutEle) {
        row = returnHFEle(state, payload.row, payload.layoutEle);
      } else {
        row = returnEle(state, payload.row);
      }
      if (row.properties.width) {
        row.properties.width[payload.loc] = payload.value;
      } else {
        row.properties.width = {
          [payload.loc]: payload.value,
        };
      }
    },

    // Cell Properties
    updateCellMargin: (state, { payload }) => {
      let cell;
      let table;
      if (payload.layoutEle) {
        cell = returnHFEle(state, payload.cell, payload.layoutEle);
        table = returnHFEle(state, payload.table, payload.layoutEle);
      } else {
        cell = returnEle(state, payload.cell);
        table = returnEle(state, payload.table);
      }
      const doc = returnEle(state, payload.doc);
      if (cell.properties.margins) {
        cell.properties.margins[payload.loc] = payload.value;
      } else if (table.properties.margins) {
        switch (payload.loc) {
          case "left":
            cell.properties.margins = {
              marginUnitType: table.properties.margins.marginUnitType,
              left: payload.value,
              right: table.properties.margins.right,
              top: table.properties.margins.top,
              bottom: table.properties.margins.bottom,
            };
            break;
          case "right":
            cell.properties.margins = {
              marginUnitType: table.properties.margins.marginUnitType,
              left: table.properties.margins.left,
              right: payload.value,
              top: table.properties.margins.top,
              bottom: table.properties.margins.bottom,
            };
            break;
          case "top":
            cell.properties.margins = {
              marginUnitType: table.properties.margins.marginUnitType,
              left: table.properties.margins.left,
              right: table.properties.margins.right,
              top: payload.value,
              bottom: table.properties.margins.bottom,
            };
            break;
          case "bottom":
            cell.properties.margins = {
              marginUnitType: table.properties.margins.marginUnitType,
              left: table.properties.margins.left,
              right: table.properties.margins.right,
              top: table.properties.margins.top,
              bottom: payload.value,
            };
            break;
        }
      } else {
        switch (payload.loc) {
          case "left":
            cell.properties.margins = {
              marginUnitType: doc.properties.cellMargin.marginUnitType,
              left: payload.value,
              right: doc.properties.cellMargin.right,
              top: doc.properties.cellMargin.top,
              bottom: doc.properties.cellMargin.bottom,
            };
            break;
          case "right":
            cell.properties.margins = {
              marginUnitType: doc.properties.cellMargin.marginUnitType,
              left: doc.properties.cellMargin.left,
              right: payload.value,
              top: doc.properties.cellMargin.top,
              bottom: doc.properties.cellMargin.bottom,
            };
            break;
          case "top":
            cell.properties.margins = {
              marginUnitType: doc.properties.cellMargin.marginUnitType,
              left: doc.properties.cellMargin.left,
              right: doc.properties.cellMargin.right,
              top: payload.value,
              bottom: doc.properties.cellMargin.bottom,
            };
            break;
          case "bottom":
            cell.properties.margins = {
              marginUnitType: doc.properties.cellMargin.marginUnitType,
              left: doc.properties.cellMargin.left,
              right: doc.properties.cellMargin.right,
              top: doc.properties.cellMargin.top,
              bottom: payload.value,
            };
            break;
        }
      }
    },
    updateCellBorder: (state, { payload }) => {
      let cell;
      if (payload.layoutEle) {
        cell = returnHFEle(state, payload.cell, payload.layoutEle);
      } else {
        cell = returnEle(state, payload.cell);
      }
      cell.properties.borders = payload.value === "false" ? false : true;
    },
    setCellWidth: (state, { payload }) => {
      let cell;
      if (payload.layoutEle) {
        cell = returnHFEle(state, payload.cell, payload.layoutEle);
      } else {
        cell = returnEle(state, payload.cell);
      }
      if (cell.properties.width) {
        cell.properties.width[payload.loc] = payload.value;
      } else {
        cell.properties.width = {
          [payload.loc]: payload.value,
        };
      }
    },
    // updateCellShading: (state, { payload }) => {
    //   const ele = returnEle(state, payload.path);
    //   if (payload.loc === "color") {
    //     ele.properties = {
    //       ...ele.properties,
    //       shading: {
    //         [payload.loc]: payload.value.replace("#", "").toUpperCase(),
    //         fill: "000000",
    //         type: "solid",
    //       },
    //     };
    //   } else {
    //     ele.properties.shading[payload.loc] =
    //       payload.loc !== "type"
    //         ? payload.value.replace("#", "").toUpperCase()
    //         : payload.value;
    //   }
    // },
    updateColSpan: (state, { payload }) => {
      let newCell;
      let newRow;
      let newTable;
      let cell;
      let row;
      let table;
      let CI;
      let RI;
      let TI;
      if (payload.layoutEle) {
        cell = returnHFEle(state, payload.path, payload.layoutEle);
        row = returnHFEle(state, payload.row, payload.layoutEle);
        table = returnHFEle(state, payload.table, payload.layoutEle);
        CI = payload.path[payload.path.length - 1].split("_")[0];
        RI = payload.row[payload.row.length - 1].split("_")[0];
        TI = payload.table[payload.table.length - 1].split("_")[0];
      } else {
        newCell = returnEI(state, payload.path);
        newRow = returnEI(state, payload.row);
        newTable = returnEI(state, payload.table);
        cell = newCell.ele;
        row = newRow.ele;
        table = newTable.ele;
        CI = newCell.index;
        RI = newRow.index;
        TI = newTable.index;
      }

      let NCS = parseFloat(payload.value);

      const doesColspanExists = (cellProps) => {
        if (cellProps.columnSpan) {
          return cellProps.columnSpan;
        }
        return -1;
      };
      const doesRowspanExists = (cellProps) => {
        if (cellProps.rowSpan) {
          return cellProps.rowSpan;
        }
        return -1;
      };

      const checkNextCellSpanning = (cellProps) => {
        if (cellProps.columnSpan || cellProps.rowSpan) {
          return false;
        }
        return true;
      };

      const findClosestNumber = (arr, num) => {
        // consoleFun(arr);
        let closest = null;
        let minDifference = Infinity;
        for (let n of arr) {
          if (n.name === num) {
            return n.name;
          }
          let difference = Math.abs(n.name - num);
          if (difference < minDifference) {
            minDifference = difference;
            closest = n.name;
          }
        }
        return closest;
      };

      let RS = doesRowspanExists(cell.properties);
      let CCS = doesColspanExists(cell.properties);

      if (RS === -1 && CCS === -1) {
        if (NCS > 1) {
          let count = -1;
          for (let i = CI + 1; i < NCS + CI; i++) {
            if (row.ele[i]) {
              if (!checkNextCellSpanning(row.ele[i].properties)) {
                count++;
                break;
              }
            } else {
              count++;
              break;
            }
          }
          if (count === -1) {
            if (NCS > 1) {
              cell.properties.columnSpan = NCS;
            } else {
              delete cell.properties.columnSpan;
            }
            let diff = NCS - 1;
            //let diff = NCS + CI - (CI + 1); // Why instead of NCS - 1?
            row.ele.splice(CI + 1, diff);
          }
        }
      } else if (CCS > 1 && RS === -1) {
        if (NCS > CCS) {
          let SD = NCS - CCS; // Span Differance
          let count = -1;
          for (let i = CI + 1; i < SD + CI + 1; i++) {
            if (row.ele[i]) {
              if (!checkNextCellSpanning(row.ele[i].properties)) {
                count++;
                break;
              }
            } else {
              count++;
              break;
            }
          }
          if (count === -1) {
            if (NCS > 1) {
              cell.properties.columnSpan = NCS;
            } else {
              delete cell.properties.columnSpan;
            }
            let diff = SD;
            row.ele.splice(CI + 1, diff);
          }
        } else if (NCS < CCS) {
          let SD = CCS - NCS;
          if (NCS > 1) {
            cell.properties.columnSpan = NCS;
          } else {
            delete cell.properties.columnSpan;
          }
          let arr = [];
          for (let i = 0; i < SD; i++) {
            let c = structuredClone(CELL);
            c.name = cell.name + NCS + i;
            arr.push(c);
          }
          row.ele.splice(CI + 1, 0, ...arr);
        }
      } else if (CCS === -1 && RS > 1) {
        if (NCS > 1) {
          let CN = cell.name;
          let count = -1;
          outerloop: for (let i = RI; i < RS + RI; i++) {
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === CN + 1) {
                if (table.ele[i].ele[j]) {
                  if (!checkNextCellSpanning(table.ele[i].ele[j].properties)) {
                    count++;
                    break outerloop;
                  }
                } else {
                  count++;
                  break outerloop;
                }
                break;
              }
            }
          }
          if (count === -1) {
            if (NCS > 1) {
              cell.properties.columnSpan = NCS;
            } else {
              delete cell.properties.columnSpan;
            }
            let diff = NCS - 1;
            for (let i = RI; i < RS + RI; i++) {
              for (let j = 0; j < table.ele[i].ele.length; j++) {
                if (table.ele[i].ele[j].name === CN + 1) {
                  table.ele[i].ele.splice(j, diff);
                }
              }
            }
          }
        }
      } else if (CCS > 1 && RS > 1) {
        if (NCS > CCS) {
          let SD = NCS - CCS;
          let count = -1;
          outerloop: for (let i = RI; i < RS + RI; i++) {
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              // if (
              //   table.ele[i].ele[j].name <= cell.properties.columnSpan + SD &&
              //   table.ele[i].ele[j].name !== cell.name
              // ) {
              if (
                table.ele[i].ele[j].name >= CCS + cell.name &&
                table.ele[i].ele[j].name < NCS + cell.name
              ) {
                if (table.ele[i].ele[j]) {
                  if (!checkNextCellSpanning(table.ele[i].ele[j].properties)) {
                    count++;
                    break outerloop;
                  }
                } else {
                  count++;
                  break outerloop;
                }
                break;
              }
            }
          }
          if (count === -1) {
            let diff = SD;
            for (let i = RI; i < RS + RI; i++) {
              for (let j = 0; j < table.ele[i].ele.length; j++) {
                if (
                  // table.ele[i].ele[j].name === cell.properties.columnSpan && // check this
                  // table.ele[i].ele[j].name !== cell.name
                  table.ele[i].ele[j].name ===
                  CCS + cell.name
                ) {
                  table.ele[i].ele.splice(j, diff);
                }
              }
            }
            if (NCS > 1) {
              cell.properties.columnSpan = NCS;
            } else {
              delete cell.properties.columnSpan;
            }
          }
        } else if (NCS < CCS) {
          let SD = CCS - NCS;
          for (let i = RI; i < RS + RI; i++) {
            let arr = [];
            for (let j = 0; j < SD; j++) {
              let c = structuredClone(CELL);
              // c.name = cell.properties.columnSpan - SD + j;
              c.name = cell.name + cell.properties.columnSpan - (j + 1);
              arr.push(c);
            }
            arr = arr.reverse();
            if (table.ele[i].ele.length === 0) {
              table.ele[i].ele.splice(1, 0, ...arr);
            } else {
              let NN = findClosestNumber(table.ele[i].ele, cell.name); // Nearest Number
              for (let j = 0; j < table.ele[i].ele.length; j++) {
                if (table.ele[i].ele[j].name === cell.name) {
                  table.ele[i].ele.splice(j + 1, 0, ...arr);
                  break;
                } else if (table.ele[i].ele[j].name === NN) {
                  if (NN > cell.name) {
                    table.ele[i].ele.splice(j, 0, ...arr);
                  } else {
                    table.ele[i].ele.splice(j + 1, 0, ...arr);
                  }
                  break;
                }
              }
            }
          }
          if (NCS > 1) {
            cell.properties.columnSpan = NCS;
          } else {
            delete cell.properties.columnSpan;
          }
        }
      }
    },
    updateRowSpan: (state, { payload }) => {
      const { ele: cell, index: CI } = returnEI(state, payload.path); // CI = Cell Index
      const { ele: row, index: RI } = returnEI(state, payload.row); // RI = Row Index
      const { ele: table, index: TI } = returnEI(state, payload.table); // TI = Table Index

      let NRS = parseFloat(payload.value);

      const doesRowspanExists = (cellProps) => {
        if (cellProps.rowSpan) {
          return cellProps.rowSpan;
        }
        return -1;
      };
      const doesColspanExists = (cellProps) => {
        if (cellProps.columnSpan) {
          return cellProps.columnSpan;
        }
        return -1;
      };

      const checkNextCellSpanning = (cellProps) => {
        if (cellProps.columnSpan || cellProps.rowSpan) {
          return false;
        }
        return true;
      };

      const findClosestNumber = (arr, num) => {
        // consoleFun(arr);
        let closest = null;
        let minDifference = Infinity;
        for (let n of arr) {
          if (n.name === num) {
            return n.name;
          }
          let difference = Math.abs(n.name - num);
          if (difference < minDifference) {
            minDifference = difference;
            closest = n.name;
          }
        }
        return closest;
      };

      let CS = doesColspanExists(cell.properties);
      let CRS = doesRowspanExists(cell.properties);

      if (CS === -1 && CRS === -1) {
        if (NRS > 1) {
          let count = -1;
          let CN = cell.name;
          outerloop: for (let i = RI; i < NRS + RI; i++) {
            let count2 = 0;
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === CN) {
                count2++;
                if (!checkNextCellSpanning(table.ele[i].ele[j].properties)) {
                  count++;
                  break outerloop;
                }
                break;
              }
            }
            if (count2 === 0) {
              count++;
              break;
            }
          }
          if (count === -1) {
            if (NRS > 1) {
              cell.properties.rowSpan = NRS;
            } else {
              delete cell.properties.rowSpan;
            }
            for (let i = RI + 1; i < NRS + RI; i++) {
              table.ele[i].ele.map((C, j) => {
                if (C.name === CN) {
                  table.ele[i].ele.splice(j, 1);
                }
              });
            }
          }
        }
      } else if (CRS > 1 && CS === -1) {
        if (NRS > CRS) {
          let SD = NRS - CRS; // Span Differance
          let count = -1;
          let CN = cell.name;
          outerloop: for (let i = RI + 1; i < SD + RI + 1; i++) {
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === CN) {
                if (table.ele[i].ele[j]) {
                  if (!checkNextCellSpanning(table.ele[i].ele[j].properties)) {
                    count++;
                    break outerloop;
                  }
                } else {
                  count++;
                  break outerloop;
                }
                break;
              }
            }
          }

          if (count === -1) {
            if (NRS > 1) {
              cell.properties.rowSpan = NRS;
            } else {
              delete cell.properties.rowSpan;
            }
            for (let i = RI + 1; i < NRS + RI; i++) {
              table.ele[i].ele.map((C, j) => {
                if (C.name === CN) {
                  table.ele[i].ele.splice(j, 1);
                }
              });
            }
          }
        } else if (NRS < CRS) {
          let SD = CRS - NRS; // Span Differance
          let CN = cell.name;
          let c = structuredClone(CELL);
          c.name = CN;
          // for (let i = RI + NRS; i < cell.properties.rowSpan; i++) {
          for (let i = RI + NRS; i < RI + cell.properties.rowSpan; i++) {
            if (table.ele[i].ele.length === 0) {
              table.ele[i].ele.splice(1, 0, c);
            } else {
              let NN = findClosestNumber(table.ele[i].ele, CN);
              for (let j = 0; j < table.ele[i].ele.length; j++) {
                if (table.ele[i].ele[j].name === CN) {
                  table.ele[i].ele.splice(j + 1, 0, c);
                  break;
                } else if (table.ele[i].ele[j].name === NN) {
                  if (NN > CN) {
                    table.ele[i].ele.splice(j, 0, c);
                  } else {
                    table.ele[i].ele.splice(j + 1, 0, c);
                  }
                  break;
                }
              }
            }
          }
          if (NRS > 1) {
            cell.properties.rowSpan = NRS;
          } else {
            delete cell.properties.rowSpan;
          }
        }
      } else if (CRS === -1 && CS > 1) {
        if (NRS > 1) {
          let count = -1;
          let CN = cell.name;
          outerloop: for (let i = RI + 1; i < RI + NRS; i++) {
            let count2 = 0;
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === CN) {
                count2++;
                for (let k = 0; k < CS; k++) {
                  if (table.ele[i].ele[k + j]) {
                    if (
                      !checkNextCellSpanning(table.ele[i].ele[k + j].properties)
                    ) {
                      count++;
                      break outerloop;
                    }
                  } else {
                    count++;
                    break outerloop;
                  }
                }
                break;
              }
            }
            if (count2 === 0) {
              count++;
              break;
            }
          }
          if (count === -1) {
            let diff = CS;
            for (let i = RI + 1; i < NRS + RI; i++) {
              for (let j = 0; j < table.ele[i].ele.length; j++) {
                if (table.ele[i].ele[j].name === CN) {
                  table.ele[i].ele.splice(j, diff);
                }
              }
            }
            if (NRS > 1) {
              cell.properties.rowSpan = NRS;
            } else {
              delete cell.properties.rowSpan;
            }
          }
        }
      } else if (CRS > 1 && CS > 1) {
        if (NRS > CRS) {
          let SD = NRS - CRS; // Span Differance
          let count = -1;
          let CN = cell.name;
          outerloop: for (let i = RI + CRS; i < RI + NRS; i++) {
            let count2 = 0;
            for (let j = 0; j < table.ele[i].ele.length; j++) {
              if (table.ele[i].ele[j].name === CN) {
                count2++;
                for (let k = 0; k < CS; k++) {
                  if (table.ele[i].ele[k + j]) {
                    if (
                      !checkNextCellSpanning(table.ele[i].ele[k + j].properties)
                    ) {
                      count++;
                      break outerloop;
                    }
                  } else {
                    count++;
                    break outerloop;
                  }
                }
              }
              break;
            }
            if (count2 === 0) {
              count++;
              break;
            }
          }
          if (count === -1) {
            let diff = CS;
            for (let i = RI + CRS; i < RI + NRS; i++) {
              for (let j = 0; j < table.ele[i].ele.length; j++) {
                if (table.ele[i].ele[j].name === CN) {
                  table.ele[i].ele.splice(j, diff);
                  break;
                }
              }
            }
            if (NRS > 1) {
              cell.properties.rowSpan = NRS;
            } else {
              delete cell.properties.rowSpan;
            }
          }
        } else if (NRS < CRS) {
          let CN = cell.name;
          for (let i = RI + NRS; i < RI + CRS; i++) {
            let arr = [];
            for (let j = 0; j < CS; j++) {
              let c = structuredClone(CELL);
              c.name = cell.name + CS - (j + 1);
              arr.push(c);
            }
            arr = arr.reverse();
            if (table.ele[i].ele.length === 0) {
              table.ele[i].ele.splice(1, 0, ...arr);
            } else {
              let NN = findClosestNumber(table.ele[i].ele, cell.name);
              for (let j = 0; j < table.ele[i].ele.length; j++) {
                if (table.ele[i].ele[j].name === NN) {
                  if (NN > cell.name) {
                    table.ele[i].ele.splice(j, 0, ...arr);
                  } else {
                    table.ele[i].ele.splice(j + 1, 0, ...arr);
                  }
                  break;
                }
              }
            }
            if (NRS > 1) {
              cell.properties.rowSpan = NRS;
            } else {
              delete cell.properties.rowSpan;
            }
          }
        }
      }
    },

    // XT Properties
    addXTInput: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      if (ele[payload.operator].XT) {
        ele[payload.operator].XT.push({
          name: "",
          dv: "",
        });
      } else {
        ele[payload.operator].XT = [
          {
            name: "",
            dv: "",
          },
        ];
      }
    },
    addXTDropDown: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      if (ele[payload.operator].XT) {
        ele[payload.operator].XT.push({
          name: payload.name,
          dv: payload.name[0],
        });
      } else {
        ele[payload.operator].XT = [
          {
            name: payload.name,
            dv: payload.name[0],
          },
        ];
      }
    },
    updateXTName: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      ele[payload.operator].XT[payload.index].name = payload.value;
    },
    updateXTDv: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      const XT = ele[payload.operator].XT[payload.index];
      XT.dv = payload.value;
    },
    removeXT: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      ele[payload.operator].XT.splice(payload.index, 1);
    },
    // MXT Properties
    addCondition: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      if (ele[payload.operator].condition) {
        if (payload.value === "") {
          delete ele[payload.operator].condition;
        } else {
          ele[payload.operator].condition = payload.value;
        }
      } else {
        if (payload.value === "") {
          delete ele[payload.operator].condition;
        } else {
          ele[payload.operator].condition = payload.value;
        }
      }
    },
    addMXTInput: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      if (ele[payload.operator].MXT) {
        ele[payload.operator].MXT.push({
          name: "",
          dv: "",
        });
      } else {
        ele[payload.operator].MXT = [
          {
            name: "",
            dv: "",
          },
        ];
      }
    },
    addMXTDropDown: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      if (ele[payload.operator].MXT) {
        ele[payload.operator].MXT.push({
          name: payload.name,
          dv: payload.name[0],
        });
      } else {
        ele[payload.operator].MXT = [
          {
            name: payload.name,
            dv: payload.name[0],
          },
        ];
      }
    },
    updateMXTName: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      ele[payload.operator].MXT[payload.index].name = payload.value;
    },
    updateMXTDv: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      const MXT = ele[payload.operator].MXT[payload.index];
      MXT.dv = payload.value;
    },
    removeMXT: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      ele[payload.operator].MXT.splice(payload.index, 1);
    },
    // JS Func Properties
    addJSFunc: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      ele.jsFun = payload.value;
    },
    updateJSFunc: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      ele.jsFun = payload.value;
    },
    removeJSFunc: (state, { payload }) => {
      const ele = returnEle(state, payload.path);
      delete ele.jsFun;
    },
  },
});

export const {
  // Document Controls
  setDocId,
  setDocs,
  setEntryForm,
  setCategory,
  setRunStatus,
  setDev,
  addDoc,
  removeDoc,
  removeEle,
  addSection,
  addPara,
  addTable,
  replaceTable,
  setIndexPath,
  resetDocs,
  // Document Properties
  setDocTabBorder,
  setDocTabWidth,
  setDocFont,
  setDocFontSize,
  setDocHorAlignment,
  setDocVerAlignment,
  setSpacing,
  setDocCellMargin,
  // Section Properties
  setSecOrientation,
  setSecBorderStyle,
  setSecBorderColor,
  setSecBorderSize,
  setSecBorderSpace,
  setSecMargin,
  setSecType,
  setSecHeaderType,
  addHeaderPara,
  addHeaderTable,
  setSecFooterType,
  addFooterPara,
  addFooterTable,
  // Common Properties
  updateShading,
  // Para Properties
  updateAlignment,
  addThematicBreak,
  addPageBreak,
  updateBorder,
  updateSpacing,
  updateParaIndent,
  addTextToPara,
  addImageToPara,
  // Image Properties
  updateImageTransformation,
  // Text Properties
  updateText,
  updateTextFont,
  updateTextSize,
  updateTextCasing,
  updateTextBold,
  updateTextItalics,
  updateTextUnderline,
  updateTextColor,
  // Table Properties
  updateTableWidth,
  updateTableAlignment,
  updateTableVerAlignment,
  updateTableHorAlignment,
  updateTableBorder,
  updateTableTextFont,
  updateTableTextSize,
  setTableCellMargin,
  addColumnToTable,
  addRowToTable,
  // Row Properties
  updateRowHeight,
  setDefaultCellWidth,
  // Cell Properties
  updateCellMargin,
  updateCellBorder,
  setCellWidth,
  updateColSpan,
  updateRowSpan,
  // XT Properties
  addXTInput,
  addXTDropDown,
  updateXTName,
  updateXTDv,
  removeXT,
  // MXT Properties
  addCondition,
  addMXTInput,
  addMXTDropDown,
  updateMXTName,
  updateMXTDv,
  removeMXT,
  // JS Function Properties
  addJSFunc,
  updateJSFunc,
  removeJSFunc,
} = documentsSlice.actions;

export default documentsSlice.reducer;
