import { useState } from "react";
import { getTextStyle } from "./Styles/textStyle";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import { setIndexPath, removeEle } from "../../../../slices/docsSlice";
import { useNavigate } from "react-router-dom";
const CreateText = ({
  text,
  index,
  loc,
  para,
  cell,
  row,
  table,
  sec,
  doc,
  baseURL,
  SIPHF,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RRIP_T, NIP_T, dev } = useSelector((state) => state.docs);
  const [show, setShow] = useState(false);

  const toggleDropdown = (index) => {
    setShow((prevShowOptions) => {
      return {
        ...prevShowOptions,
        [index]: !prevShowOptions[index],
      };
    });
  };

  let splitLoc = loc.split("/");
  let splitIndex = index.split("_");
  let lastIndex = splitIndex[splitIndex.length - 1];

  const textStyle = getTextStyle({
    textProps: text?.properties,
    paraProps: para?.properties,
    cellProps: cell?.properties,
    rowProps: row?.properties,
    tableProps: table?.properties,
    sectionProps: sec?.properties,
    docProps: doc?.properties,
  });
  let textCopy = "CES";
  let paraCopy = "CES";
  let cellCopy = "CES";
  let rowCopy = "CES";
  let tableCopy = "CES";
  if (dev) {
    if (false) {
      textCopy = "";
      paraCopy = "";
      cellCopy = "";
      rowCopy = "";
      tableCopy = "";
    }
    splitIndex.map((i, index) => {
      if (index <= splitIndex.length - 5) {
        tableCopy = tableCopy + `[${i}].ele`;
      }
      if (index <= splitIndex.length - 4) {
        rowCopy = rowCopy + `[${i}].ele`;
      }
      if (index <= splitIndex.length - 3) {
        cellCopy = cellCopy + `[${i}].ele`;
      }
      if (index <= splitIndex.length - 2) {
        paraCopy = paraCopy + `[${i}].ele`;
      }
      textCopy = textCopy + `[${i}].ele`;
    });
    tableCopy = tableCopy.slice(0, -4);
    rowCopy = rowCopy.slice(0, -4);
    cellCopy = cellCopy.slice(0, -4);
    paraCopy = paraCopy.slice(0, -4);
    textCopy = textCopy.slice(0, -4);
  }

  return (
    <div className="relative inline" key={`${index}`}>
      <span
        id={`${index}`}
        name={`${loc}`}
        style={textStyle}
        className="relative cursor-pointer whitespace-pre-wrap"
        onClick={(e) => {
          toggleDropdown(index);
          e.preventDefault();
          e.stopPropagation();

          console.log(dev);
          // Bug here
          if (!dev) {
            dispatch(setIndexPath(`${loc}`));
            if (SIPHF === "0_H" || SIPHF === "0_F") {
              navigate(
                `${baseURL}/${loc}${
                  // check length for HF
                  splitLoc.length > 4
                    ? `/SP/HFP/TBP/TR/TC/PP/TP/${lastIndex}`
                    : `/SP/HFP/PP/TP/${lastIndex}`
                }`
              );
            } else {
              navigate(
                `${baseURL}/${loc}${
                  splitLoc.length > 4
                    ? `/SP/TBP/TR/TC/PP/TP/${lastIndex}`
                    : `/SP/PP/TP/${lastIndex}`
                }`
              );
            }
          }
        }}
      >
        {NIP_T === `${index}` && (SIPHF !== "0_H" || SIPHF !== "0_F") ? (
          <div
            className={`${
              NIP_T.startsWith(`${index}`) ? "flex" : "hidden"
            } absolute left-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50 z-30 text-xs`}
            onClick={(e) => {
              e.preventDefault();
              dispatch(removeEle(RRIP_T));
              let newRRIP = [...RRIP_T].slice(0, -1);
              if (newRRIP.length === 3) {
                navigate(`${baseURL}/${newRRIP.join("/")}/SP/PP`);
              } else {
                navigate(`${baseURL}/${newRRIP.join("/")}/SP/TBP/TR/TC/PP`);
              }
            }}
          >
            <IoCloseSharp />
          </div>
        ) : null}

        {text?.t}
        {dev && show[index] ? (
          <div
            className={`absolute right-0 mt-0 bg-white border rounded-md shadow-lg z-[200] font-mono text-sm ${
              show[index] ? "block" : "hidden"
            }`}
          >
            <ul className="py-1">
              <li
                className="px-4 py-2 hover:bg-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(textCopy);
                }}
              >
                Text
              </li>
              <li
                className="px-4 py-2 hover:bg-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(paraCopy);
                }}
              >
                Para
              </li>
              <li
                className="px-4 py-2 hover:bg-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(cellCopy);
                }}
              >
                Cell
              </li>
              <li
                className="px-4 py-2 hover:bg-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(rowCopy);
                }}
              >
                Row
              </li>
              <li
                className="px-4 py-2 hover:bg-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  toggleDropdown(index);
                  navigator.clipboard.writeText(tableCopy);
                }}
              >
                Table
              </li>
            </ul>
          </div>
        ) : null}
      </span>
    </div>
  );
};

export default CreateText;
