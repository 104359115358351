import React, { useEffect, useState } from "react";
import { setIndexPath } from "../../../../slices/docsSlice";
import { setElementName } from "../../../../slices/elementSlice";
import { useDispatch, useSelector } from "react-redux";
import { GoDotFill } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";

const NesDropDown = ({ loc, options, index }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { indexPath } = useSelector((state) => state.docs);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const { pathname } = useLocation();
  let splitPath = pathname.split("/");
  if (splitPath.length >= 4) {
    let length = splitPath.length - 3;
    splitPath.splice(3, length);
  }
  const baseURL = splitPath.join("/");

  const SIP = indexPath.split("/");

  const splitLoc = loc.split("/");

  // console.log(loc, options, index);
  let LI = splitLoc[2];
  let splitLI = LI?.split("_");
  if (splitLI === undefined) {
    splitLI = [0, 1];
  }
  let newIndex;
  if (splitLI[1] === "H" || splitLI[1] === "F") {
    let splitIndex = index.split("_");
    // console.log(splitIndex);
    // splitIndex.splice(2, 0, "*");
    newIndex = splitIndex.join("_");
  }

  function startsWithExactPath(
    fullPath,
    prefixPath,
    option,
    layoutEle = undefined
  ) {
    if (layoutEle === undefined) {
      let fullSegments = fullPath.split("_");
      let prefixSegments = prefixPath.split("_");

      if (prefixSegments.length > fullSegments.length) {
        return false;
      }

      for (let i = 0; i < prefixSegments.length; i++) {
        if (fullSegments[i] !== prefixSegments[i]) {
          return false;
        }
      }

      if (option.type === "text") {
        dispatch(
          setElementName({
            ele: option.type,
            name: option.t,
          })
        );
      } else if (
        option.type === "image" ||
        option.type === "para" ||
        option.type === "cell" ||
        option.type === "row" ||
        option.type === "table" ||
        option.type === "section" ||
        option.type === "doc"
      ) {
        dispatch(
          setElementName({
            ele: option.type,
            name: option.name,
          })
        );
      }

      return true;
    } else {
      let splitPrefixPath = prefixPath.split("_");
      splitPrefixPath.splice(2, 0, 0);
      let newPrefixPath = splitPrefixPath.join("_");
      let fullSegments = fullPath.split("_");
      // console.log(fullPath, newPrefixPath);
      let prefixSegments = newPrefixPath.split("_");
      if (prefixSegments.length > fullSegments.length) {
        return false;
      }

      for (let i = 0; i < prefixSegments.length; i++) {
        if (fullSegments[i] !== prefixSegments[i]) {
          return false;
        }
      }
      return true;
    }
  }

  // console.log(SIP);
  let newIndexPath = "";
  SIP.map((i) => {
    const splitIndex = i.split("_");
    newIndexPath = newIndexPath + splitIndex[0] + "_";
  });
  newIndexPath = newIndexPath.slice(0, -1);

  // console.log(newIndexPath);

  const handleDoubleClick = (option) => {
    setShow((prevShowOptions) => {
      return {
        ...prevShowOptions,
        [option]: !prevShowOptions[option],
      };
    });
  };

  useEffect(() => {
    setShow(false);
    const SNIP = newIndexPath.split("_");
    let IP;
    SNIP.forEach((index, i) => {
      if (i === 0) {
        IP = `${index}`;
      } else {
        IP = `${IP}_${index}`;
      }
      handleDoubleClick(IP);
    });
  }, [newIndexPath]);

  if (typeof options === "object" && Array.isArray(options)) {
    return (
      <>
        {options.map((option, i) => {
          if (option.type === "doc") {
            return (
              <div className="relative font-sans">
                <a href={`#${i}`}>
                  <h1
                    className="cursor-pointer flex items-center justify-between"
                    onClick={() => {
                      dispatch(setIndexPath(`${i}_Doc`));
                      navigate(`${baseURL}/${i}_Doc/DP`);
                    }}
                    // onDoubleClick={() => handleDoubleClick(`${i}`)}
                  >
                    {option.name}
                    <span className="text-orange-500">
                      {startsWithExactPath(
                        newIndexPath,
                        `${index}_${i}`,
                        option
                      ) ? (
                        <GoDotFill />
                      ) : null}
                    </span>
                  </h1>
                </a>
                <div
                  className={
                    show[`${i}`]
                      ? "border-l-2 flex flex-col items-start"
                      : "hidden"
                  }
                >
                  <NesDropDown loc={SIP[0]} options={option.ele} index={i} />
                </div>
              </div>
            );
          } else if (option.type === "section") {
            // console.log(option.headers.ele, `${index}_${i}`);
            return (
              <div className="flex items-start w-full rounded-sm">
                <div className="w-1 border mr-1 mt-3"></div>
                <div className="relative w-full">
                  <a href={`#${index}_${i}`}>
                    <h1
                      className="cursor-pointer flex items-center justify-between bg-cyan-950 text-light-500"
                      onClick={() => {
                        dispatch(setIndexPath(`${loc}/${i}_S`));
                        navigate(`${baseURL}/${loc}/${i}_S/SP`);
                      }}
                      // onDoubleClick={() => handleDoubleClick(`${index}_${i}`)}
                    >
                      {!isNaN(parseFloat(option.name))
                        ? `S_${option.name}`
                        : option.name}
                      <span className="text-light-500">
                        {startsWithExactPath(
                          newIndexPath,
                          `${index}_${i}`,
                          option
                        ) ? (
                          <GoDotFill />
                        ) : null}
                      </span>
                    </h1>
                  </a>
                  <div
                    className={
                      show[`${index}_${i}`]
                        ? "border-l-2 items-center"
                        : "hidden"
                    }
                  >
                    <NesDropDown
                      loc={`${loc}/${i}_S/0_H`}
                      options={option.headers.ele}
                      index={`${index}_${i}`}
                    />
                  </div>
                  <div
                    className={
                      show[`${index}_${i}`]
                        ? "border-l-2 items-center"
                        : "hidden"
                    }
                  >
                    <NesDropDown
                      loc={`${loc}/${i}_S`}
                      options={option.ele}
                      index={`${index}_${i}`}
                    />
                  </div>
                  <div
                    className={
                      show[`${index}_${i}`]
                        ? "border-l-2 items-center"
                        : "hidden"
                    }
                  >
                    <NesDropDown
                      loc={`${loc}/${i}_S/0_F`}
                      options={option.footers.ele}
                      index={`${index}_${i}`}
                    />
                  </div>
                </div>
              </div>
            );
          } else if (option.type === "para") {
            // let LI = splitLoc[2];
            // let splitLI = LI?.split("_");
            // if (splitLI === undefined) {
            //   splitLI = [0, 1];
            // }
            // let newIndex;
            // if (splitLI[1] === "H" || splitLI[1] === "F") {
            //   let splitIndex = index.split("_");
            //   splitIndex.splice(2, 0, 0);
            //   newIndex = splitIndex.join("_");
            // }
            return (
              <div className="flex items-start w-full rounded-sm">
                <div className="w-1 border mr-1 mt-3"></div>
                <div className="relative w-full">
                  <a
                    href={`${
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? `#${newIndex}_${i}`
                        : `#${index}_${i}`
                    }`}
                  >
                    <h1
                      className="cursor-pointer flex items-center justify-between"
                      onClick={() => {
                        console.log(splitLoc);
                        dispatch(setIndexPath(`${loc}/${i}_P`));
                        if (splitLI[1] === "H" || splitLI[1] === "F") {
                          navigate(
                            `${baseURL}/${loc}/${i}_P${
                              splitLoc.length > 3
                                ? "/SP/HFP/TBP/TR/TC/PP"
                                : "/SP/HFP/PP"
                            }`
                            // `${baseURL}/${loc}/${i}_P/PP`
                          );
                        } else {
                          navigate(
                            `${baseURL}/${loc}/${i}_P${
                              splitLoc.length > 3
                                ? "/SP/TBP/TR/TC/PP"
                                : "/SP/PP"
                            }`
                            // `${baseURL}/${loc}/${i}_P/PP`
                          );
                        }
                      }}
                      // onDoubleClick={() => handleDoubleClick(`${index}_${i}`)}
                    >
                      {!isNaN(parseFloat(option.name))
                        ? `${
                            splitLI[1] === "H"
                              ? "Header"
                              : splitLI[1] === "F"
                              ? "Footer"
                              : "P"
                          }_${option.name}`
                        : option.name}
                      <span className="text-teal-500">
                        {splitLI[1] !== "H" && splitLI[1] !== "F" ? (
                          startsWithExactPath(
                            newIndexPath,
                            `${index}_${i}`,
                            option
                          ) ? (
                            <GoDotFill />
                          ) : null
                        ) : startsWithExactPath(
                            newIndexPath,
                            `${newIndex}_${i}`,
                            option,
                            splitLI[1]
                          ) ? (
                          <GoDotFill />
                        ) : null}
                      </span>
                    </h1>
                  </a>
                  <div
                    className={
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? show1[`${newIndex}_${i}`]
                        : show[`${index}_${i}`]
                        ? "border-l-2 items-center"
                        : "hidden"
                    }
                  >
                    <NesDropDown
                      loc={`${loc}/${i}_P`}
                      options={option.ele}
                      index={
                        splitLI[1] === "H" || splitLI[1] === "F"
                          ? `${newIndex}_${i}`
                          : `${index}_${i}`
                      }
                    />
                  </div>
                </div>
              </div>
            );
          } else if (option.type === "text") {
            // console.log(splitLoc, index);
            // let LI = splitLoc[2];
            // let splitLI = LI.split("_");
            // if (splitLI === undefined) {
            //   splitLI = [0, 1];
            // }
            // let newIndex;
            // if (splitLI[1] === "H" || splitLI[1] === "F") {
            //   let splitIndex = index.split("_");
            //   splitIndex.splice(2, 0, 0);
            //   newIndex = splitIndex.join("_");
            // }
            // console.log(newIndex);

            return (
              <div className="flex items-start w-full rounded-sm">
                <div className="w-1 border mr-1 mt-3"></div>
                <div className="relative w-full">
                  <a
                    href={`${
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? `#${newIndex}_${i}`
                        : `#${index}_${i}`
                    }`}
                  >
                    <h1
                      className="cursor-pointer line-clamp-1 flex items-center justify-between"
                      onClick={
                        () => {
                          dispatch(setIndexPath(`${loc}/${i}_T`));
                          if (splitLI[1] === "H" && splitLI[1] === "F") {
                            navigate(
                              `${baseURL}/${loc}/${i}_T${
                                splitLoc.length > 4
                                  ? `/SP/HFP/TBP/TR/TC/PP/TP/${i}`
                                  : `/SP/HFP/PP/TP/${i}`
                              }`
                              // `${baseURL}/${loc}/${i}_T/TP`
                            );
                          } else {
                            navigate(
                              `${baseURL}/${loc}/${i}_T${
                                splitLoc.length > 4
                                  ? `/SP/TBP/TR/TC/PP/TP/${i}`
                                  : `/SP/PP/TP/${i}`
                              }`
                              // `${baseURL}/${loc}/${i}_T/TP`
                            );
                          }
                        }
                        // setShow((prevShowOptions) => ({
                        //   ...prevShowOptions,
                        //   [option.t]: !prevShowOptions[option.t],
                        // }))
                      }
                      // onDoubleClick={() => {}}
                    >
                      <p className="line-clamp-1">
                        {/* {option.t === "" ? "R" : option.t} */}
                        {option.t}
                      </p>
                      <span className="text-purple-500">
                        {splitLI[1] !== "H" && splitLI[1] !== "F" ? (
                          startsWithExactPath(
                            newIndexPath,
                            `${index}_${i}`,
                            option
                          ) ? (
                            <GoDotFill />
                          ) : null
                        ) : startsWithExactPath(
                            newIndexPath,
                            `${newIndex}_${i}`,
                            option,
                            splitLI[1]
                          ) ? (
                          <GoDotFill />
                        ) : null}
                      </span>
                    </h1>
                  </a>
                  <div
                    className={
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? show1[`${newIndex}_${i}`]
                        : show[`${index}_${i}`]
                        ? "border-l-2 flex items-center"
                        : "hidden"
                    }
                  >
                    {/* <NesDropDown options={option.ele} /> */}
                  </div>
                </div>
              </div>
            );
          } else if (option.type === "image") {
            // let LI = splitLoc[2];
            // let splitLI = LI?.split("_");
            // if (splitLI === undefined) {
            //   splitLI = [0, 1];
            // }
            // let newIndex;
            // if (splitLI[1] === "H" || splitLI[1] === "F") {
            //   let splitIndex = index.split("_");
            //   splitIndex.splice(2, 0, 0);
            //   newIndex = splitIndex.join("_");
            // }
            return (
              <div className="flex items-start w-full rounded-sm">
                <div className="w-1 border mr-1 mt-3"></div>
                <div className="relative w-full">
                  <a
                    href={`${
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? `#${newIndex}_${i}`
                        : `#${index}_${i}`
                    }`}
                  >
                    <h1
                      className="cursor-pointer line-clamp-1 flex items-center justify-between"
                      onClick={
                        () => {
                          dispatch(setIndexPath(`${loc}/${i}_I`));
                          if (splitLI[1] === "H" || splitLI[1] === "F") {
                            navigate(
                              `${baseURL}/${loc}/${i}_I${
                                splitLoc.length > 4
                                  ? `/SP/HFP/TBP/TR/TC/PP/IP/${i}`
                                  : `/SP/HFP/PP/IP/${i}`
                              }`
                            );
                          } else {
                            navigate(
                              `${baseURL}/${loc}/${i}_I${
                                splitLoc.length > 4
                                  ? `/SP/TBP/TR/TC/PP/IP/${i}`
                                  : `/SP/PP/IP/${i}`
                              }`
                            );
                          }
                        }
                        // setShow((prevShowOptions) => ({
                        //   ...prevShowOptions,
                        //   [option.t]: !prevShowOptions[option.t],
                        // }))
                      }
                      // onDoubleClick={() => {}}
                    >
                      <p className="line-clamp-1">{option.name}</p>
                      <span className="text-purple-500">
                        {splitLI[1] !== "H" && splitLI[1] !== "F" ? (
                          startsWithExactPath(
                            newIndexPath,
                            `${index}_${i}`,
                            option
                          ) ? (
                            <GoDotFill />
                          ) : null
                        ) : startsWithExactPath(
                            newIndexPath,
                            `${newIndex}_${i}`,
                            option,
                            splitLI[1]
                          ) ? (
                          <GoDotFill />
                        ) : null}
                      </span>
                    </h1>
                  </a>
                  <div
                    className={
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? show1[`${newIndex}_${i}`]
                        : show[`${index}_${i}`]
                        ? "border-l-2 flex items-center"
                        : "hidden"
                    }
                  >
                    {/* <NesDropDown options={option.ele} /> */}
                  </div>
                </div>
              </div>
            );
          } else if (option.type === "table") {
            // let LI = splitLoc[2];
            // let splitLI = LI?.split("_");
            // if (splitLI === undefined) {
            //   splitLI = [0, 1];
            // }
            // let newIndex;
            // if (splitLI[1] === "H" || splitLI[1] === "F") {
            //   let splitIndex = index.split("_");
            //   splitIndex.splice(2, 0, 0);
            //   newIndex = splitIndex.join("_");
            // }
            return (
              <div className="flex items-start w-full rounded-sm">
                <div className="w-1 border mr-1 mt-3"></div>
                <div className="relative w-full">
                  <a
                    href={`${
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? `#${newIndex}_${i}`
                        : `#${index}_${i}`
                    }`}
                  >
                    <h1
                      className="cursor-pointer flex items-center justify-between"
                      onClick={() => {
                        dispatch(setIndexPath(`${loc}/${i}_TB`));
                        if (splitLI[1] === "H" || splitLI[1] === "F") {
                          navigate(`${baseURL}/${loc}/${i}_TB/SP/HFP/TBP`);
                        } else {
                          navigate(`${baseURL}/${loc}/${i}_TB/SP/TBP`);
                        }
                      }}
                      // onDoubleClick={() => handleDoubleClick(`${index}_${i}`)}
                    >
                      {!isNaN(parseFloat(option.name))
                        ? `${
                            splitLI[1] === "H"
                              ? "Header"
                              : splitLI[1] === "F"
                              ? "Footer"
                              : "TBP"
                          }_${option.name}`
                        : option.name}
                      <span className="text-blue-500">
                        {splitLI[1] !== "H" && splitLI[1] !== "F" ? (
                          startsWithExactPath(
                            newIndexPath,
                            `${index}_${i}`,
                            option
                          ) ? (
                            <GoDotFill />
                          ) : null
                        ) : startsWithExactPath(
                            newIndexPath,
                            `${newIndex}_${i}`,
                            option,
                            splitLI[1]
                          ) ? (
                          <GoDotFill />
                        ) : null}
                      </span>
                    </h1>
                  </a>
                  <div
                    className={
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? show1[`${newIndex}_${i}`]
                        : show[`${index}_${i}`]
                        ? "border-l-2 items-center"
                        : "hidden"
                    }
                  >
                    <NesDropDown
                      loc={`${loc}/${i}_TB`}
                      options={option.ele}
                      index={
                        splitLI[1] === "H" || splitLI[1] === "F"
                          ? `${newIndex}_${i}`
                          : `${index}_${i}`
                      }
                    />
                  </div>
                </div>
              </div>
            );
          } else if (option.type === "row") {
            // let LI = splitLoc[2];
            // let splitLI = LI?.split("_");
            // if (splitLI === undefined) {
            //   splitLI = [0, 1];
            // }
            // let newIndex;
            // if (splitLI[1] === "H" || splitLI[1] === "F") {
            //   let splitIndex = index.split("_");
            //   splitIndex.splice(2, 0, 0);
            //   newIndex = splitIndex.join("_");
            // }
            return (
              <div className="flex items-start w-full rounded-sm">
                <div className="w-1 border mr-1 mt-3"></div>
                <div className="relative w-full">
                  <a
                    href={`${
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? `#${newIndex}_${i}`
                        : `#${index}_${i}`
                    }`}
                  >
                    <h1
                      className="cursor-pointer flex items-center justify-between"
                      onClick={() => {
                        dispatch(setIndexPath(`${loc}/${i}_TR`));
                        if (splitLI[1] === "H" || splitLI[1] === "F") {
                          navigate(`${baseURL}/${loc}/${i}_TR/SP/HFP/TBP/TR`);
                        } else {
                          navigate(`${baseURL}/${loc}/${i}_TR/SP/TBP/TR`);
                        }
                      }}
                      // onDoubleClick={() => handleDoubleClick(`${index}_${i}`)}
                    >
                      {`TR_${i}`}
                      <span className="text-green-500">
                        {splitLI[1] !== "H" && splitLI[1] !== "F" ? (
                          startsWithExactPath(
                            newIndexPath,
                            `${index}_${i}`,
                            option
                          ) ? (
                            <GoDotFill />
                          ) : null
                        ) : startsWithExactPath(
                            newIndexPath,
                            `${newIndex}_${i}`,
                            option,
                            splitLI[1]
                          ) ? (
                          <GoDotFill />
                        ) : null}
                      </span>
                    </h1>
                  </a>
                  <div
                    className={
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? show1[`${newIndex}_${i}`]
                        : show[`${index}_${i}`]
                        ? "border-l-2 items-center"
                        : "hidden"
                    }
                  >
                    <NesDropDown
                      loc={`${loc}/${i}_TR`}
                      options={option.ele}
                      index={
                        splitLI[1] === "H" || splitLI[1] === "F"
                          ? `${newIndex}_${i}`
                          : `${index}_${i}`
                      }
                    />
                  </div>
                </div>
              </div>
            );
          } else if (option.type === "cell") {
            // let LI = splitLoc[2];
            // let splitLI = LI?.split("_");
            // if (splitLI === undefined) {
            //   splitLI = [0, 1];
            // }
            // let newIndex;
            // if (splitLI[1] === "H" || splitLI[1] === "F") {
            //   let splitIndex = index.split("_");
            //   splitIndex.splice(2, 0, 0);
            //   newIndex = splitIndex.join("_");
            // }
            return (
              <div className="flex items-start w-full rounded-sm">
                <div className="w-1 border mr-1 mt-3"></div>
                <div className="relative w-full">
                  <a
                    href={`${
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? `#${newIndex}_${i}`
                        : `#${index}_${i}`
                    }`}
                  >
                    <h1
                      className="cursor-pointer flex items-center justify-between"
                      onClick={() => {
                        dispatch(setIndexPath(`${loc}/${i}_TC`));
                        if (splitLI[1] === "H" || splitLI[1] === "F") {
                          navigate(
                            `${baseURL}/${loc}/${i}_TC/SP/HFP/TBP/TR/TC`
                          );
                        } else {
                          navigate(`${baseURL}/${loc}/${i}_TC/SP/TBP/TR/TC`);
                        }
                      }}
                      // onDoubleClick={() => handleDoubleClick(`${index}_${i}`)}
                    >
                      {`TD_${i}`}
                      <span className="text-yellow-500">
                        {splitLI[1] !== "H" && splitLI[1] !== "F" ? (
                          startsWithExactPath(
                            newIndexPath,
                            `${index}_${i}`,
                            option
                          ) ? (
                            <GoDotFill />
                          ) : null
                        ) : startsWithExactPath(
                            newIndexPath,
                            `${newIndex}_${i}`,
                            option,
                            splitLI[1]
                          ) ? (
                          <GoDotFill />
                        ) : null}
                      </span>
                    </h1>
                  </a>
                  <div
                    className={
                      splitLI[1] === "H" || splitLI[1] === "F"
                        ? show1[`${newIndex}_${i}`]
                        : show[`${index}_${i}`]
                        ? "border-l-2 items-center"
                        : "hidden"
                    }
                  >
                    <NesDropDown
                      loc={`${loc}/${i}_TC`}
                      options={option.ele}
                      index={
                        splitLI[1] === "H" || splitLI[1] === "F"
                          ? `${newIndex}_${i}`
                          : `${index}_${i}`
                      }
                    />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </>
    );
  }
  // else {
  //   const newOptions = Object.keys(options);
  //   return (
  //     <>
  //       {newOptions.map((option) => {
  //         const [show, setShow] = useState(false);
  //         if (options[option].type === "para") {
  //           return (
  //             <div className="relative pl-1">
  //               <h1 className="cursor-pointer">{options[option].name}</h1>
  //             </div>
  //           );
  //         }
  //       })}
  //     </>
  //   );
  // }
};

export default NesDropDown;
