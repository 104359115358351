import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Dropdown from "../../../../../../utils/Dropdown";
import Input from "../../../../../../utils/Input";
import RetractableDiv from "../../../../../../components/RetractableDiv";
import {
  updateRowHeight,
  setDefaultCellWidth,
} from "../../../../../../slices/docsSlice";
import { returnPropEle, switchWidthType } from "../../CommFunctions";

const RowProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    docs,
    indexPath,
    SIP,
    RRIP_T,
    RRIP_P,
    RRIP_TC,
    RRIP_TR,
    RRIP_TB,
    RRIP_DOC,
    NIP_T,
    NIP_P,
    NIP_TC,
    NIP_TR,
    NIP_TB,
    NIP_DOC,
  } = useSelector((state) => state.docs);
  const { rowName } = useSelector((state) => state.element);
  const { rowHeightRules, widthTypes } = useSelector((state) => state.options);
  const [heightValue, setHeightValue] = useState();
  const [heightRule, setHeightRule] = useState();
  const [widthType, setWidthType] = useState();
  const [width, setWidth] = useState();

  console.log(rowHeightRules);

  let SIPHF = SIP[2];

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");
  if (splitLoc.length >= 4) {
    let length = splitLoc.length - 3;
    splitLoc.splice(3, length);
  }
  const baseURL = splitLoc.join("/");

  let newIndexPathArr = NIP_TR.split("_");

  // let dynName = "docs";
  // newIndexPathArr.forEach((item, i) => {
  //   dynName =
  //     dynName +
  //     `['${item}'].${i === newIndexPathArr.length - 1 ? "name" : "ele"}`;
  // });

  useEffect(() => {
    const row = RRIP_TR ? returnPropEle(docs, RRIP_TR) : undefined;
    if (row) {
      if (row.properties.height) {
        setHeightRule(row.properties.height.rule);
        setHeightValue(row.properties.height.value);
      } else {
        setHeightRule("");
        setHeightValue("");
      }
      if (row.properties.width) {
        setWidthType(row.properties.width.type);
        if (row.properties.width.type === "pct") {
          setWidth(parseFloat(row.properties.width.size.replace("%", "")));
        } else if (row.properties.width.type === "dxa") {
          setWidth(row.properties.width.size);
        } else {
          setWidth();
        }
      }
    }
  }, [docs, RRIP_TR]);

  return (
    <div className="psm morphous bg-color">
      <h1 className="font-mono text-center pb-4">
        Row Properties [{rowName}]
        {/* [{`${eval(dynName)}_${newIndexPathArr[newIndexPathArr.length - 1]}`}] */}
      </h1>
      {/* <RetractableDiv
        show={false}
        name="Row Properties"
        highLight={`${eval(dynName)}_${
          newIndexPathArr[newIndexPathArr.length - 1]
        }`}
      > */}
      <div className="pb-4">
        <h1 className="pb-1 font-mono">
          Height Units [mm, cm, in, pt, pc, pi]
        </h1>
        <div className="flex flex-wrap gap-x-5 gap-y-3 mt-1">
          <div className="mb-2">
            <p className="mb-2">Height</p>
            <Input
              className="h-[40px] w-[150px]"
              value={heightValue}
              type="text"
              blur={(e) => {
                dispatch(
                  updateRowHeight({
                    loc: "value",
                    row: RRIP_TR,
                    value: e.target.value,
                    layoutEle:
                      SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                  })
                );
              }}
              change={(e) => setHeightValue(e.target.value)}
            />
          </div>
          <div className="mb-2">
            <p className="mb-2">Height Rule</p>
            <Dropdown
              width={150}
              height={40}
              value={heightRule}
              options={rowHeightRules}
              target="option"
              name="RHR"
              placeholder="height rule"
              click={(value) => {
                dispatch(
                  updateRowHeight({
                    loc: "rule",
                    row: RRIP_TR,
                    value,
                    layoutEle:
                      SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                  })
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="pb-4">
        <h1 className="pb-1 font-mono">Cell Properties</h1>
        <h1 className="pb-1 font-mono">
          Width Units [mm, 55.87cm (MAX), in, pt, pc, pi, %]
        </h1>
        <div className="flex gap-4">
          {/* <p className="mb-2">Set Default Cell Width</p> */}
          <div className="flex flex-wrap gap-x-5 gap-y-3 mt-1">
            <div>
              <h2 className="pb-1">Set Width Type</h2>
              <Dropdown
                value={widthType}
                options={widthTypes}
                target="option"
                placeholder="width type"
                name="WT"
                width={150}
                height={40}
                click={(value) => {
                  dispatch(
                    setDefaultCellWidth({
                      loc: "type",
                      row: RRIP_TR,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">Set Width Size</h2>
              <Input
                className="h-[40px] w-[150px]"
                value={width}
                type="text"
                blur={(e) => {
                  dispatch(
                    setDefaultCellWidth({
                      loc: "size",
                      row: RRIP_TR,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
                change={(e) => setWidth(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {/* </RetractableDiv> */}
      <div className="mb-2">
        <Outlet />
      </div>
    </div>
  );
};

export default RowProperties;
