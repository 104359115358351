import React, { useState } from "react";
// import "../styles/cibil.scss";
import { parse } from "parse5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import { setPPB } from "../features/testSlice";

const PPB = () => {
  // const CES = useSelector((state) => state.test.CES);
  const dispatch = useDispatch();
  const history = useNavigate();

  let [customerFile, setCustomerFile] = useState();
  let [ppb, setppb] = useState([]);

  let obj = {};

  const addData = (e) => {
    e.preventDefault();
    let newPPB = [...ppb];
    newPPB.push(obj);
    setppb(newPPB);
  };

  // console.log(obj);

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(setPPB(ppb));
  };

  const onChange = (e) => {
    e.preventDefault();
    setCustomerFile(e.target.files);
    let CN = document.querySelector(".Cibil-name");
    CN.innerHTML = `PPB Name: ${e.target.value.split("\\").pop()}`;
  };

  if (customerFile !== undefined) {
    let fileReader = new FileReader();
    fileReader.readAsText(customerFile[0]);
    fileReader.onload = (e) => {
      let data = e.target.result;
      // console.log(data);
      let doc = parse(data);
      // console.log(doc);
      doc.childNodes.map((ele) => {
        ele.childNodes.map((el1, i1) => {
          if (i1 === 2) {
            el1.childNodes.map((el2, i2) => {
              if (i2 === 3) {
                el2.childNodes.map((el3, i3) => {
                  if (el3.nodeName === "table") {
                    console.log(el3, i3);
                    if (i3 === 1) {
                      el3.childNodes.map((el4, i4) => {
                        if (i4 === 1) {
                          el4.childNodes.map((el5, i5) => {
                            if (el5.nodeName === "tr") {
                              // console.log(el5, i5);
                              if (i5 === 13 || i5 === 12) {
                                obj["Jilla"] =
                                  el5.childNodes[3].childNodes[0].value.replace(
                                    /[:\s]/g,
                                    ""
                                  );
                                obj["Division"] =
                                  el5.childNodes[7].childNodes[0].value.replace(
                                    /[:\s]/g,
                                    ""
                                  );
                                // console.log(obj);
                              } else if (i5 === 15 || i5 === 14) {
                                obj["Mandalam"] =
                                  el5.childNodes[3].childNodes[0].value.replace(
                                    /[:\s]/g,
                                    ""
                                  );
                                obj["Village"] =
                                  el5.childNodes[7].childNodes[0].value.replace(
                                    /[:\s]/g,
                                    ""
                                  );
                                // console.log(obj);
                              } else if (i5 === 17 || i5 === 16) {
                                obj["Passbook No"] =
                                  el5.childNodes[3].childNodes[0].value.replace(
                                    /[:\s]/g,
                                    ""
                                  );
                                // console.log(obj);
                              } else if (i5 === 19 || i5 === 18) {
                                // console.log(el5);
                                obj["Image"] = {};
                                obj.Image["src"] =
                                  el5.childNodes[7].childNodes[
                                    i5 === 19 ? 1 : 0
                                  ].attrs[0].value;
                                obj.Image["height"] =
                                  el5.childNodes[7].childNodes[
                                    i5 === 19 ? 1 : 0
                                  ].attrs[2].value;
                                // console.log(obj);
                              } else if (i5 === 25 || i5 === 24) {
                                // console.log(el5);
                                obj["Patadar"] = {};
                                el5.childNodes[1].childNodes[
                                  i5 === 25 ? 1 : 0
                                ].childNodes.map((el6, i6) => {
                                  if (el6.nodeName === "li") {
                                    // console.log(el6, i6);
                                    if (i6 === 1) {
                                      obj.Patadar["Name"] =
                                        el6.childNodes[1].childNodes[0].value.trim();
                                    } else if (i6 === 6) {
                                      obj.Patadar["Father/Husband Name"] =
                                        el6.childNodes[1].childNodes[0].value.trim();
                                    } else if (i6 === 11) {
                                      obj.Patadar["Gender"] = el6.childNodes[1]
                                        .childNodes[0]
                                        ? el6.childNodes[1].childNodes[0].value.trim()
                                        : "";
                                    } else if (i6 === 16) {
                                      obj.Patadar["Address"] =
                                        el6.childNodes[1].childNodes[0].value.trim();
                                    } else if (i6 === 21) {
                                      obj.Patadar["Caste"] = el6.childNodes[1]
                                        .childNodes[0]
                                        ? el6.childNodes[1].childNodes[0].value.trim()
                                        : "";
                                    }
                                    // console.log(obj);
                                  }
                                });
                                // console.log(el5.childNodes[1].childNodes);
                              }
                            }
                          });
                        }
                      });
                    } else if (i3 === 7 || i3 === 6) {
                      el3.childNodes.map((el4, i4) => {
                        if (i4 === 1) {
                          el4.childNodes.map((el5, i5) => {
                            if (el5.nodeName === "tr") {
                              console.log(el5, i5);
                              if (i5 === 9 || i5 === 8) {
                                obj["date"] = el5.childNodes[1].childNodes[
                                  i5 === 9 ? 4 : 3
                                ].value.replace(/[:\s]/g, "");
                                console.log(obj);
                              } else if (i5 === 11 || i5 === 10) {
                                console.log(el5);
                                el5.childNodes.map((el6, i6) => {
                                  if (i6 === 1) {
                                    el6.childNodes.map((el7, i7) => {
                                      console.log(el7, i7);
                                      if (i7 === (i5 === 11 ? 1 : 0)) {
                                        console.log(el7);
                                        el7.childNodes.map((el8, i8) => {
                                          console.log(el8, i8);
                                          if (i8 === 1) {
                                            console.log(el8);
                                            obj["Land"] = [];
                                            el8.childNodes.map((el9, i9) => {
                                              console.log(el8.childNodes);
                                              if (el9.nodeName === "tr") {
                                                // console.log(el9, i9);
                                                if (
                                                  i9 !== (i5 === 11 ? 1 : 0) &&
                                                  i9 !==
                                                    el8.childNodes.length - 2
                                                ) {
                                                  obj.Land.push({
                                                    "Survey No":
                                                      el9.childNodes[3]
                                                        .childNodes[0].value,
                                                    Boundary:
                                                      el9.childNodes[5]
                                                        .childNodes[0].value,
                                                  });
                                                } else if (
                                                  i9 ===
                                                  el8.childNodes.length - 2
                                                ) {
                                                  console.log(el9);
                                                  obj.Land.push({
                                                    Total:
                                                      el9.childNodes[
                                                        i5 === 11 ? 3 : 2
                                                      ].childNodes[0].value,
                                                  });
                                                }
                                                console.log(obj);
                                              }
                                            });
                                          }
                                        });
                                      }
                                    });
                                  }
                                });
                              }
                            }
                          });
                        }
                      });
                    }
                  }
                });
              }
            });
          }
        });
      });
    };
  }

  return (
    <div className="Cibil bg1 txt1">
      {/* {CES["PR"].doc["Partners Table"].T.map((R, i) => {
        if (i > 0) {
          return <h2 className="t2">{R[2].name}</h2>;
        }
      })} */}
      <div className="Cibil-box">
        <div className="Cibil-input-box bg2">
          <h1 className="t2 Cibil-name">PPB File: </h1>
          <input
            type="file"
            id="customerFile"
            files={customerFile}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="d-flex justify-content-between">
          <button className="Cibil-Add bg6 txt7 sdw" onClick={addData}>
            Add Data
          </button>
          {/* <button className='Cibil-Dummy bg9 txt1 sdw' onClick={() => {}}>
            Test Data
          </button> */}
          <input
            type="submit"
            value="Submit"
            className="Cibil-Add bg5 txt6 sdw"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default PPB;
