import React, { useEffect, useState } from "react";
import Dropdown from "../../../../../utils/Dropdown";
import Input from "../../../../../utils/Input";
import { RiSave3Fill } from "react-icons/ri";
import { GiCardPickup } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAlignment,
  addThematicBreak,
  addPageBreak,
  updateBorder,
  updateShading,
  updateSpacing,
  updateParaIndent,
  addTextToPara,
  setIndexPath,
  returnHFEle,
} from "../../../../../slices/docsSlice";
// import { updateColors } from "../../../../slices/productSlice";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { switchShadeType, returnPropEle } from "../CommFunctions";
import XtControls from "../Tools/xtControls";
import MxtControls from "../Tools/mxtControls";
import JsFunControls from "../Tools/jsFunControls";
// import { convertToTwips } from "../../UnitConversion";

const ParagraphProperties = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    docs,
    indexPath,
    SIP,
    RRIP_I,
    RRIP_T,
    RRIP_P,
    RRIP_TC,
    RRIP_TR,
    RRIP_TB,
    RRIP_S,
    RRIP_DOC,
    NIP_I,
    NIP_T,
    NIP_P,
    NIP_TC,
    NIP_TR,
    NIP_TB,
    NIP_S,
    NIP_DOC,
  } = useSelector((state) => state.docs);
  const { paraName } = useSelector((state) => state.element);
  // const { productInfo } = useSelector((state) => state.product);
  const { alignments, isTrueOrFalse, shadingTypes } = useSelector(
    (state) => state.options
  );
  const [alignment, setAlignment] = useState();
  const [thematicBreak, setThematicBreak] = useState();
  const [pageBreak, setPageBreak] = useState();
  const [border, setBorder] = useState();
  const [shadeColor, setShadeColor] = useState();
  const [shadeFill, setShadeFill] = useState();
  const [shadeType, setShadeType] = useState();
  const [spacingBefore, setSpacingBefore] = useState();
  const [spacingAfter, setSpacingAfter] = useState();
  const [spacingLine, setSpacingLine] = useState();
  const [leftIndent, setLeftIndent] = useState(); // Example default value
  const [rightIndent, setRightIndent] = useState();
  const [firstLineIndent, setFirstLineIndent] = useState();
  const [hangingIndent, setHangingIndent] = useState();

  const [paraStatus, setParaStatus] = useState("properties");

  useEffect(() => {
    setParaStatus("properties");
  }, [indexPath]);

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");
  // const currentPath = splitLoc[3];
  if (splitLoc.length >= 4) {
    let length = splitLoc.length - 3;
    splitLoc.splice(3, length);
  }
  const baseURL = splitLoc.join("/");

  let SIPHF = SIP[2];

  // let dynName = "docs";
  // NIP_P.split("_").forEach((item, i) => {
  //   dynName =
  //     dynName +
  //     `['${item}'].${i === NIP_P.split("_").length - 1 ? "name" : "ele"}`;
  // });

  let dynEle = "docs";
  NIP_P.split("_").forEach((item, i) => {
    dynEle =
      dynEle + `['${item}'].${i === NIP_P.split("_").length - 1 ? "" : "ele"}`;
  });

  dynEle = dynEle.slice(0, -1);

  const docIndex = parseFloat(SIP[0].split("_")[0]);

  useEffect(() => {
    let para;
    let cell;
    let row;
    let table;
    let sec;
    let doc;
    if (SIPHF === "0_H" || SIPHF === "0_F") {
      // para = useSelector((state) => returnHFEle(state, RRIP_P, SIPHF));
      // cell = RRIP_TC
      //   ? useSelector((state) => returnHFEle(state, RRIP_TC, SIPHF))
      //   : undefined;
      // row = RRIP_TR
      //   ? useSelector((state) => returnHFEle(state, RRIP_TR, SIPHF))
      //   : undefined;
      // table = RRIP_TB
      //   ? useSelector((state) => returnHFEle(state, RRIP_TB, SIPHF))
      //   : undefined;
      // sec = useSelector((state) => returnHFEle(state, RRIP_S, SIPHF));
      doc = returnPropEle(docs, RRIP_DOC);
      console.log(para, doc);
    } else {
      para = returnPropEle(docs, RRIP_P);
      cell = RRIP_TC ? returnPropEle(docs, RRIP_TC) : undefined;
      row = RRIP_TR ? returnPropEle(docs, RRIP_TR) : undefined;
      table = RRIP_TB ? returnPropEle(docs, RRIP_TB) : undefined;
      doc = returnPropEle(docs, RRIP_DOC);
    }

    if (para) {
      if (para?.properties?.alignment) {
        setAlignment(para.properties.alignment);
      } else if (table !== undefined && table.properties.alignment) {
        // check table.properties.alignment
        setAlignment(table.properties.alignment);
      } else {
        setAlignment(doc.properties.horizontalAlignment);
      }

      if (para?.properties?.thematicBreak) {
        setThematicBreak(para.properties.thematicBreak);
      } else {
        setThematicBreak();
      }
      if (para.properties.pageBreak) {
        setPageBreak(para.properties.pageBreak);
      } else {
        setPageBreak();
      }
      if (para.properties.border) {
        setBorder(para.properties.border);
      } else {
        setBorder();
      }
      if (para.properties.indent) {
        if (para.properties.indent.left) {
          setLeftIndent(para.properties.indent.left);
        } else {
          setLeftIndent();
        }
        if (para.properties.indent.right) {
          setRightIndent(para.properties.indent.right);
        } else {
          setRightIndent();
        }
        if (para.properties.indent.firstLine) {
          setFirstLineIndent(para.properties.indent.firstLine);
        } else {
          setFirstLineIndent();
        }
        if (para.properties.indent.hanging) {
          setHangingIndent(para.properties.indent.hanging);
        } else {
          setHangingIndent();
        }
      }
      if (para.properties.shading) {
        setShadeColor(`#${para.properties.shading.color.toLowerCase()}`);
        setShadeFill(`#${para.properties.shading.fill.toLowerCase()}`);
        setShadeType(switchShadeType(para.properties.shading.type));
      }
      if (para.properties.spacing) {
        setSpacingBefore(para.properties.spacing.before);
        setSpacingAfter(para.properties.spacing.after);
        setSpacingLine(para.properties.spacing.line);
      } else if (table === undefined) {
        setSpacingBefore(doc.properties.spacing.before);
        setSpacingAfter(doc.properties.spacing.after);
        setSpacingLine(doc.properties.spacing.line);
      }
    }
  }, [docs, NIP_P]);

  let activeStyle = "bg-cyan-950 text-light-500";

  return (
    <section className="psm morphous bg-color">
      <h1 className="font-mono text-center pb-4">
        Paragraph Properties [{paraName}]
      </h1>
      {SIP.length === 3 ? (
        <ul className="flex justify-center border-b border-cyan-950 mb-6">
          <li className="-mb-px mr-1">
            <a
              className={`${
                paraStatus === "properties" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setParaStatus("properties")}
            >
              Properties
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                paraStatus === "xt" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setParaStatus("xt")}
            >
              XT
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                paraStatus === "mxt" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setParaStatus("mxt")}
            >
              MXT
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                paraStatus === "jsfun" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setParaStatus("jsfun")}
            >
              JS Fun
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                paraStatus === "docxfun" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setParaStatus("docxfun")}
            >
              DOCX Fun
            </a>
          </li>
        </ul>
      ) : null}

      {paraStatus === "properties" ? (
        <>
          {/* Get Properties */}
          <div className="flex gap-4 pb-4 bg-">
            <button className="bg-cyan-950 text-light-500 h-[40px] w-[100px]">
              Para Props
            </button>
            <button className="bg-cyan-950 text-light-500 h-[40px] w-[100px]">
              Text Props
            </button>
          </div>
          {/* background alignment and line break */}
          <div className="flex gap-4 pb-4">
            <div>
              <h2 className="pb-1">Para Color</h2>
              <Input
                className="h-[40px] w-[100px] p-0 shadow-none"
                value={shadeColor}
                blur={(e) => {
                  dispatch(
                    updateShading({
                      loc: "color",
                      path: RRIP_P,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
                change={(e) => setShadeColor(e.target.value)}
                type="color"
              />
              {/* <div
              className={`text-2xl h-[35px] w-[35px] flex items-center justify-center bg-success-500 cursor-pointer rounded`}
              onClick={() => {
                navigate(`${newPath}/${indexPath}/PP/SC/shadeColor`);
              }}
            >
              <GiCardPickup />
            </div> */}
            </div>
            <div>
              <h2 className="pb-1">Text Color</h2>
              <div className="flex items-center gap-2">
                <Input
                  className="h-[40px] w-[100px] p-0 shadow-none"
                  value={shadeFill}
                  blur={(e) => {
                    dispatch(
                      updateShading({
                        loc: "fill",
                        path: RRIP_P,
                        value: e.target.value,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    );
                  }}
                  change={(e) => setShadeFill(e.target.value)}
                  type="color"
                />
                {/* <div
                className={`text-2xl h-[35px] w-[35px] flex items-center justify-center bg-success-500 cursor-pointer rounded`}
                onClick={() => {
                  navigate(`${newPath}/${indexPath}/PP/SC/shadeFill`);
                }}
              >
                <GiCardPickup />
              </div> */}
              </div>
            </div>
            <div>
              <h2 className="pb-1">Shade Type</h2>
              <Dropdown
                placeholder="Shade Type"
                value={shadeType}
                options={shadingTypes}
                name="Shade Type"
                target="option"
                width={200}
                height={40}
                click={(value) => {
                  dispatch(
                    updateShading({
                      loc: "type",
                      path: RRIP_P,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>

            <div>
              <h2 className="pb-1">Line Break</h2>
              <Dropdown
                placeholder="Thematic Break"
                className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                value={thematicBreak}
                options={isTrueOrFalse}
                name="T or F"
                target="option"
                width={150}
                height={40}
                click={(value) => {
                  dispatch(
                    addThematicBreak({
                      para: RRIP_P,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>
          </div>
          {/* Pagebreak border and spacing */}
          <div className="flex flex-wrap gap-4 pb-4">
            <div>
              <h2 className="pb-1">Page Break</h2>
              <Dropdown
                placeholder="Page Break"
                className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                value={pageBreak}
                options={isTrueOrFalse}
                target="option"
                name="T or F"
                width={150}
                height={40}
                click={(value) => {
                  dispatch(
                    addPageBreak({
                      para: RRIP_P,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">Border</h2>
              <Dropdown
                placeholder="Border"
                className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                value={border}
                options={isTrueOrFalse}
                target="option"
                name="T or F"
                width={150}
                height={40}
                click={(value) => {
                  dispatch(
                    updateBorder({
                      para: RRIP_P,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">Spacing Before</h2>
              <Input
                className="h-[40px] w-[100px]"
                value={spacingBefore}
                blur={(e) =>
                  dispatch(
                    updateSpacing({
                      loc: "before",
                      para: RRIP_P,
                      doc: RRIP_DOC,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  )
                }
                change={(e) => setSpacingBefore(e.target.value)}
                type="text"
              />
            </div>
            <div>
              <h2 className="pb-1">Spacing After</h2>
              <Input
                className="h-[40px] w-[100px]"
                value={spacingAfter}
                blur={(e) =>
                  dispatch(
                    updateSpacing({
                      loc: "after",
                      para: RRIP_P,
                      doc: RRIP_DOC,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  )
                }
                change={(e) => setSpacingAfter(e.target.value)}
                type="text"
              />
            </div>
            <div>
              <h2 className="pb-1">Line</h2>
              <Input
                className="h-[40px] w-[100px]"
                value={spacingLine}
                blur={(e) =>
                  dispatch(
                    updateSpacing({
                      loc: "line",
                      para: RRIP_P,
                      doc: RRIP_DOC,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  )
                }
                change={(e) => setSpacingLine(e.target.value)}
                type="text"
              />
            </div>
          </div>
          {/* Indentation */}
          <div className="pb-4">
            <h1 className="pb-1">
              * Intentation won't work if left and firstLine are added together.
              *
            </h1>
            <div className="flex flex-wrap gap-4">
              <div>
                <h2 className="pb-1">Indent Left</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  type="text"
                  value={leftIndent}
                  blur={(e) =>
                    dispatch(
                      updateParaIndent({
                        loc: "left",
                        value: e.target.value,
                        para: RRIP_P,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    )
                  }
                  change={(e) => setLeftIndent(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">Indent Right</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  type="text"
                  value={rightIndent}
                  blur={(e) =>
                    dispatch(
                      updateParaIndent({
                        loc: "right",
                        value: e.target.value,
                        para: RRIP_P,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    )
                  }
                  change={(e) => setRightIndent(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">Indent FirstLine</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  type="text"
                  value={firstLineIndent}
                  blur={(e) =>
                    dispatch(
                      updateParaIndent({
                        loc: "firstLine",
                        value: e.target.value,
                        para: RRIP_P,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    )
                  }
                  change={(e) => setFirstLineIndent(e.target.value)}
                />
              </div>
              <div>
                <h2 className="pb-1">Indent Hanging</h2>
                <Input
                  className="h-[40px] w-[150px]"
                  type="text"
                  value={hangingIndent}
                  blur={(e) =>
                    dispatch(
                      updateParaIndent({
                        loc: "hanging",
                        value: e.target.value,
                        para: RRIP_P,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    )
                  }
                  change={(e) => setHangingIndent(e.target.value)}
                />
              </div>
            </div>
          </div>
          {/* Add Text or Image */}
          <div className="flex gap-4 pb-4">
            <div>
              <h2 className="pb-1">Alignment</h2>
              <Dropdown
                value={alignment}
                options={alignments}
                target="option"
                width={100}
                height={40}
                click={(value) => {
                  dispatch(
                    updateAlignment({
                      para: RRIP_P,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>

            <div>
              <h2 className="pb-1">Add Text</h2>
              <button
                className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
                onClick={() => {
                  dispatch(
                    addTextToPara({
                      para: RRIP_P,
                      docIndex,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
                // disabled={!eleName}
              >
                Text
              </button>
            </div>
            <div>
              <h2 className="pb-1">Add Image</h2>
              <button
                className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
                onClick={() => {
                  dispatch(setIndexPath(RRIP_P.join("/")));
                  if (SIPHF === "0_H" || SIPHF === "0_F") {
                    navigate(
                      `${baseURL}/${RRIP_P.join("/")}${
                        SIP.length > 3
                          ? "/SP/HFP/TBP/TR/TC/PP/SI"
                          : "/SP/HFP/PP/SI"
                      }`
                    );
                  } else {
                    navigate(
                      `${baseURL}/${RRIP_P.join("/")}${
                        SIP.length > 3 ? "/SP/TBP/TR/TC/PP/SI" : "/SP/PP/SI"
                      }`
                    );
                  }
                }}
                // disabled={!eleName}
              >
                Image
              </button>
            </div>
          </div>
        </>
      ) : paraStatus === "xt" ? (
        <div className="flex flex-col">
          <XtControls path={RRIP_P} dynEle={dynEle} operator="user" />
          <XtControls path={RRIP_P} dynEle={dynEle} operator="manager" />
        </div>
      ) : paraStatus === "mxt" ? (
        <div className="flex flex-col">
          <MxtControls path={RRIP_P} dynEle={dynEle} operator="user" />
          <MxtControls path={RRIP_P} dynEle={dynEle} operator="manager" />
        </div>
      ) : paraStatus === "jsfun" ? (
        <div className="flex flex-col pb-[80px]">
          <JsFunControls path={RRIP_P} dynEle={dynEle} />
        </div>
      ) : (
        <></>
      )}

      {/* {SIP.length !== 2 ? ( */}
      <div className="mb-2">
        <Outlet />
      </div>
      {/* ) : null} */}
    </section>
  );
};

export default ParagraphProperties;
