import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../utils/Input";
import { toast } from "react-toastify";
import {
  setDocs,
  addDoc,
  addPara,
  addTable,
  setIndexPath,
} from "../../../../slices/docsSlice";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

const DocumentControls = () => {
  const dispatch = useDispatch();
  // const { productInfo } = useSelector((state) => state.product);
  const {
    docs,
    indexPath,
    base_URL,
    SIP,
    RRIP_T,
    RRIP_P,
    RRIP_TC,
    RRIP_TR,
    RRIP_TB,
    RRIP_DOC,
    NIP_T,
    NIP_P,
    NIP_TC,
    NIP_TR,
    NIP_TB,
    NIP_DOC,
  } = useSelector((state) => state.docs);
  const [docName, setDocName] = useState();
  const [paraName, setParaName] = useState();
  const [tableName, setTableName] = useState();
  const [rows, setRows] = useState();
  const [cols, setCols] = useState();

  return (
    <section className="psm">
      <h1 className="font-mono text-center pb-4">Document Controls</h1>

      <h2 className="font-mono pb-2">Document Name</h2>
      <div className="flex gap-4 pb-6">
        <Input
          className="h-[40px] w-[250px]"
          value={docName}
          change={(e) => setDocName(e.target.value)}
          placeholder="Enter Document Name"
        />
        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (docName !== "") {
              console.log(docs.length - 1);
              dispatch(addDoc(docName));
              dispatch(setIndexPath(`${docs.length}_Doc`));
            } else {
              toast.error("Document name cannot be empty");
            }
          }}
        >
          Add Doc
        </button>
      </div>
      {/* <h2 className="font-mono pb-2">Paragraph Name</h2>
      <div className="flex gap-4 pb-6">
        <Input
          className="h-[40px] w-[250px]"
          value={paraName}
          change={(e) => setParaName(e.target.value)}
          placeholder="Enter Paragraph Name"
        />
        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (paraName !== undefined && docs !== undefined) {
              dispatch(
                addPara({
                  path: RRIP_DOC,
                  name: paraName,
                })
              );
            } else {
              toast.error("Para Name Cannot be empty");
            }
          }}
        >
          Add Para
        </button>
      </div>
      <h2 className="font-mono pb-2">Table Name, Rows and Cols</h2>
      <div className="flex flex-wrap gap-4 pb-6">
        <Input
          className="h-[40px] w-[250px]"
          value={tableName}
          change={(e) => setTableName(e.target.value)}
          placeholder="Enter Table Name"
        />
        <Input
          className="h-[40px] w-[150px]"
          value={rows}
          change={(e) => setRows(e.target.value)}
          placeholder="Enter no.of Rows"
          type="number"
        />
        <Input
          className="h-[40px] w-[150px]"
          value={cols}
          change={(e) => setCols(e.target.value)}
          placeholder="Enter no.of Cols"
          type="number"
        />

        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            if (tableName !== undefined && docs !== undefined) {
              dispatch(
                addTable({
                  path: RRIP_DOC,
                  name: tableName,
                  rows: parseFloat(rows),
                  cols: parseFloat(cols),
                })
              );
            } else {
              toast.error("Table Name Cannot be empty");
            }
          }}
        >
          Add Table
        </button>
      </div> */}
    </section>
  );
};

export default DocumentControls;
