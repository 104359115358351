import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createTestDoc } from "../rahulDocx/Functions";
import { rahulDocx } from "../rahulDocx/RahulDocx";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { setSingleID } from "../../../../slices/internalDependencySlice";
import { setDocument } from "../../../../slices/documentSlice";
import { useUpdateDocumentMutation } from "../../../../slices/documentsApiSlice";
import produceClone from "../../../../components/Lodash";

import RunFunctions from "./RunFunctions";
import { setEntryForm } from "../../../../slices/docsSlice";
import { getForms } from "../../../../slices/formsSlice";

const RunControls = () => {
  const dispatch = useDispatch();
  const { docId, docs, runStatus, indexPath } = useSelector(
    (state) => state.docs
  );
  const { document: mainFile } = useSelector((state) => state.document);
  const { internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const { forms, userForms } = useSelector((state) => state.forms);

  const [updateDocument, { isLoading: loadingUpdate }] =
    useUpdateDocumentMutation();

  const generateDocx = (doc, path) => {
    const testDoc = createTestDoc({ doc, path });
    Packer.toBlob(testDoc).then((blob) => {
      console.log(doc);
      saveAs(blob, `test.docx`);
      console.log("Document created successfully");
    });

    // rahulDocx(createTestDoc({ doc, path })).then((docfile) => {
    //   Packer.toBlob(docfile).then((blob) => {
    //     console.log(docfile);
    //     saveAs(blob, `PR_.docx`);
    //     console.log("Document created successfully");
    //   });
    // });
  };

  const n = (val) => {
    if (isNaN(parseFloat(val))) {
      return 0;
    }
    if (parseFloat(val) === Infinity) {
      return 0;
    }
    return parseFloat(val);
  };

  const dn = (val) => {
    return parseFloat(val).toFixed(2);
  };

  const tc = (val) => {
    if (typeof val !== "number") {
      return 0;
    } else if (isNaN(val)) {
      return 0;
    } else if (val === Infinity) {
      return 0;
    }
    return val;
  };

  let newForms = produceClone(forms);

  const updateDocumentHandler = async () => {
    const entryForm = produceClone(docs);
    entryForm.map((doc) => {
      delete doc.properties;
      delete doc.type;
      doc.ele.map((S) => {
        delete S.type;
        delete S.headers.type;
        delete S.footers.type;
        delete S.headers.ele;
        delete S.footers.ele;
        delete S.properties;
        S.ele.map((E) => {
          delete E.ele;
          delete E.properties;
          delete E.type;
          delete E.jsFun;
          delete E.docxFun;
          delete E.noc;
          delete E.nor;
        });
      });
    });

    console.log(entryForm);

    const filledEF = produceClone(entryForm);
    filledEF.map((doc) => {
      delete doc.name;
      doc.ele.map((S) => {
        delete S.name;
        S.ele.map((E) => {
          delete E.name;
          E.user?.XT?.map((xt) => {
            xt.name = xt.dv;
          });
          E.manager?.XT?.map((xt) => {
            xt.name = xt.dv;
          });
          if (E.user?.condition) {
            E.user.condition = 1;
            E.user.MXT.map((mxt) => {
              mxt.name = mxt.dv;
            });
            E.user.MXT = [E.user.MXT];
          }
          if (E.manager?.condition) {
            E.manager.condition = 1;
            E.manager.MXT.map((mxt) => {
              mxt.name = mxt.dv;
            });
            E.manager.MXT = [E.manager.MXT];
          }
        });
      });
    });

    console.log(filledEF, entryForm);

    try {
      const res = await updateDocument({
        docId,
        docs,
        entryForm,
        filledEF,
        indexPath,
      }).unwrap();
      if (res.document.category === mainFile.category) {
        console.log(res);
        dispatch(setDocument(res.document));
        dispatch(setEntryForm(res.document.filledEF));
        newForms[res.document.category] = res.document;
        newForms[`${res.document.category}_EF`] = res.document.filledEF;
        dispatch(
          getForms({
            forms: newForms,
            userForms,
          })
        );
      } else {
        internalDependency.map((doc, i) => {
          if (res.document.category === doc.category) {
            dispatch(
              setSingleID({
                i,
                doc: res.document,
              })
            );
            dispatch(setEntryForm(res.document.filledEF));
            newForms[res.document.category] = res.document;
            newForms[`${res.document.category}_EF`] = res.document.filledEF;
            dispatch(
              getForms({
                forms: newForms,
                userForms,
              })
            );
          }
        });
      }

      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  // console.log(docs);

  return (
    <div className="min-h-[50px] border-t w-full text-light-500 px-3 py-2 flex flex-wrap justify-between items-center gap-5">
      <div className="flex gap-4">
        <button
          className="bg-info-500 px-2 py-1"
          onClick={() => {
            var dataStr =
              "data:text/json;charset=utf-8," +
              encodeURIComponent(
                JSON.stringify({
                  ...mainFile,
                  document: docs,
                  indexPath,
                })
              );
            var dlAnchorElem = document.getElementById("downloadAnchorElem");
            dlAnchorElem.setAttribute("href", dataStr);
            dlAnchorElem.setAttribute("download", "CEF.json");
            dlAnchorElem.click();
          }}
        >
          Download JSON
          <a id="downloadAnchorElem"></a>
        </button>
        {!runStatus ? (
          <button
            className="bg-primary-500 px-2 py-1"
            onClick={() => updateDocumentHandler()}
          >
            Update Doc
          </button>
        ) : null}

        {docs.map((doc, di) => {
          // console.log(docs, doc);
          return (
            <button
              onClick={() => generateDocx(doc, `${di}`)}
              className="bg-warning-500 px-2 py-1 text-dark-900"
            >
              {doc.name}
            </button>
          );
        })}
      </div>
      <RunFunctions />
    </div>
  );
};

export default RunControls;
