import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  internalDependency: undefined,
  listIDS: undefined,
};

const internalDependencySlice = createSlice({
  name: "internalDependency",
  initialState,
  reducers: {
    setInternalDependency: (state, { payload }) => {
      state.internalDependency = payload;
    },
    setSingleID: (state, { payload }) => {
      state.internalDependency[payload.i] = payload.doc;
    },
    resetInternalDependency: (state, { payload }) => {
      state.internalDependency = undefined;
      state.listIDS = undefined;
    },
    setListIDS: (state, { payload }) => {
      const newListIDS = state.listIDS !== undefined ? [...state.listIDS] : [];
      newListIDS[payload.index] = payload.value;
      state.listIDS = newListIDS;
    },
    getListIDS: (state, { payload }) => {
      state.listIDS = payload;
    },
  },
});

export const {
  setInternalDependency,
  setSingleID,
  resetInternalDependency,
  setListIDS,
  getListIDS,
} = internalDependencySlice.actions;

export default internalDependencySlice.reducer;
