import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";
import Input from "../../utils/Input";
import Dropdown from "../../utils/Dropdown";

import { setDocument } from "../../slices/documentSlice";
import { useGetExternalDependenciesQuery } from "../../slices/externalDependencyApiSlice";
import {
  useGetDocumentsQuery,
  useAddInternalDependencyMutation,
  useAddEDMutation,
  useDeleteInternalDependencyMutation,
  useDeleteExternalDependencyMutation,
} from "../../slices/documentsApiSlice";
import { toast } from "react-toastify";

const AddDependency = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { params } = useParams();
  const [externalDependency, setExternalDependency] = useState("");
  const [role, setRole] = useState("");
  const [roleOptions, setRoleOptions] = useState("");

  const { document } = useSelector((state) => state.document);
  const { categories } = useSelector((state) => state.categories);
  const reducedCategories = categories.filter(
    (item) => item.category !== document.category
  );
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchCategory, setSearchCategory] = useState(
    reducedCategories[0]?.category
  );

  const {
    data: externalDependencies,
    refetch: refetchExternalDependencies,
    isLoading: externalDependenciesLoading,
  } = useGetExternalDependenciesQuery();

  const {
    data: documents,
    refetch: refetchDocuments,
    isLoading: documentsLoading,
  } = useGetDocumentsQuery({
    keyword: searchKeyword,
    category: searchCategory,
    params,
  });

  const [addInternalDependency, {}] = useAddInternalDependencyMutation();
  const [addED, {}] = useAddEDMutation();
  const [deleteInternalDependency, {}] = useDeleteInternalDependencyMutation();
  const [deleteExternalDependency, {}] = useDeleteExternalDependencyMutation();

  const addInternalDependencyHandler = async (name, category) => {
    try {
      const res = await addInternalDependency({
        id: document._id,
        data: {
          name,
          category,
        },
      }).unwrap();
      dispatch(setDocument(res.document));
      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const addExternalDependencyHandler = async () => {
    if (externalDependency === "") {
      toast.error("External Dependency is needed.");
    } else {
      try {
        const res = await addED({
          id: document._id,
          data: {
            name: externalDependency,
            role: role,
          },
        }).unwrap();
        dispatch(setDocument(res.document));
        toast.success(res.message);
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const deleteInternalDependencyHandler = async (name, category) => {
    try {
      const res = await deleteInternalDependency({
        id: document._id,
        data: {
          name,
          category,
        },
      }).unwrap();
      dispatch(setDocument(res.document));
      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const deleteExternalDependencyHandler = async (name, role) => {
    try {
      const res = await deleteExternalDependency({
        id: document._id,
        data: {
          name,
          role,
        },
      }).unwrap();
      dispatch(setDocument(res.document));
      toast.success(res.message);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (document === undefined) {
      navigate("/manager/CD");
    }
  }, document);

  return (
    <div className="psm gap-2">
      <div className="mb-10">
        <h2 className="font-mono pb-2">Document Description</h2>
        {document ? (
          <Table>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell>{document.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>{document.category}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Internal Dependency</TableCell>
              <TableCell style={{ padding: "0px" }}>
                {document.internalDependency ? (
                  <div className="flex gap-2">
                    {Object.keys(document.internalDependency).map(
                      (dependency) => {
                        return (
                          <div className="bg-cyan-950 text-white">
                            <h2 className="font-mono text-center bg-primary-500 p-2">
                              {dependency}
                            </h2>
                            {document.internalDependency[dependency].map(
                              (doc) => {
                                return (
                                  <div
                                    className="hover:bg-danger-500 cursor-pointer p-2"
                                    onClick={() =>
                                      deleteInternalDependencyHandler(
                                        doc.name,
                                        doc.category
                                      )
                                    }
                                  >
                                    <p>{doc.name}</p>
                                    {/* <p>{doc.category}</p>
                                    <p>{doc.entryForm}</p> */}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>External Dependency</TableCell>
              <TableCell style={{ padding: "0px" }}>
                <div className="flex gap-2">
                  {document.externalDependency.map((dependency) => {
                    return (
                      <div
                        className="bg-cyan-950 text-white p-2 hover:bg-danger-500 cursor-pointer"
                        onClick={() =>
                          deleteExternalDependencyHandler(
                            dependency.name,
                            dependency.role
                          )
                        }
                      >
                        <p>{dependency.name}</p>
                        <p>{dependency.role}</p>
                      </div>
                    );
                  })}
                </div>
              </TableCell>
            </TableRow>
          </Table>
        ) : null}
      </div>
      <div className="flex items-start gap-10">
        <div className="morphous p-4 flex-1">
          <h2 className="font-mono pb-2">Internal Dependency</h2>
          <div className="flex gap-4 pb-4">
            <Input
              type="text"
              value={searchKeyword}
              change={(e) => setSearchKeyword(e.target.value)}
              placeholder="Enter Doc Name"
            />
            <Dropdown
              options={reducedCategories}
              target="option.category"
              value={searchCategory}
              // name="category"
              click={(value) => setSearchCategory(value.category)}
              style={
                searchCategory
                  ? { backgroundColor: "#035afc", color: "white" }
                  : null
              }
            />
          </div>
          <Table>
            <TableRow isHeader={true}>
              <TableCell isHeader={true}>Name</TableCell>
              <TableCell isHeader={true}>Category</TableCell>
              <TableCell isHeader={true}>Add</TableCell>
            </TableRow>
            {documents?.documents?.map((document, sno) => {
              return (
                <TableRow
                  key={sno}
                  className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
                >
                  <TableCell>{document.name}</TableCell>
                  <TableCell>{document.category}</TableCell>
                  <TableCell
                    className="bg-cyan-950 text-center text-white cursor-pointer"
                    click={() =>
                      addInternalDependencyHandler(
                        document.name,
                        document.category
                      )
                    }
                  >
                    ADD
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </div>
        <div className="morphous p-4 flex-1">
          <h2 className="font-mono pb-2">External Dependency</h2>
          <div className="flex gap-4">
            <Dropdown
              options={externalDependencies}
              value={externalDependency}
              name="Dependency"
              target="option.name"
              click={(value) => {
                setExternalDependency(value.name);
                if (value.role === "Manager") {
                  setRoleOptions(["Manager"]);
                } else if (value.role === "User") {
                  setRoleOptions(["User"]);
                } else {
                  setRoleOptions(["Manager", "User"]);
                }
              }}
              style={
                externalDependency
                  ? { backgroundColor: "#035afc", color: "white" }
                  : null
              }
            />
            {roleOptions ? (
              <Dropdown
                options={roleOptions}
                value={role}
                name="Role"
                target="option"
                click={(value) => setRole(value)}
                style={
                  role ? { backgroundColor: "#035afc", color: "white" } : null
                }
              />
            ) : null}

            <button
              className="px-5 py-3 bg-cyan-950 text-light-500 font-mono"
              onClick={() => addExternalDependencyHandler()}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDependency;
