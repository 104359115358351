import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDocument } from "../../slices/documentSlice";
import {
  setDocs,
  setIndexPath,
  setCategory,
  setRunStatus,
} from "../../slices/docsSlice";
import { getForms } from "../../slices/formsSlice";
import {
  setListIDS,
  getListIDS,
  setInternalDependency,
} from "../../slices/internalDependencySlice";
import { useNavigate } from "react-router-dom";
import { useGetDocumentMutation } from "../../slices/documentsApiSlice";
import { toast } from "react-toastify";

const InternalDep = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);

  const { document } = useSelector((state) => state.document);
  const { forms, userForms } = useSelector((state) => state.forms);
  const { listIDS, internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const { userInfo } = useSelector((state) => state.auth);

  const [getDocument, {}] = useGetDocumentMutation();

  const getDocumentHandler = async (record) => {
    try {
      const res = await getDocument({ document: record, listIDS }).unwrap();
      console.log(res.userForms);
      dispatch(
        getForms({
          userForms: res.userForms,
          forms: res.forms,
        })
      );
      dispatch(setDocument(record));
      dispatch(setDocs(record.document));
      dispatch(setCategory(record.category));
      dispatch(setRunStatus(record.runStatus));
      dispatch(setIndexPath(record.indexPath));
      dispatch(getListIDS(res.listIDS));
      dispatch(setInternalDependency(res.internalDependency));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (document && forms && userForms && listIDS && internalDependency) {
      setIsReadyToNavigate(true);
    }
  }, [document, forms, userForms, listIDS, internalDependency]);

  useEffect(() => {
    if (isReadyToNavigate && document) {
      if (userInfo.role === "Manager") {
        navigate(`/manager/${document.category}/DC`);
      } else if (userInfo.role === "User") {
        navigate(`/Forms/EF/${document.category}_EF`);
      }
    } else {
    }
  }, [isReadyToNavigate, document]);

  useEffect(() => {
    if (!document) {
      navigate("/manager/CD");
    }
  }, [document]);

  return (
    <div className="psm wh-70 mx-auto">
      {document
        ? Object.keys(document.internalDependency).map((category, i) => {
            return (
              <div className="pb-10">
                <h2 className="font-mono pb-2">{category} [Select One]</h2>
                <div className="flex gap-4">
                  {document.internalDependency[category].map((dep) => {
                    return (
                      <button
                        className={`${
                          listIDS && listIDS[i]?.name === dep.name
                            ? "bg-primary-500"
                            : "bg-cyan-950"
                        } text-light-500 p-2`}
                        onClick={() => {
                          dispatch(
                            setListIDS({
                              index: i,
                              value: dep,
                            })
                          );
                        }}
                      >
                        {dep.name}
                      </button>
                    );
                  })}
                </div>
              </div>
            );
          })
        : null}
      <div className="flex justify-end">
        <button
          className="bg-cyan-950 text-light-500 p-2"
          onClick={() => {
            // if (userInfo.role === "User") {
            //   navigate(`/Forms/EF/${document.category}_EF`);
            // } else if (userInfo.role === "Manager") {
            //   navigate(`/manager/Forms/EF/${document.category}_EF`);
            // }
            getDocumentHandler(document);
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default InternalDep;
