import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IoCloseSharp } from "react-icons/io5";

import Input from "../../../utils/Input";
import NesDropDown from "./Elements/NesDropDown";
import {
  removeDoc,
  removeEle,
  setIndexPath,
  setEntryForm,
} from "../../../slices/docsSlice";
import { twipToPt, DxaToPt, dxaToPx } from "./UnitConversion";
import RunControls from "./Elements/RunControls";
import DeleteElement from "./Elements/DeleteElement";
import produceClone from "../../../components/Lodash";

import CreateSection from "./Slices/section";

const Document = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { sideBar } = useSelector((state) => state.util);
  const { docs, indexPath, SIP, NIP_DOC, dev } = useSelector(
    (state) => state.docs
  );
  const { document: mainFile } = useSelector((state) => state.document);
  const { internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const { forms, userForms } = useSelector((state) => state.forms);
  const colors = [
    "bg-blue-50",
    "bg-red-50",
    "bg-green-50",
    "bg-yellow-50",
    "bg-purple-50",
  ];

  const [scale, setScale] = useState(1); // Initial scale value

  const zoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom: 3x
  };

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // Min zoom: 0.5x
  };

  console.log(dev);

  const { pathname } = useLocation();
  let splitPath = pathname.split("/");
  if (splitPath.length >= 4) {
    let length = splitPath.length - 3;
    splitPath.splice(3, length);
  }
  const baseURL = splitPath.join("/");

  let SIPHF;
  if (SIP && SIP.length > 2) {
    SIPHF = SIP[2];
  }

  const bar = document.querySelector(".split__bar");

  let mouseDown = false;
  document.addEventListener("mousemove", (e) => {
    if (mouseDown) {
      const left = document.querySelector(".split__left");
      left.style.width = `${sideBar ? e.clientX - 250 : e.clientX}px`;
    }
  });
  document.addEventListener("mouseup", () => {
    mouseDown = false;
  });

  return (
    <div className="flex h-full">
      <div
        className={`border-r transition-transform ease-in-out transform ${
          sideBar
            ? "translate-x-0 w-[250px] flex"
            : "-translate-x-full w-[0px] overflow-hidden"
        } flex flex-col`}
      >
        {/* <div className="px-3 py-2 w-full">
          <div className="w-full">
            <h1 className="text-base">Search Document</h1>
            <Input className="h-[30px] mt-3" placeholder="Search Document" />
          </div>
        </div> */}
        <div className="px-3 overflow-auto" id="doc-scroll">
          {docs.length > 0 ? (
            <NesDropDown loc={pathname} options={docs} count={0} />
          ) : null}
        </div>
      </div>

      <div
        className={`flex-1 2xl:overflow-hidden transition-width ${
          sideBar ? "w-full" : "w-screen"
        } resize-x flex flex-wrap 2xl:flex-nowrap`}
      >
        {/* min-w-[874px] */} {/* 21.9cm */}
        <section className="split__left w-full 2xl:min-w-[21.9cm] items-center md:h-[500px] 2xl:h-auto">
          <div
            className="document-body px-3 py-2 w-full flex flex-col items-center overflow-auto relative"
            id="doc-scroll"
          >
            {docs !== undefined
              ? docs.map((doc, i1) => {
                  const colorClass = colors[i1 % colors.length];
                  return (
                    <section
                      className={`mb-5 relative psm ${colorClass} flex flex-col items-center gap-4 w-full origin-top-left`}
                      style={{ transform: `scale(${scale})` }}
                      id={`${i1}`}
                      key={i1}
                    >
                      <div
                        className="w-full cursor-pointer text-center"
                        onClick={(e) => {
                          console.log("Hello");
                          e.preventDefault();
                          e.stopPropagation();
                          dispatch(setIndexPath(`${i1}_Doc`));
                          navigate(`${baseURL}/${i1}_Doc/DP`);
                        }}
                      >
                        <a
                          href={`#${i1}`}
                          // onClick={() => {
                          //   dispatch(setIndexPath(`${i1}_Doc`));
                          //   navigate(`${baseURL}/${i1}_Doc/DP`);
                          // }}
                        >
                          <h2 className="font-mono mb-2">{doc.name}</h2>
                        </a>
                      </div>
                      <div
                        className={`${
                          NIP_DOC.startsWith(`${i1}`) ? "flex" : "hidden"
                        } absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          dispatch(removeDoc(doc.name));
                          navigate(`${baseURL}/DC`);
                        }}
                      >
                        <IoCloseSharp />
                      </div>
                      {/* 21cm */}
                      {doc.ele.map((sec, i2) => {
                        return (
                          <CreateSection
                            sec={sec}
                            index={`${i1}_${i2}`}
                            loc={`${i1}_Doc/${i2}_S`}
                            doc={doc}
                            baseURL={baseURL}
                            SIPHF={SIPHF}
                          />
                        );
                      })}
                    </section>
                  );
                })
              : null}
            <div className="fixed bottom-5 right-5 flex space-x-2 z-50">
              <button
                className="bg-cyan-950 text-white text-xl rounded-full w-10 h-10 flex items-center justify-center"
                onClick={zoomIn}
              >
                +
              </button>
              <button
                className="bg-cyan-950 text-white text-xl rounded-full w-10 h-10 flex items-center justify-center"
                onClick={zoomOut}
              >
                -
              </button>
            </div>
          </div>
          <RunControls />
        </section>
        <div
          className="min-w-[4px] cursor-col-resize border split__bar bg-gray-400 hidden 2xl:block"
          onMouseDown={() => (mouseDown = true)}
        ></div>
        <div
          className="border-danger-500 flex-1 min-w-[500px] 2xl:min-w-[800px] overflow-auto font-palanquin"
          id="doc-scroll"
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Document;
