export const getTextStyle = ({
  textProps,
  paraProps,
  cellProps,
  rowProps,
  tableProps,
  docProps,
}) => {
  const style = {};
  style.fontFamily = docProps.font;
  style.fontSize = docProps.fontSize;
  if (tableProps !== undefined) {
    if (tableProps.font) {
      style.fontFamily = tableProps.font;
    }
    if (tableProps.fontSize) {
      style.fontSize = tableProps.fontSize;
    } else {
      style.fontSize = docProps.fontSize;
    }
  }
  if (Object.keys(textProps).length !== 0) {
    // console.log(textProps.font);
    if (textProps.font) {
      style.fontFamily = textProps.font;
    }
    if (textProps.fontSize) {
      style.fontSize = textProps.fontSize;
    }
    if (textProps.allCaps) {
      style.textTransform = "uppercase";
    }
    if (textProps.bold) {
      style.fontWeight = "bold";
    }
    if (textProps.italics) {
      style.fontStyle = "italic";
    }
    if (textProps.underline) {
      style.textDecoration = "underline";
    }
    if (textProps.color) {
      style.color = `#${textProps.color}`;
    }
  }
  return style;
};
