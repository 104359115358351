import React, { useEffect, useState } from "react";
import Input from "../../../../utils/Input";
import Dropdown from "../../../../utils/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  // setDocs,
  addSection,
  setDocFont,
  setDocFontSize,
  setDocTabBorder,
  setDocTabWidth,
  setDocHorAlignment,
  setDocVerAlignment,
  setSpacing,
  setDocCellMargin,
} from "../../../../slices/docsSlice";
import { useLocation } from "react-router-dom";

import { switchWidthType } from "./CommFunctions";

const DocumentProperties = () => {
  const dispatch = useDispatch();
  const {
    docs,
    indexPath,
    base_URL,
    SIP,
    RRIP_T,
    RRIP_P,
    RRIP_TC,
    RRIP_TR,
    RRIP_TB,
    RRIP_DOC,
    NIP_T,
    NIP_P,
    NIP_TC,
    NIP_TR,
    NIP_TB,
    NIP_DOC,
  } = useSelector((state) => state.docs);
  const {
    fonts,
    fontSizes,
    alignments,
    cellAlignments,
    widthTypes,
    tableAlignments,
    isTrueOrFalse,
    headerFooterStyles,
  } = useSelector((state) => state.options);
  const [sectionName, setSectionName] = useState();
  const [headerType, setHeaderType] = useState();
  const [font, setFont] = useState();
  const [fontSize, setFontSize] = useState();
  const [widthType, setWidthType] = useState();
  const [width, setWidth] = useState();
  const [horizontalAlignment, setHorizontalAlignment] = useState();
  const [verticalAlignment, setVerticalAlignment] = useState();
  const [marginLeft, setMarginLeft] = useState();
  const [marginRight, setMarginRight] = useState();
  const [marginTop, setMarginTop] = useState();
  const [marginBottom, setMarginBottom] = useState();
  const [tableBorder, setTableBorder] = useState("false");
  const [spacingBefore, setSpacingBefore] = useState();
  const [spacingAfter, setSpacingAfter] = useState();
  const [spacingLine, setSpacingLine] = useState();

  let dynName = "docs";
  NIP_DOC.split("_").forEach((item, i) => {
    dynName =
      dynName +
      `['${item}'].${i === NIP_DOC.split("_").length - 1 ? "name" : "ele"}`;
  });

  useEffect(() => {
    docs.map((doc) => {
      setMarginLeft(doc.properties.cellMargin.left);
      setMarginRight(doc.properties.cellMargin.right);
      setMarginTop(doc.properties.cellMargin.top);
      setMarginBottom(doc.properties.cellMargin.bottom);
      setFont(doc.properties.font);
      setFontSize(doc.properties.fontSize);
      setTableBorder(`${doc.properties.tableBorder}`);
      setWidthType(doc.properties.tableWidth.type);
      setWidth(doc.properties.tableWidth.size);
      setHorizontalAlignment(doc.properties.horizontalAlignment);
      setVerticalAlignment(doc.properties.verticalAlignment);
      setSpacingBefore(doc.properties.spacing.before);
      setSpacingAfter(doc.properties.spacing.after);
      setSpacingLine(doc.properties.spacing.line);
      if (doc.headers) {
        setHeaderType(doc.headers.type);
      }
    });
  }, [docs, NIP_DOC]);

  return (
    <section className="psm">
      <h1 className="text-center pb-4">
        Document Properties [{eval(dynName)}]
      </h1>
      <div className="flex gap-4 pb-4">
        <Input
          className="h-[40px] w-[250px]"
          value={sectionName}
          change={(e) => setSectionName(e.target.value)}
          placeholder="Enter Section Name"
        />
        <button
          className="px-3 py-1 bg-cyan-950 text-light-500"
          onClick={() => {
            console.log(docs.length - 1);
            dispatch(
              addSection({
                name: sectionName,
                path: RRIP_DOC,
              })
            );
            setSectionName("");
            // Add this later
            // dispatch(setIndexPath(`${docs.length}_Doc`));
          }}
        >
          Add Section
        </button>
      </div>

      <div className="flex gap-4 pb-6">
        <div>
          <h2 className="pb-1">Table Border</h2>
          <Dropdown
            value={tableBorder}
            options={isTrueOrFalse}
            target="option"
            click={(value) => {
              dispatch(setDocTabBorder({ doc: RRIP_DOC, value }));
            }}
            width={150}
            height={40}
            style={
              tableBorder === "true"
                ? { backgroundColor: "#083344", color: "#f8f9fa" }
                : {}
            }
          />
        </div>
      </div>
      {/* <h2 className="pb-2">Page Border</h2> */}
      <div className="mb-2 flex gap-2">
        {/* <div className="min-w-[50px]">
          <p className="mb-2">Page Border</p>
        </div> */}
        <div className="min-w-[50px]">
          <p className="mb-2">Table Border</p>
        </div>
      </div>
      <div className="flex gap-2">
        <div className="mb-2">
          <p className="mb-2">Set Width Type</p>
          <Dropdown
            width={200}
            height={40}
            value={widthType}
            options={widthTypes}
            target="option"
            placeholder="width type"
            click={(value) => {
              dispatch(
                setDocTabWidth({
                  loc: "type",
                  doc: RRIP_DOC,
                  value,
                })
              );
            }}
          />
        </div>
        <div className="mb-2">
          <p className="mb-2">Set Width Size</p>
          <Input
            className="h-[40px] w-[150px]"
            value={width}
            type="number"
            blur={(e) => {
              dispatch(
                setDocTabWidth({
                  loc: "size",
                  doc: RRIP_DOC,
                  value: e.target.value,
                })
              );
            }}
            change={(e) => setWidth(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-x-5 gap-y-3 mt-1">
        <div className="mb-2">
          <p className="mb-2">Doc Tab Ver Alignment</p>
          <Dropdown
            width={200}
            height={40}
            value={verticalAlignment}
            options={cellAlignments}
            target="option"
            placeholder="alignment"
            click={(value) => {
              dispatch(
                setDocVerAlignment({
                  doc: RRIP_DOC,
                  value,
                })
              );
            }}
          />
        </div>
        <div className="mb-2">
          <p className="mb-2">Doc Hor Alignment</p>
          <Dropdown
            width={200}
            height={40}
            value={horizontalAlignment}
            options={alignments}
            target="option"
            placeholder="alignment"
            click={(value) => {
              dispatch(
                setDocHorAlignment({
                  doc: RRIP_DOC,
                  value,
                })
              );
            }}
          />
        </div>
        <div className="mb-2">
          <p className="mb-2">Doc Font</p>
          <Dropdown
            width={200}
            height={40}
            value={font}
            options={fonts}
            target="option"
            click={(value) => {
              dispatch(
                setDocFont({
                  doc: RRIP_DOC,
                  value,
                })
              );
            }}
          />
        </div>
        <div className="mb-2">
          <p className="mb-2">Doc Font Size</p>
          <Input
            className="h-[40px] w-[150px]"
            value={fontSize}
            type="text"
            blur={(e) =>
              dispatch(
                setDocFontSize({
                  doc: RRIP_DOC,
                  value: e.target.value,
                })
              )
            }
            change={(e) => setFontSize(e.target.value)}
          />
        </div>
      </div>

      <div className="mb-2">
        <p className="mb-2">Set Default Spacing for Paragraphs</p>
        <div className="flex gap-2 mb-2">
          <div className="min-w-[50px]">
            <p className="mb-2">Before</p>
            <Input
              className="h-[40px] w-[150px]"
              value={spacingBefore}
              blur={(e) =>
                dispatch(
                  setSpacing({
                    doc: RRIP_DOC,
                    loc: "before",
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setSpacingBefore(e.target.value)}
              type="text"
            />
          </div>
          <div className="min-w-[50px]">
            <p className="mb-2">After</p>
            <Input
              className="h-[40px] w-[150px]"
              value={spacingAfter}
              blur={(e) =>
                dispatch(
                  setSpacing({
                    doc: RRIP_DOC,
                    loc: "after",
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setSpacingAfter(e.target.value)}
              type="text"
            />
          </div>
          <div className="min-w-[50px]">
            <p className="mb-2">Line</p>
            <Input
              className="h-[40px] w-[150px]"
              value={spacingLine}
              blur={(e) =>
                dispatch(
                  setSpacing({
                    doc: RRIP_DOC,
                    loc: "line",
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setSpacingLine(e.target.value)}
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="mb-2">
        <p className="mb-2">Set Doc Cell Margin</p>
        <div className="flex flex-wrap gap-2 mb-2">
          <div className="min-w-[50px]">
            <p className="mb-2">Left</p>
            <Input
              className="h-[40px] w-[150px]"
              value={marginLeft}
              blur={(e) =>
                dispatch(
                  setDocCellMargin({
                    loc: "left",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setMarginLeft(e.target.value)}
              type="text"
            />
          </div>
          <div className="min-w-[50px]">
            <p className="mb-2">Right</p>
            <Input
              className="h-[40px] w-[150px]"
              value={marginRight}
              blur={(e) =>
                dispatch(
                  setDocCellMargin({
                    loc: "right",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setMarginRight(e.target.value)}
              type="text"
            />
          </div>
          <div className="min-w-[50px]">
            <p className="mb-2">Top</p>
            <Input
              className="h-[40px] w-[150px]"
              value={marginTop}
              blur={(e) =>
                dispatch(
                  setDocCellMargin({
                    loc: "top",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setMarginTop(e.target.value)}
              type="text"
            />
          </div>
          <div className="min-w-[50px]">
            <p className="mb-2">Bottom</p>
            <Input
              className="h-[40px] w-[150px]"
              value={marginBottom}
              blur={(e) =>
                dispatch(
                  setDocCellMargin({
                    loc: "bottom",
                    doc: RRIP_DOC,
                    value: e.target.value,
                  })
                )
              }
              change={(e) => setMarginBottom(e.target.value)}
              type="text"
            />
          </div>
        </div>
      </div>
      {/* <div className="mb-2">
        <p>Header</p>
        <div className="flex gap-2 items-center">
          <div className="mb-2">
            <p className="mb-2">Header Type</p>
            <Dropdown
              width={200}
              height={40}
              placeholder={headerType}
              value={headerType}
              options={headerFooterStyles}
              target="option"
              click={(value) => {
                dispatch(addHeader({ doc: RRIP_DOC, value }));
              }}
            />
          </div>
          <button
            className="bg-primary-500 rounded h-[30px] outline-none px-2 cursor-pointer"
            onClick={() => {
              dispatch(addParaToHeader({ doc: RRIP_DOC }));
            }}
          >
            Para
          </button>
          <button
            className="bg-warning-500 text-dark-900 rounded h-[30px] outline-none px-2 cursor-pointer"
            onClick={() => {
              dispatch(addTableToHeader({ doc: RRIP_DOC }));
            }}
          >
            Table
          </button>
        </div>
      </div> */}
      {/* <div className="w-[33.3px] border h-[33.3px]"></div>
      <ol>
        <li>
          <b>Paragraph Properties</b>
        </li>
        <li>Line Spacing: 1.15lines</li>
        <li>Paragraph Spacing: No space before or after paragraphs</li>
        <li>Indentation: First line indent of 0.5 inches</li>
      </ol>
      <ol>
        <li>
          <b>Headers and Footers</b>
        </li>
        <li>Header</li>
        <li>Footer</li>
      </ol> */}
    </section>
  );
};

export default DocumentProperties;
