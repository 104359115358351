export const inchesToTwips = (inches) => inches * 1440;
export const cmToTwips = (cm) => cm * 567;
export const pointsToTwips = (points) => points * 20;
export const twipsToInches = (twips) => twips / 1440;
export const twipsToCm = (twips) => twips / 567;
export const twipsToPoints = (twips) => twips / 20;
// Convert inches, cm, or points to DXA
export const inchesToDxa = (inches) => inches * 1440;
export const cmToDxa = (cm) => cm * 567;
export const pointsToDxa = (points) => points * 20;

// Convert DXA back to inches, cm, or points (for display purposes)
export const dxaToInches = (dxa) => dxa / 1440;
export const dxaToCm = (dxa) => dxa / 567;
export const dxaToPoints = (dxa) => dxa / 20;

export const convertToTwips = (value, unit) => {
  switch (unit) {
    case "inches":
      return inchesToTwips(value);
    case "cm":
      return cmToTwips(value);
    case "points":
      return pointsToTwips(value);
    default:
      return value;
  }
};

export const convertFromTwips = (twips, unit) => {
  switch (unit) {
    case "inches":
      return twipsToInches(twips);
    case "cm":
      return twipsToCm(twips);
    case "points":
      return twipsToPoints(twips);
    default:
      return twips;
  }
};

export const convertToDxa = (value, unit) => {
  switch (unit) {
    case "inches":
      return inchesToDxa(value);
    case "cm":
      return cmToDxa(value);
    case "points":
      return pointsToDxa(value);
    case "dxa":
      return value; // If already in DXA, return the value as-is
    default:
      throw new Error(`Unsupported unit: ${unit}`);
  }
};

export const convertFromDxa = (dxa, unit) => {
  switch (unit) {
    case "inches":
      return dxaToInches(dxa);
    case "cm":
      return dxaToCm(dxa);
    case "points":
      return dxaToPoints(dxa);
    case "dxa":
      return dxa; // If output needs to be in DXA, return as-is
    default:
      throw new Error(`Unsupported unit: ${unit}`);
  }
};

const twipToPt = (value) => {
  return value / 20;
};

function DxaToPt(dxa) {
  var pointsPerDxa = 1 / 20;
  var points = dxa * pointsPerDxa;
  return points;
}

function dxaToPx(dxa, dpr = window.devicePixelRatio || 1) {
  const pixels = dxa * dpr;
  return pixels;
}

export const convertToPx = (value) => {
  if (typeof value === "string") {
    if (value.endsWith("in")) {
      return parseFloat(value) * 96; // 1 inch = 96px
    }
    if (value.endsWith("cm")) {
      return parseFloat(value) * 37.7952755906; // 1 cm = ~37.8px
    }
    if (value.endsWith("%")) {
      return value; // 1 cm = ~37.8px
    }
    // Add other conversions as needed (e.g., pt to px, etc.)
  }
  if (isNaN(parseFloat(value))) {
    return "0px";
  } else {
    return parseFloat(value);
  }
  // Default case: assume it's already in pixels or numbers
};

export const cmToPx = (cm) => {
  if (cm !== undefined && isNaN(cm)) {
    cm = cm.slice(0, -2);
  }
  const ppi = 96; // Assuming 96 PPI
  const pixels = (parseFloat(cm) / 2.54) * ppi;
  return Math.round(pixels);
};

export { twipToPt, DxaToPt, dxaToPx };
