import React, { useState, useEffect, useRef } from "react";
import CodeMirror from "@uiw/react-codemirror";

import { javascript } from "@codemirror/lang-javascript";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";

import { useDispatch, useSelector } from "react-redux";
import { updateJSFunc } from "../slices/docsSlice";
import _ from "lodash";

const Editor = ({ value, path }) => {
  const dispatch = useDispatch();
  // const [func, setFunc] = useState(value);
  const { RRIP_P, RRIP_T } = useSelector((state) => state.docs);

  const inputRef = useRef(value);

  const debouncedUpdate = useRef(
    _.debounce((value) => {
      dispatch(updateJSFunc({ path: path, value })); // Dispatch after debounce delay
    }, 500)
  ).current;

  // const handleInputChange = (e) => {
  //   inputRef.current = e.target.value;
  //   debouncedUpdate(inputRef.current); // Use debounce to avoid frequent dispatches
  // };

  const onChange = React.useCallback((value, viewUpdate) => {
    // setFunc(value);
    inputRef.current = value;
    debouncedUpdate(inputRef.current);
    localStorage.setItem("func", value);
  }, []);
  return (
    <CodeMirror
      defaultValue={inputRef.current}
      value={inputRef.current}
      // height='800px'
      // width='870px'
      theme={vscodeDark}
      extensions={[javascript({ jsx: true })]}
      onChange={onChange}
      basicSetup={{
        closeBrackets: false,
      }}
    />
  );
};
export default Editor;
