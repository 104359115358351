import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ManagerRoute = () => {
  const { userInfo } = useSelector((state) => state.auth);
  // console.log(userInfo);
  return userInfo && userInfo.role === "Manager" ? (
    <Outlet />
  ) : (
    <Navigate to="/SignIn" replace />
  );
};
export default ManagerRoute;
