import { twipToPt, convertToPx } from "../../UnitConversion";
export const getParaStyle = ({
  paraProps,
  cellProps,
  rowProps,
  tableProps,
  sectionProps,
  docProps,
}) => {
  const style = {};
  if (tableProps === undefined) {
    style.textAlign = docProps.horizontalAlignment;
  }
  if (tableProps !== undefined && tableProps.horizontalAlignment) {
    style.textAlign = tableProps.horizontalAlignment;
  }
  if (paraProps?.alignment) {
    style.textAlign = paraProps?.alignment;
  }

  if (tableProps !== undefined) {
    // if(tableProps)
  }
  if (paraProps !== undefined && Object.keys(paraProps)?.length > 0) {
    Object.keys(paraProps).map((prop) => {
      if (prop === "alignment") {
        style.textAlign =
          paraProps.alignment === "justified" ? "justify" : paraProps.alignment;
      } else if (prop === "spacing") {
        style.marginTop = paraProps.spacing.before;
        style.marginBottom = paraProps.spacing.after;
        // paraProps.spacing.line is not applied
      } else if (prop === "border") {
        style.border = "1px solid black";
      } else if (prop === "shading") {
        style.backgroundColor = `#${paraProps.shading.color}`;
        style.color = `#${paraProps.shading.fill}`;
      } else if (prop === "indent") {
        style.paddingLeft = convertToPx(paraProps.indent.left);
        style.paddingRight = convertToPx(paraProps.indent.right);
        style.textIndent = convertToPx(paraProps.indent.firstLine);
        if (paraProps.indent.hanging) {
          style.textIndent = convertToPx(paraProps.indent.hanging);
          style.paddingLeft = convertToPx(paraProps.indent.hanging);
        }
      }
    });
    // console.log(style);
    return style;
  } else {
    return style;
  }
};
