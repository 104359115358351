import React, { useEffect } from "react";
import {
  useGetImagesQuery,
  useDeleteImageMutation,
} from "../../../../../../slices/imageApiSlice";
import Loader from "../../../../../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { IoCloseSharp } from "react-icons/io5";
import { toast } from "react-toastify";

import { addImageToPara } from "../../../../../../slices/docsSlice";

const SavedImages = () => {
  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useGetImagesQuery();
  const { SIP, RRIP_P } = useSelector((state) => state.docs);

  const [deleteImage, {}] = useDeleteImageMutation();

  // const docIndex = parseFloat(SIP[0].split("_")[0]);

  let SIPHF = SIP[2];

  useEffect(() => {
    refetch();
  }, []);

  const deleteImageHandler = async (imageId) => {
    try {
      const res = await deleteImage(imageId).unwrap();
      toast.success(res.message);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <div className="psm morphous bg-color">
      <h2 className="font-mono text-center pb-4">Saved Images</h2>
      <div className="flex flex-wrap justify-between gap-4">
        {isLoading ? (
          <Loader />
        ) : (
          data?.map((image) => {
            return (
              <div className="relative">
                <div
                  className={`absolute right-[-7px] top-[-6px] cursor-pointer bg-red-500 rounded-full text-light-500 shadow shadow-red-500/50`}
                  onClick={(e) => {
                    console.log("Hello");
                    e.preventDefault();
                    deleteImageHandler(image._id);
                  }}
                >
                  <IoCloseSharp />
                </div>
                <img
                  className="object-cover h-48 w-96 rounded-lg morphous"
                  src={image.image}
                  alt={image.name}
                  style={{
                    // width: `${image.transformation.width}px`,
                    // height: `${image.transformation.height}px`,
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    dispatch(
                      addImageToPara({
                        para: RRIP_P,
                        image,
                        layoutEle:
                          SIPHF === "0_H" || SIPHF === "0_F"
                            ? SIPHF
                            : undefined,
                      })
                    )
                  }
                />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SavedImages;
