import { useState } from "react";
import { getCellStyle } from "./Styles/cellStyle";
import DeleteElement from "../Elements/DeleteElement";
import { useDispatch, useSelector } from "react-redux";
import { setIndexPath } from "../../../../slices/docsSlice";

import CreatePara from "./Para";
import CreateTable from "./Table";
import { useNavigate } from "react-router-dom";

const CreateCell = ({
  cell,
  index,
  loc,
  row,
  table,
  sec,
  doc,
  baseURL,
  SIPHF,
}) => {
  // console.log(index);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { RRIP_TC, NIP_TC, category, dev } = useSelector((state) => state.docs);
  const [show, setShow] = useState(false);

  const toggleDropdown = (index) => {
    setShow((prevShowOptions) => {
      return {
        ...prevShowOptions,
        [index]: !prevShowOptions[index],
      };
    });
  };

  const cellStyles = getCellStyle({
    cellProps: cell?.properties,
    rowProps: row?.properties,
    tableProps: table?.properties,
    sectionProps: sec?.properties,
    docProps: doc?.properties,
  });
  let splitIndex = index.split("_");
  let tableCopy = "CES";
  let rowCopy = "CES";
  let cellCopy = "CES";
  let textCopy = "CES";
  if (dev) {
    if (false) {
      tableCopy = "";
      rowCopy = "";
      cellCopy = "";
      textCopy = "";
    }
    splitIndex.map((i, index) => {
      if (index <= splitIndex.length - 3) {
        tableCopy = tableCopy + `[${i}].ele`;
      }
      if (index <= splitIndex.length - 2) {
        rowCopy = rowCopy + `[${i}].ele`;
      }
      cellCopy = cellCopy + `[${i}].ele`;
      textCopy = textCopy + `[${i}].ele`;
    });
    tableCopy = tableCopy.slice(0, -4);
    rowCopy = rowCopy.slice(0, -4);
    cellCopy = cellCopy.slice(0, -4);
    textCopy = textCopy + "[0].ele[0]";
  }
  return (
    <td
      key={index}
      className={`${
        NIP_TC === `${index}` ? "bg-blue-100 cursor-pointer" : "cursor-pointer"
      } relative`}
      id={`${index}`}
      style={cellStyles}
      colspan={cell?.properties?.columnSpan}
      rowSpan={cell?.properties?.rowSpan}
      onClick={(e) => {
        toggleDropdown(index);
        e.preventDefault();
        e.stopPropagation();
        if (cell?.ele[0] && cell?.ele[0]?.ele[0]) {
          if (!dev) {
            dispatch(setIndexPath(`${loc}/0_P/0_T`));
            navigate(`${baseURL}/${loc}/0_P/0_T/SP/TBP/TR/TC/PP/TP`);
          }
        } else {
          if (!dev) {
            dispatch(setIndexPath(`${loc}`));
            navigate(`${baseURL}/${loc}/SP/TBP/TR/TC`);
          }
        }
      }}
      // onDoubleClick={(e) => {
      //   e.preventDefault();
      //   e.stopPropagation();
      //   let val = `CES`;
      //   if (false) {
      //     val = "";
      //   }
      //   splitIndex.map((i, index) => {
      //     console.log(index, splitIndex.length);
      //     if (index <= splitIndex.length - 2) {
      //       val = val + `[${i}].ele`;
      //     }
      //   });
      //   navigator.clipboard.writeText(val);
      // }}
    >
      {cell?.ele.map((ele, i1) => {
        if (ele?.type === "para") {
          return (
            <CreatePara
              para={ele}
              index={`${index}_${i1}`}
              loc={`${loc}/${i1}_P`}
              cell={cell}
              row={row}
              table={table}
              sec={sec}
              doc={doc}
              baseURL={baseURL}
              SIPHF={SIPHF}
            />
          );
        } else if (ele?.type === "table") {
          return (
            <CreateTable
              table={ele}
              index={`${index}_${i1}`}
              loc={`${loc}/${i1}_TB`}
              sec={sec}
              doc={doc}
              baseURL={baseURL}
              SIPHF={SIPHF}
            />
          );
        }
      })}
      {dev ? (
        <span className="bg-cyan-950 text-light-500 text-[5pt] rounded-full absolute w-[10px] h-[10px] right-0 left-0 top-0 bottom-0 flex items-center justify-center">
          {cell.name}
        </span>
      ) : null}
      {dev && show[index] ? (
        <div
          className={`absolute right-0 mt-0 bg-white border rounded-md shadow-lg z-[200] font-mono text-sm ${
            show[index] ? "block" : "hidden"
          }`}
        >
          <ul className="py-1">
            <li
              className="px-4 py-2 hover:bg-gray-100"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleDropdown(index);
                navigator.clipboard.writeText(textCopy);
              }}
            >
              Text
            </li>
            <li
              className="px-4 py-2 hover:bg-gray-100"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleDropdown(index);
                navigator.clipboard.writeText(cellCopy);
              }}
            >
              Cell
            </li>
            <li
              className="px-4 py-2 hover:bg-gray-100"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleDropdown(index);
                navigator.clipboard.writeText(rowCopy);
              }}
            >
              Row
            </li>
            <li
              className="px-4 py-2 hover:bg-gray-100"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                toggleDropdown(index);
                navigator.clipboard.writeText(tableCopy);
              }}
            >
              Table
            </li>
          </ul>
        </div>
      ) : null}
    </td>
  );
};

export default CreateCell;
