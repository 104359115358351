import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  useGetEntryFormsQuery,
  useDeleteEntryFormMutation,
} from "../../slices/entryFormApiSlice";
import { useGetRecordMutation } from "../../slices/documentsApiSlice";
import { toast } from "react-toastify";
import { getForms } from "../../slices/formsSlice";
import { setDocument } from "../../slices/documentSlice";
import {
  setDocs,
  setEntryForm,
  setCategory,
  setIndexPath,
  setDocId,
  setRunStatus,
} from "../../slices/docsSlice";
import {
  getListIDS,
  setInternalDependency,
} from "../../slices/internalDependencySlice";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import Table from "../../utils/Table/Table";
import TableRow from "../../utils/Table/TableRow";
import TableCell from "../../utils/Table/TableCell";
import Dropdown from "../../utils/Dropdown";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const { document } = useSelector((state) => state.document);
  const { forms, userForms } = useSelector((state) => state.forms);
  const { docs, indexPath, category } = useSelector((state) => state.docs);
  const { listIDS, internalDependency } = useSelector(
    (state) => state.internalDependency
  );

  const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);
  const [record, setRecord] = useState();

  const [getRecord, {}] = useGetRecordMutation();
  const [deleteEntryForm, {}] = useDeleteEntryFormMutation();
  const { data: entryForms, isLoading, refetch } = useGetEntryFormsQuery();

  const getRecordsHandler = async (form) => {
    try {
      const res = await getRecord({ record: form }).unwrap();
      dispatch(
        getForms({
          userForms: res.record.userForms,
          forms: res.record.forms,
        })
      );
      dispatch(setDocument(res.record.forms[res.record.category]));
      dispatch(setDocId(res.record.forms[res.record.category]._id));
      dispatch(setDocs(res.record.forms[res.record.category].document));
      dispatch(setEntryForm(res.record.forms[`${res.record.category}_EF`]));
      dispatch(setCategory(res.record.category));
      dispatch(setRunStatus(res.record.runStatus));
      dispatch(setIndexPath(res.record.forms[res.record.category].indexPath));
      let ID_Arr = [];
      res.record.listIDS.map((ID) => {
        ID_Arr.push(res.record.forms[ID.category]);
      });
      dispatch(getListIDS(res.record.listIDS));
      dispatch(setInternalDependency(ID_Arr));
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const deleteEntryFormHandler = async (entryFormId) => {
    try {
      const res = await deleteEntryForm(entryFormId).unwrap();
      toast.success(res.message);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    if (
      document &&
      forms &&
      userForms &&
      docs &&
      indexPath &&
      category &&
      listIDS &&
      internalDependency
    ) {
      setIsReadyToNavigate(true);
    }
  }, [
    document,
    forms,
    userForms,
    docs,
    indexPath,
    category,
    listIDS,
    internalDependency,
  ]);

  useEffect(() => {
    if (isReadyToNavigate && record) {
      navigate(`/manager/${record.category}/DC`);
    } else {
    }
  }, [isReadyToNavigate, record]);

  useEffect(() => {
    refetch();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <div className="psm">
      <h2 className="font-mono pb-2">Records</h2>
      {isLoading ? (
        <Loader />
      ) : (
        <Table style={{ paddingBottom: "200px" }}>
          <TableRow isHeader={true}>
            <TableCell isHeader={true}>S.No</TableCell>
            <TableCell isHeader={true}>Name</TableCell>
            <TableCell isHeader={true}>Category</TableCell>
            <TableCell isHeader={true}>User</TableCell>
            <TableCell isHeader={true}>Email</TableCell>
            <TableCell isHeader={true}>Status</TableCell>
            <TableCell isHeader={true}>IDS</TableCell>
            <TableCell isHeader={true} style={{ width: "200px" }}>
              Select
            </TableCell>
            <TableCell isHeader={true}>Date</TableCell>
            <TableCell isHeader={true}>X</TableCell>
          </TableRow>
          {entryForms?.map((form, sno) => {
            // console.log(form);
            return (
              <TableRow
                key={sno}
                className={(sno + 1) % 2 === 0 ? "bg-gray-100" : null}
              >
                <TableCell>{sno + 1}</TableCell>
                <TableCell>{form.docName}</TableCell>
                <TableCell>{form.category}</TableCell>
                <TableCell>{form.name}</TableCell>
                <TableCell>{form.email}</TableCell>
                <TableCell style={{ padding: "0px" }}>
                  <Dropdown
                    options={[
                      "Work under process",
                      "Payment pending",
                      "Completed",
                      "Terminated",
                    ]}
                    target="option"
                    value={form.status}
                    click={(value) => {}}
                  />
                </TableCell>
                <TableCell style={{ padding: "0px" }}>
                  <div className="flex gap-2">
                    {form.listIDS.map((ID, i) => (
                      <p>
                        {ID.name}
                        {i !== form.listIDS.length - 1 ? "," : ""}
                      </p>
                    ))}
                  </div>
                </TableCell>
                <TableCell
                  style={{
                    padding: "0px",
                    cursor: "pointer",
                    backgroundColor: "#083344",
                    color: "#f8f9fa",
                    textAlign: "center",
                  }}
                  click={() => {
                    setRecord(form);
                    getRecordsHandler(form);
                  }}
                >
                  Select
                </TableCell>
                <TableCell>{formatDate(form.createdAt)}</TableCell>
                <TableCell
                  className={`cursor-pointer bg-danger-500 font-bold text-light-500 text-center`}
                  click={() => deleteEntryFormHandler(form._id)}
                >
                  X
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      )}
    </div>
  );
};

export default Dashboard;
