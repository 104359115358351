export const BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8010" : "https://www.doccreator.in";
export const DOCUMENTS_URL = "/api/documents";
export const USERS_URL = "/api/users";
export const AGENCY_URL = "/api/agency";
export const PASSWORD_URL = "/api/password";
export const CATEGORY_URL = "/api/category";
export const EXTERNALDEPENDENCY_URL = "/api/externalDependency";
export const ENTRYFORM_URL = "/api/entryForm";
export const IMAGE_URL = "/api/image";
// export const ORDERS_URL = "/api/orders";
// export const PAYPAL_URL = "/api/config/paypal";
