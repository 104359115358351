import { DOCUMENTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const documentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDocuments: builder.query({
      query: ({ keyword, category, pageNumber }) => ({
        url: DOCUMENTS_URL,
        params: { keyword, category, pageNumber },
        credentials: "include",
      }),
      keepUnusedDataFor: 5,
      providesTags: ["Documents"],
    }),
    getDocumentDetails: builder.query({
      query: (documentId) => ({
        url: `${DOCUMENTS_URL}/${documentId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createDocument: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Document"],
    }),
    updateDocStatus: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/docStatus`,
        method: "POST",
        body: data,
      }),
    }),
    getInternalDependency: builder.mutation({
      query: (date) => ({
        url: `${DOCUMENTS_URL}/internalDependency`,
        method: "POST",
        body: date,
      }),
    }),
    addInternalDependency: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/internalDependency/${data.id}`,
        method: "POST",
        body: data.data,
      }),
    }),
    addED: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/externalDependency/${data.id}`,
        method: "POST",
        body: data.data,
      }),
      providesTags: ["Document"],
    }),
    deleteInternalDependency: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/internalDependency/${data.id}`,
        method: "DELETE",
        body: data.data,
      }),
    }),
    deleteExternalDependency: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/externalDependency/${data.id}`,
        method: "DELETE",
        body: data.data,
      }),
    }),
    updateDocument: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/${data.docId}`,
        method: "PUT",
        body: data,
      }),
      // invalidatesTags: ["Documents"],
    }),
    uploadDocumentImage: builder.mutation({
      query: (data) => ({
        url: `/api/upload`,
        method: "POST",
        body: data,
      }),
    }),
    deleteDocument: builder.mutation({
      query: (documentId) => ({
        url: `${DOCUMENTS_URL}/${documentId}`,
        method: "DELETE",
      }),
      providesTags: ["Document"],
    }),
    createReview: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/${data.documentId}/reviews`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Document"],
    }),
    getTopDocuments: builder.query({
      query: () => `${DOCUMENTS_URL}/top`,
      keepUnusedDataFor: 5,
    }),
    getAssignedDocs: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/getAssignedDocs`,
        method: "POST",
        body: data,
      }),
    }),
    getSingleAssignedDoc: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/getSingleAssignedDoc`,
        method: "POST",
        body: data,
      }),
    }),
    getRecord: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/getRecord`,
        method: "POST",
        body: data,
      }),
    }),
    getDocument: builder.mutation({
      query: (data) => ({
        url: `${DOCUMENTS_URL}/getDocument`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetDocumentsQuery,
  useGetDocumentDetailsQuery,
  useCreateDocumentMutation,
  useUpdateDocStatusMutation,
  useGetInternalDependencyMutation,
  useAddInternalDependencyMutation,
  useAddEDMutation,
  useDeleteInternalDependencyMutation,
  useDeleteExternalDependencyMutation,
  useUpdateDocumentMutation,
  useUploadDocumentImageMutation,
  useDeleteDocumentMutation,
  useCreateReviewMutation,
  useGetTopDocumentsQuery,
  useGetAssignedDocsMutation,
  useGetSingleAssignedDocMutation,
  useGetRecordMutation,
  useGetDocumentMutation,
} = documentsApiSlice;
