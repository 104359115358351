import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Input from "../../utils/Input";
import Dropdown from "../../utils/Dropdown";
import {
  updateXT,
  updateCondition,
  updateMXT,
  getForms,
} from "../../slices/formsSlice";

const EntryForm = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { forms } = useSelector((state) => state.forms);
  const { dev } = useSelector((state) => state.docs);

  const { pathname } = useLocation();
  const splitPath = pathname.split("/");
  let lastIndex = splitPath[splitPath.length - 1];
  let entryForm = lastIndex.slice(0, -3);

  let path = "forms[entryForm]";
  if (userInfo.role === "Manager") {
    path = "forms[entryForm].document";
  }

  return (
    <div className="psm overflow-auto h-[900px]">
      {forms && entryForm
        ? eval(path)?.map((doc, i1) => {
            return (
              <div key={i1} className="flex flex-col gap-4">
                {userInfo.role === "Manager" ? (
                  <h2 className="font-mono bg-cyan-950 text-light-500 text-center p-2">
                    Manager
                  </h2>
                ) : null}
                {doc.ele.map((S, i2) => {
                  return (
                    <div key={i2} className="flex flex-col gap-4">
                      {S.ele.map((E, i3) => {
                        return (
                          <div key={i3} className="flex flex-col gap-4">
                            {userInfo.role === "Manager" ? (
                              <>
                                <div className="flex flex-wrap gap-4">
                                  {E.manager?.XT?.map((XT, i4) => {
                                    if (typeof XT.name === "string") {
                                      return (
                                        <div>
                                          <h2 className="font-mono pb-2">
                                            {XT.name}
                                          </h2>
                                          <Input
                                            placeholder={XT.name}
                                            type="text"
                                            className="w-[250px] h-[40px]"
                                            value={
                                              forms[lastIndex][i1].ele[i2].ele[
                                                i3
                                              ].manager.XT[i4].name
                                            }
                                            change={(e) =>
                                              dispatch(
                                                updateXT({
                                                  EF: lastIndex,
                                                  value: e.target.value,
                                                  doc: i1,
                                                  sec: i2,
                                                  ele: i3,
                                                  xt: i4,
                                                  role: userInfo.role.toLowerCase(),
                                                })
                                              )
                                            }
                                            click={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              if (
                                                userInfo.role === "Manager" &&
                                                dev
                                              ) {
                                                let val = `manager.XT[${i4}].name`;
                                                navigator.clipboard.writeText(
                                                  val
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div>
                                          <h2 className="font-mono pb-2">
                                            Drop Down
                                          </h2>
                                          <Dropdown
                                            options={XT.name}
                                            target="option"
                                            value={
                                              forms[lastIndex][i1].ele[i2].ele[
                                                i3
                                              ].manager.XT[i4].name
                                            }
                                            click={(value) => {
                                              if (
                                                userInfo.role === "Manager" &&
                                                dev
                                              ) {
                                                let val = `manager.XT[${i4}].name`;
                                                navigator.clipboard.writeText(
                                                  val
                                                );
                                              }
                                              dispatch(
                                                updateXT({
                                                  EF: lastIndex,
                                                  value: value,
                                                  doc: i1,
                                                  sec: i2,
                                                  ele: i3,
                                                  xt: i4,
                                                  role: userInfo.role.toLowerCase(),
                                                })
                                              );
                                            }}
                                            width={250}
                                            height={40}
                                          />
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                                <div className="flex flex-col gap-4">
                                  {E.manager?.condition ? (
                                    <>
                                      <div>
                                        <h2 className="pb-2">
                                          No.of {`${E.manager.condition}s`}
                                        </h2>
                                        <Input
                                          type="number"
                                          className="w-[250px] h-[40px]"
                                          value={
                                            forms[lastIndex][i1].ele[i2].ele[i3]
                                              .manager.condition
                                          }
                                          change={(e) => {
                                            dispatch(
                                              updateCondition({
                                                EF: lastIndex,
                                                doc: i1,
                                                sec: i2,
                                                ele: i3,
                                                role: userInfo.role.toLowerCase(),
                                                value: e.target.value,
                                              })
                                            );
                                          }}
                                        />
                                      </div>

                                      {forms[lastIndex][i1].ele[i2].ele[
                                        i3
                                      ].manager.MXT.map((ROW, i4) => {
                                        return (
                                          <div className="flex flex-wrap gap-4">
                                            {ROW.map((MXT, i5) => {
                                              if (
                                                typeof E.manager.MXT[i5]
                                                  .name === "string"
                                              ) {
                                                return (
                                                  <div>
                                                    <h2 className="font-mono pb-2">
                                                      {E.manager.MXT[i5].name}
                                                    </h2>
                                                    <Input
                                                      placeholder={
                                                        E.manager.MXT[i5].name
                                                      }
                                                      type="text"
                                                      className="w-[250px] h-[40px]"
                                                      value={
                                                        forms[lastIndex][i1]
                                                          .ele[i2].ele[i3]
                                                          .manager.MXT[i4][i5]
                                                          .name
                                                      }
                                                      change={(e) =>
                                                        dispatch(
                                                          updateMXT({
                                                            EF: lastIndex,
                                                            value:
                                                              e.target.value,
                                                            doc: i1,
                                                            sec: i2,
                                                            ele: i3,
                                                            row: i4,
                                                            mxt: i5,
                                                            role: userInfo.role.toLowerCase(),
                                                          })
                                                        )
                                                      }
                                                      click={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (
                                                          userInfo.role ===
                                                            "Manager" &&
                                                          dev
                                                        ) {
                                                          let val = `manager.MXT[${i4}][${i5}].name`;
                                                          navigator.clipboard.writeText(
                                                            val
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div>
                                                    <h2 className="font-mono pb-2">
                                                      Drop Down
                                                    </h2>
                                                    <Dropdown
                                                      options={
                                                        E.manager.MXT[i5].name
                                                      }
                                                      target="option"
                                                      value={
                                                        forms[lastIndex][i1]
                                                          .ele[i2].ele[i3]
                                                          .manager.MXT[i4][i5]
                                                          .name
                                                      }
                                                      click={(value) => {
                                                        if (
                                                          userInfo.role ===
                                                            "Manager" &&
                                                          dev
                                                        ) {
                                                          let val = `manager.MXT[${i4}][${i5}].name`;
                                                          navigator.clipboard.writeText(
                                                            val
                                                          );
                                                        }
                                                        dispatch(
                                                          updateMXT({
                                                            EF: lastIndex,
                                                            value: value,
                                                            doc: i1,
                                                            sec: i2,
                                                            ele: i3,
                                                            row: i4,
                                                            mxt: i5,
                                                            role: userInfo.role.toLowerCase(),
                                                          })
                                                        );
                                                      }}
                                                      width={250}
                                                      height={40}
                                                    />
                                                  </div>
                                                );
                                              }
                                            })}
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })
        : null}
      {forms && entryForm
        ? eval(path)?.map((doc, i1) => {
            return (
              <div key={i1} className="flex flex-col gap-4">
                {userInfo.role === "Manager" ? (
                  <h2 className="font-mono bg-cyan-950 text-light-500 text-center p-2">
                    User
                  </h2>
                ) : null}
                {doc.ele.map((S, i2) => {
                  return (
                    <div key={i2} className="flex flex-col gap-4">
                      {S.ele.map((E, i3) => {
                        return (
                          <div key={i3} className="flex flex-col gap-4">
                            {userInfo.role === "User" ||
                            userInfo.role === "Manager" ? (
                              <>
                                <div className="flex flex-wrap gap-4">
                                  {E.user?.XT?.map((XT, i4) => {
                                    console.log(XT);
                                    if (typeof XT.name === "string") {
                                      return (
                                        <div>
                                          <h2 className="font-mono pb-2">
                                            {XT.name}
                                          </h2>
                                          <Input
                                            placeholder={XT.name}
                                            type="text"
                                            className="w-[250px] h-[40px]"
                                            value={
                                              forms[lastIndex][i1].ele[i2].ele[
                                                i3
                                              ].user.XT[i4].name
                                            }
                                            change={(e) =>
                                              dispatch(
                                                updateXT({
                                                  EF: lastIndex,
                                                  value: e.target.value,
                                                  doc: i1,
                                                  sec: i2,
                                                  ele: i3,
                                                  xt: i4,
                                                  role: "user",
                                                })
                                              )
                                            }
                                            click={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              if (
                                                userInfo.role === "Manager" &&
                                                dev
                                              ) {
                                                let val = `user.XT[${i4}].name`;
                                                navigator.clipboard.writeText(
                                                  val
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div>
                                          <h2 className="font-mono pb-2">
                                            Drop Down
                                          </h2>
                                          <Dropdown
                                            options={XT.name}
                                            target="option"
                                            value={
                                              forms[lastIndex][i1].ele[i2].ele[
                                                i3
                                              ].user.XT[i4].name
                                            }
                                            click={(value) => {
                                              if (
                                                userInfo.role === "Manager" &&
                                                dev
                                              ) {
                                                let val = `user.XT[${i4}].name`;
                                                navigator.clipboard.writeText(
                                                  val
                                                );
                                              }
                                              dispatch(
                                                updateXT({
                                                  EF: lastIndex,
                                                  value: value,
                                                  doc: i1,
                                                  sec: i2,
                                                  ele: i3,
                                                  xt: i4,
                                                  role: "user",
                                                })
                                              );
                                            }}
                                            width={250}
                                            height={40}
                                          />
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                                <div className="flex flex-col gap-4">
                                  {E.user?.condition ? (
                                    <>
                                      <div>
                                        <h2 className="pb-2">
                                          No.of {`${E.user.condition}s`}
                                        </h2>
                                        <Input
                                          type="number"
                                          className="w-[250px] h-[40px]"
                                          value={
                                            forms[lastIndex][i1].ele[i2].ele[i3]
                                              .user.condition
                                          }
                                          change={(e) => {
                                            dispatch(
                                              updateCondition({
                                                EF: lastIndex,
                                                doc: i1,
                                                sec: i2,
                                                ele: i3,
                                                role: "user",
                                                value: e.target.value,
                                              })
                                            );
                                          }}
                                        />
                                      </div>

                                      {forms[lastIndex][i1].ele[i2].ele[
                                        i3
                                      ].user?.MXT?.map((ROW, i4) => {
                                        return (
                                          <div className="flex gap-4">
                                            {ROW?.map((MXT, i5) => {
                                              if (
                                                typeof E.user.MXT[i5].name ===
                                                "string"
                                              ) {
                                                return (
                                                  <div>
                                                    <h2 className="font-mono pb-2">
                                                      {E.user.MXT[i5].name}
                                                    </h2>
                                                    <Input
                                                      placeholder={
                                                        E.user.MXT[i5].name
                                                      }
                                                      type="text"
                                                      className="w-[250px] h-[40px]"
                                                      value={
                                                        forms[lastIndex][i1]
                                                          .ele[i2].ele[i3].user
                                                          .MXT[i4][i5].name
                                                      }
                                                      change={(e) =>
                                                        dispatch(
                                                          updateMXT({
                                                            EF: lastIndex,
                                                            value:
                                                              e.target.value,
                                                            doc: i1,
                                                            sec: i2,
                                                            ele: i3,
                                                            row: i4,
                                                            mxt: i5,
                                                            role: "user",
                                                          })
                                                        )
                                                      }
                                                      click={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (
                                                          userInfo.role ===
                                                            "Manager" &&
                                                          dev
                                                        ) {
                                                          let val = `user.MXT[${i4}][${i5}].name`;
                                                          navigator.clipboard.writeText(
                                                            val
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div>
                                                    <h2 className="font-mono pb-2">
                                                      Drop Down
                                                    </h2>
                                                    <Dropdown
                                                      options={
                                                        E.user.MXT[i5].name
                                                      }
                                                      target="option"
                                                      value={
                                                        forms[lastIndex][i1]
                                                          .ele[i2].ele[i3].user
                                                          .MXT[i4][i5].name
                                                      }
                                                      click={(value) => {
                                                        if (
                                                          userInfo.role ===
                                                            "Manager" &&
                                                          dev
                                                        ) {
                                                          let val = `user.MXT[${i4}][${i5}].name`;
                                                          navigator.clipboard.writeText(
                                                            val
                                                          );
                                                        }

                                                        dispatch(
                                                          updateMXT({
                                                            EF: lastIndex,
                                                            value: value,
                                                            doc: i1,
                                                            sec: i2,
                                                            ele: i3,
                                                            row: i4,
                                                            mxt: i5,
                                                            role: "user",
                                                          })
                                                        );
                                                      }}
                                                      width={250}
                                                      height={40}
                                                    />
                                                  </div>
                                                );
                                              }
                                            })}
                                          </div>
                                        );
                                      })}
                                    </>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default EntryForm;
