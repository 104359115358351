import { getRowStyle } from "./Styles/rowStyle";
import DeleteElement from "../Elements/DeleteElement";
import { useDispatch, useSelector } from "react-redux";
import { setIndexPath } from "../../../../slices/docsSlice";
import CreateCell from "./Cell";

const CreateRow = ({ row, index, loc, table, sec, doc, baseURL, SIPHF }) => {
  const dispatch = useDispatch();
  const { RRIP_TR, NIP_TR } = useSelector((state) => state.docs);
  const rowStyles = getRowStyle({ rowProps: row?.properties });
  return (
    <tr key={index} id={`${index}`} style={rowStyles}>
      {row?.ele?.map((cell, i1) => {
        return (
          <CreateCell
            cell={cell}
            index={`${index}_${i1}`}
            loc={`${loc}/${i1}_TC`}
            row={row}
            table={table}
            sec={sec}
            doc={doc}
            baseURL={baseURL}
            SIPHF={SIPHF}
          />
        );
      })}
    </tr>
  );
};

export default CreateRow;
