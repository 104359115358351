import { cmToPx } from "../../UnitConversion";
export const getRowStyle = ({ rowProps }) => {
  const style = {};
  if (rowProps.width) {
    style.display = "table-row";
  }
  style.height = rowProps.height ? cmToPx(rowProps.height.value) : "20px";
  // if (rowProps !== undefined && Object.keys(rowProps)?.length > 0) {
  //   Object.keys(rowProps).map((prop) => {
  //     if (prop === "width") {
  //       if (rowProps.width.type === "dxa") {
  //         style.display = "table-row";
  //         // style.width = "100%";
  //         // style.border = "2px solid red";
  //       } else if (rowProps.width.type === "nil") {
  //         style.display = "table-row";
  //       }
  //     }
  //     // style.display = "flex";
  //   });
  //   // console.log(style);
  //   return style;
  // }
  return style;
};
