import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetInternalDependencyMutation } from "../../slices/documentsApiSlice";
import { setInternalDependency } from "../../slices/internalDependencySlice";
import { setForms } from "../../slices/formsSlice";

const Forms = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { document: mainFile } = useSelector((state) => state.document);
  const { listIDS, internalDependency } = useSelector(
    (state) => state.internalDependency
  );
  const { userForms, forms } = useSelector((state) => state.forms);

  const [GetInternalDependency, {}] = useGetInternalDependencyMutation();

  const { pathname } = useLocation();
  let splitLoc = pathname.split("/");

  // useEffect(() => {
  //   const getInternalDepndencyHandler = async () => {
  //     try {
  //       const res = await GetInternalDependency(listIDS).unwrap();
  //       dispatch(setInternalDependency(res));
  //       if (res.length === 0) {
  //         dispatch(
  //           setForms({
  //             userInfo,
  //             document: mainFile,
  //           })
  //         );
  //       } else {
  //         dispatch(
  //           setForms({
  //             userInfo,
  //             document: mainFile,
  //             internalDependency: res,
  //           })
  //         );
  //       }
  //     } catch (err) {
  //       toast.error(err?.data?.message || err.error);
  //     }
  //   };
  //   getInternalDepndencyHandler();
  // }, [listIDS]);

  let activeStyle = "bg-cyan-950 text-light-500";
  return (
    <div className="psm flex flex-col gap-4">
      <ul className="flex justify-center border-b border-cyan-950 mb-6">
        {userForms?.map((userForm) => {
          console.log(userForm);
          const lastIndex = userForm.name.split("_");
          if (userForm.role !== "Manager") {
            return (
              <li className="mr-1">
                <a
                  className={`${
                    splitLoc[splitLoc.length - 1] === userForm.name
                      ? activeStyle
                      : ""
                  } inline-block py-2 px-4 font-semibold cursor-pointer`}
                  onClick={() => {
                    if (lastIndex[lastIndex.length - 1] === "EF") {
                      navigate(`/Forms/EF/${userForm.name}`);
                    } else {
                      navigate(`/Forms/${userForm.name}`);
                    }
                  }}
                >
                  {userForm.name}
                </a>
              </li>
            );
          }
        })}
      </ul>
      <div className="psm">
        <Outlet />
      </div>
    </div>
  );
};

export default Forms;
