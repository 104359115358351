import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navigationLock: false,
  headerFooterTypes: ["default", "first", "even"],
  sectionTypes: ["continuous", "evenPage", "oddPage", "nextPage", "nextColumn"],
  sectionOrientation: ["portrait", "landscape"],
  fonts: ["Arial MT", "Calibri", "Trebuchet MS"],
  fontSizes: [
    "7pt",
    "7.5pt",
    "8pt",
    "8.5pt",
    "9pt",
    "9.5pt",
    "10pt",
    "10.5pt",
    "11pt",
    "11.5pt",
    "12pt",
    "12.5pt",
    "13pt",
    "13.5pt",
    "14pt",
    "14.5pt",
    "15pt",
    "15.5pt",
    "16pt",
    "16.5pt",
    "17pt",
    "17.5pt",
    "18pt",
    "18.5pt",
    "19pt",
    "19.5pt",
    "20pt",
  ],
  isTrueOrFalse: ["true", "false"],
  colors: ["None", "Red", "Blue", "Green", "Yellow"],
  alignments: ["left", "center", "right", "justified"],
  cellAlignments: ["top", "center", "bottom"],
  borderStyles: [
    "single",
    "dashDotStroked",
    "dashed",
    "dashSmallGap",
    "dotDash",
    "dotDotDash",
    "dotted",
    "double",
    "doubleWave",
    "inset",
    "nil",
    "none",
    "outset",
    "thick",
    "thickThinLargeGap",
    "thickThinMediumGap",
    "thickThinSmallGap",
    "thinThickLargeGap",
    "thinThickMediumGap",
    "thinThickSmallGap",
    "thinThickThinLargeGap",
    "thinThickThinMediumGap",
    "thinThickThinSmallGap",
    "threeDEmboss",
    "threeDEngrave",
    "triple",
    "wave",
  ],
  underlines: [
    "SINGLE",
    "WORDS",
    "DOUBLE",
    "THICK",
    "DOTTED",
    "DOTTEDHEAVY",
    "DASH",
    "DASHEDHEAVY",
    "DASHLONG",
    "DASHLONGHEAVY",
    "DOTDASH",
    "DASHDOTHEAVY",
    "DOTDOTDASH",
    "DASHDOTDOTHEAVY",
    "WAVE",
    "WAVYHEAVY",
    "WAVYDOUBLE",
    "NONE",
  ],
  shadingTypes: [
    "SOLID",
    "CLEAR",
    "DIAGONAL_CROSS",
    "DIAGONAL_STRIPE",
    "HORIZONTAL_CROSS",
    "HORIZONTAL_STRIPE",
    "NIL",
    "PERCENT_5",
    "PERCENT_10",
    "PERCENT_12",
    "PERCENT_15",
    "PERCENT_20",
    "PERCENT_25",
    "PERCENT_30",
    "PERCENT_35",
    "PERCENT_37",
    "PERCENT_40",
    "PERCENT_45",
    "PERCENT_50",
    "PERCENT_55",
    "PERCENT_60",
    "PERCENT_62",
    "PERCENT_65",
    "PERCENT_70",
    "PERCENT_75",
    "PERCENT_80",
    "PERCENT_85",
    "PERCENT_87",
    "PERCENT_90",
    "PERCENT_95",
    "REVERSE_DIAGONAL_STRIPE",

    "THIN_DIAGONAL_CROSS",
    "THIN_DIAGONAL_STRIPE",
    "THIN_HORIZONTAL_CROSS",
    "THIN_REVERSE_DIAGONAL_STRIPE",
    "THIN_VERTICAL_STRIPE",
    "VERTICAL_STRIPE",
  ],
  widthTypes: ["dxa", "pct", "auto", "nil"],
  tableAlignments: ["LEFT", "RIGHT", "CENTER", "START", "END", "JUSTIFIED"],
  rowHeightRules: ["auto", "atLeast", "exact"],
  pageBorderDisplay: ["allPages", "firstPage", "notFirstPage"],
  pageBorderOffsetFrom: ["page", "text"],
  pageBorderZOrder: ["back", "front"],
  pageBorders: ["display", "offsetFrom", "zOrder"],
  bullet: ["•", "°", "■"],
};

const optionsSlice = createSlice({
  name: "options",
  initialState,
  reducers: {
    setShowColors: (state, { payload }) => {
      state.showColors = !state.showColors;
    },
  },
});

export const { setShowColors } = optionsSlice.actions;

export default optionsSlice.reducer;
