import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import produceClone from "../../../../components/Lodash"; // DO NOT REMOVE
import { setDocs, setRunStatus } from "../../../../slices/docsSlice";
import { setFormsRunStatus } from "../../../../slices/formsSlice";

const RunFunctions = () => {
  const dispatch = useDispatch();
  const { docs, category, runStatus, entryForm } = useSelector(
    (state) => state.docs
  );
  const { userForms, forms } = useSelector((state) => state.forms);
  const { document: mainFile } = useSelector((state) => state.document);
  const { internalDependency } = useSelector(
    (state) => state.internalDependency
  );

  const TABLE = {
    ele: [],
    properties: {},
    name: "",
    nor: 0,
    noc: 0,
    type: "table",
  };

  const ROW = {
    ele: [],
    properties: {},
    name: "",
    type: "row",
  };

  const CELL = {
    ele: [],
    properties: {},
    name: "",
    type: "cell",
  };

  const PARA = {
    ele: [],
    properties: {},
    name: 0,
    type: "para",
  };

  const TEXT = {
    t: "",
    properties: {},
    type: "text",
  };

  let newForms = produceClone(forms);
  Object.keys(newForms).forEach((form) => {
    window[form] = newForms[form];
  });

  console.log(entryForm);

  const generateReport = () => {
    let CES = produceClone(docs);
    let NEF = produceClone(entryForm);
    CES.map((doc, i1) => {
      let XTS = NEF[i1].XTS;
      doc.ele.map((sec, i2) => {
        sec.ele.forEach((E, i3) => {
          let USER = NEF[i1].ele[i2].ele[i3].user;
          let MANAGER = NEF[i1].ele[i2].ele[i3].manager;
          if (E.jsFun) {
            let func = eval("(" + E.jsFun + ")");
            func(E, E.ele, USER, MANAGER);
          }
        });
      });
    });
    dispatch(setDocs(CES));
  };

  return (
    <div className="flex gap-4">
      <button
        className="bg-cyan-950 text-light-500 px-2 py-1"
        onClick={() => {
          if (category === mainFile.category) {
            dispatch(setFormsRunStatus({ category, value: true }));
            dispatch(setRunStatus(true));
          } else {
            internalDependency.map((doc, i) => {
              if (category === doc.category) {
                dispatch(
                  setFormsRunStatus({
                    category,
                    value: true,
                  })
                );
                dispatch(setRunStatus(true));
              }
            });
          }
          generateReport();
        }}
      >
        Run
      </button>
      {runStatus ? (
        <button
          className="bg-warning-500 text-black px-2 py-1"
          onClick={() => {
            if (category === mainFile.category) {
              dispatch(setFormsRunStatus({ category, value: false }));
              dispatch(setRunStatus(false));
              dispatch(setDocs(mainFile.document));
            } else {
              internalDependency.map((doc, i) => {
                if (category === doc.category) {
                  dispatch(
                    setFormsRunStatus({
                      category,
                      value: false,
                    })
                  );
                  dispatch(setRunStatus(false));
                  dispatch(setDocs(doc.document));
                }
              });
            }
          }}
        >
          Clear
        </button>
      ) : null}
    </div>
  );
};

export default RunFunctions;
