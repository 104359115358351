import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Dropdown from "../../../../../utils/Dropdown";
import Input from "../../../../../utils/Input";
import { RiSave3Fill } from "react-icons/ri";
import { GiCardPickup } from "react-icons/gi";
import {
  updateTableWidth,
  updateTableAlignment,
  updateTableVerAlignment,
  updateTableHorAlignment,
  updateTableBorder,
  updateTableTextSize,
  setTableCellMargin,
  addColumnToTable,
  addRowToTable,
  updateShading,
} from "../../../../../slices/docsSlice";
import {
  returnPropEle,
  switchWidthType,
  switchVerAlignment,
} from "../CommFunctions";
import XtControls from "../Tools/xtControls";
import MxtControls from "../Tools/mxtControls";
import JsFunControls from "../Tools/jsFunControls";

const TableProperties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    docs,
    indexPath,
    SIP,
    RRIP_T,
    RRIP_P,
    RRIP_TC,
    RRIP_TR,
    RRIP_TB,
    RRIP_DOC,
    NIP_T,
    NIP_P,
    NIP_TC,
    NIP_TR,
    NIP_TB,
    NIP_DOC,
  } = useSelector((state) => state.docs);
  const { tableName } = useSelector((state) => state.element);
  // const { productInfo } = useSelector((state) => state.product);
  const {
    fonts,
    fontSizes,
    widthTypes,
    alignments,
    cellAlignments,
    tableAlignments,
    isTrueOrFalse,
    shadingTypes,
  } = useSelector((state) => state.options);
  const [font, setFont] = useState();
  const [fontSize, setFontSize] = useState();
  const [widthType, setWidthType] = useState();
  const [width, setWidth] = useState();
  const [alignment, setAlignment] = useState();
  const [border, setBorder] = useState();
  const [marginTop, setMarginTop] = useState();
  const [marginBottom, setMarginBottom] = useState();
  const [marginLeft, setMarginLeft] = useState();
  const [marginRight, setMarginRight] = useState();
  const [verticalAlignment, setVerticalAlignment] = useState();
  const [horizontalAlignment, setHorizontalAlignment] = useState();
  // const [shadeColor, setShadeColor] = useState();
  // const [shadeFill, setShadeFill] = useState();
  // const [shadeType, setShadeType] = useState();
  const [tableStatus, setTableStatus] = useState("properties");

  let SIPHF = SIP[2];

  useEffect(() => {
    if (docs.length === 0) {
      navigate(`${newPath}`);
    }
  }, [docs]);

  useEffect(() => {
    setTableStatus("properties");
  }, [indexPath]);

  let dynEle = "docs";
  NIP_TB.split("_").forEach((item, i) => {
    dynEle =
      dynEle + `['${item}'].${i === NIP_TB.split("_").length - 1 ? "" : "ele"}`;
  });

  dynEle = dynEle.slice(0, -1);

  useEffect(() => {
    const table = RRIP_TB ? returnPropEle(docs, RRIP_TB) : undefined;
    const doc = returnPropEle(docs, RRIP_DOC);

    if (table) {
      if (table.properties.width) {
        setWidthType(table.properties.width.type);
        setWidth(table.properties.width.size);
      } else {
        setWidthType(doc.properties.tableWidth.type);
        setWidth(doc.properties.tableWidth.size);
      }
      if (table.properties.alignment) {
        setAlignment(switchVerAlignment(table.properties.alignment));
      }
      if (table.properties.verticalAlignment) {
        setVerticalAlignment(table.properties.verticalAlignment);
      } else {
        setVerticalAlignment(doc.properties.verticalAlignment);
      }
      if (table.properties.horizontalAlignment) {
        setHorizontalAlignment(table.properties.horizontalAlignment);
      }
      if (`${table.properties.borders}` !== "undefined") {
        setBorder(table.properties.borders === true ? "true" : "false");
      } else {
        setBorder(doc.properties.tableBorder);
      }

      if (table.properties.margins) {
        setMarginLeft(table.properties.margins.left);
        setMarginRight(table.properties.margins.right);
        setMarginTop(table.properties.margins.top);
        setMarginBottom(table.properties.margins.bottom);
      } else {
        setMarginLeft(doc.properties.cellMargin.left);
        setMarginRight(doc.properties.cellMargin.right);
        setMarginTop(doc.properties.cellMargin.top);
        setMarginBottom(doc.properties.cellMargin.bottom);
      }

      if (table.properties.font) {
        setFont(table.properties.font);
      } else {
        setFont(doc.properties.font);
      }

      if (table.properties.fontSize) {
        setFontSize(table.properties.fontSize);
      } else {
        setFontSize(doc.properties.fontSize);
      }
    }
  }, [docs, NIP_DOC, NIP_TB]);

  let activeStyle = "bg-cyan-950 text-light-500";

  return (
    <section className="psm bg-gray-100">
      <h1 className="font-mono text-center pb-4">
        Table Properties [{tableName ? tableName : ""}]
      </h1>
      {SIP.length === 3 ? (
        <ul className="flex justify-center border-b border-cyan-950 mb-6">
          <li className="-mb-px mr-1">
            <a
              className={`${
                tableStatus === "properties" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setTableStatus("properties")}
            >
              Properties
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                tableStatus === "xt" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setTableStatus("xt")}
            >
              XT
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                tableStatus === "mxt" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setTableStatus("mxt")}
            >
              MXT
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                tableStatus === "jsfun" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setTableStatus("jsfun")}
            >
              JS Fun
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`${
                tableStatus === "docxfun" ? activeStyle : ""
              } inline-block py-2 px-4 font-semibold cursor-pointer`}
              onClick={() => setTableStatus("docxfun")}
            >
              DOCX Fun
            </a>
          </li>
        </ul>
      ) : null}
      {/* <RetractableDiv
        show={false}
        name={`Table Properties`}
        highLight={`${dynName ? eval(dynName) : ""}`}
      > */}
      {tableStatus === "properties" ? (
        <>
          <div className="flex flex-wrap gap-4 pb-4">
            <div>
              <h2 className="pb-1">Set Width Type</h2>
              <Dropdown
                className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                value={widthType}
                options={widthTypes}
                target="option"
                name="WT"
                placeholder="width type"
                width={150}
                height={40}
                click={(value) => {
                  dispatch(
                    updateTableWidth({
                      loc: "type",
                      table: RRIP_TB,
                      doc: RRIP_DOC,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">Set Width Size</h2>
              <Input
                className="h-[40px] w-[150px]"
                value={width}
                type="text"
                blur={(e) => {
                  dispatch(
                    updateTableWidth({
                      loc: "size",
                      table: RRIP_TB,
                      doc: RRIP_DOC,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
                change={(e) => setWidth(e.target.value)}
              />
            </div>
            <div>
              <h2 className="pb-1">Table Alignment</h2>
              <Dropdown
                className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                value={alignment}
                name="alignment"
                options={tableAlignments}
                target="option"
                placeholder="alignment"
                width={150}
                height={40}
                click={(value) => {
                  dispatch(
                    updateTableAlignment({
                      table: RRIP_TB,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">Vertical Alignment</h2>
              <Dropdown
                className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                value={verticalAlignment}
                options={cellAlignments}
                target="option"
                placeholder="alignment"
                width={150}
                height={40}
                click={(value) => {
                  dispatch(
                    updateTableVerAlignment({
                      table: RRIP_TB,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">Horizontal Alignment</h2>
              <Dropdown
                className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                value={horizontalAlignment}
                options={alignments}
                target="option"
                placeholder="alignment"
                name="HL"
                width={150}
                height={40}
                click={(value) => {
                  dispatch(
                    updateTableHorAlignment({
                      table: RRIP_TB,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">Set Border</h2>
              <Dropdown
                className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                value={border}
                options={isTrueOrFalse}
                target="option"
                placeholder="border"
                width={150}
                height={40}
                click={(value) => {
                  dispatch(
                    updateTableBorder({
                      table: RRIP_TB,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
                style={
                  border === "true"
                    ? { backgroundColor: "#083344", color: "#f8f9fa" }
                    : {}
                }
              />
            </div>
            <div>
              <h2 className="pb-1">Set Text Font</h2>
              <Dropdown
                className="bg-dark-700 rounded h-[30px] outline-none px-2 cursor-pointer"
                value={font}
                options={fonts}
                target="option"
                width={250}
                height={40}
                click={(value) => {
                  dispatch(
                    updateTableTextFont({
                      table: RRIP_TB,
                      value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              />
            </div>
            <div>
              <h2 className="pb-1">Set Text Size</h2>
              <Input
                className="h-[40px] w-[100px]"
                value={fontSize}
                type="text"
                blur={(e) =>
                  dispatch(
                    updateTableTextSize({
                      table: RRIP_TB,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  )
                }
                change={(e) => setFontSize(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-wrap gap-4 pb-4">
            <div>
              <h2 className="pb-1">Left Margin</h2>
              <Input
                className="h-[40px] w-[150px]"
                value={marginLeft}
                placeholder="Left Margin"
                blur={(e) =>
                  dispatch(
                    setTableCellMargin({
                      loc: "left",
                      table: RRIP_TB,
                      doc: RRIP_DOC,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  )
                }
                change={(e) => setMarginLeft(e.target.value)}
                type="text"
              />
            </div>
            <div>
              <h2 className="pb-1">Right Margin</h2>
              <Input
                className="h-[40px] w-[150px]"
                value={marginRight}
                placeholder="right margin"
                blur={(e) =>
                  dispatch(
                    setTableCellMargin({
                      loc: "right",
                      table: RRIP_TB,
                      doc: RRIP_DOC,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  )
                }
                change={(e) => setMarginRight(e.target.value)}
                type="text"
              />
            </div>
            <div>
              <h2 className="pb-1">Top Margin</h2>
              <Input
                className="h-[40px] w-[150px]"
                value={marginTop}
                placeholder="top margin"
                blur={(e) =>
                  dispatch(
                    setTableCellMargin({
                      loc: "top",
                      table: RRIP_TB,
                      doc: RRIP_DOC,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  )
                }
                change={(e) => setMarginTop(e.target.value)}
                type="text"
              />
            </div>
            <div>
              <h2 className="pb-1">Bottom Margin</h2>
              <Input
                className="h-[40px] w-[150px]"
                value={marginBottom}
                placeholder="bottom margin"
                blur={(e) =>
                  dispatch(
                    setTableCellMargin({
                      loc: "bottom",
                      table: RRIP_TB,
                      doc: RRIP_DOC,
                      value: e.target.value,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  )
                }
                change={(e) => setMarginBottom(e.target.value)}
                type="text"
              />
            </div>
            <div>
              <h2 className="pb-1">Row</h2>
              <button
                className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
                onClick={() => {
                  dispatch(
                    addRowToTable({
                      table: RRIP_TB,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              >
                Row
              </button>
            </div>
            <div>
              <h2 className="pb-1">Column</h2>
              <button
                className="px-3 py-1 bg-cyan-950 text-light-500 h-[40px]"
                onClick={() => {
                  dispatch(
                    addColumnToTable({
                      table: RRIP_TB,
                      layoutEle:
                        SIPHF === "0_H" || SIPHF === "0_F" ? SIPHF : undefined,
                    })
                  );
                }}
              >
                Column
              </button>
            </div>
          </div>
        </>
      ) : tableStatus === "xt" ? (
        <div className="flex flex-col">
          <XtControls path={RRIP_TB} dynEle={dynEle} operator="user" />
          <XtControls path={RRIP_TB} dynEle={dynEle} operator="manager" />
        </div>
      ) : tableStatus === "mxt" ? (
        <div className="flex flex-col">
          <MxtControls path={RRIP_TB} dynEle={dynEle} operator="user" />
          <MxtControls path={RRIP_TB} dynEle={dynEle} operator="manager" />
        </div>
      ) : tableStatus === "jsfun" ? (
        <div className="flex flex-col pb-[80px]">
          <JsFunControls path={RRIP_TB} dynEle={dynEle} />
        </div>
      ) : (
        <></>
      )}
      {/* </RetractableDiv> */}
      <div className="mb-2">
        <Outlet />
      </div>
    </section>
  );
};

export default TableProperties;
