export const getTableStyle = ({ tableProps, sectionProps, docProps }) => {
  const style = {};
  style.tableLayout = "auto";
  style.borderCollapse = "collapse";
  style.width = docProps.tableWidth.size;
  if (tableProps.width) {
    style.width = tableProps.width.size;
  }
  if (tableProps.alignment) {
    switch (tableProps.alignment) {
      case "left":
        style.marginRight = "auto";
        break;
      case "right":
        style.marginLeft = "auto";
        break;
      case "center":
        style.marginLeft = "auto";
        style.marginRight = "auto";
        // style.marginTop = "auto";
        // style.marginBottom = "auto";
        break;
      case "both":
        style.marginLeft = "auto";
        style.marginRight = "auto";
        break;
    }
  }
  style.border = "0px solid black";
  // style.width = "100%";
  // if (tableProps !== undefined && Object.keys(tableProps)?.length > 0) {
  //   Object.keys(tableProps).map((prop) => {
  //     style.tableLayout = "auto";
  //     style.borderCollapse = "collapse";
  //     // Table Properties
  //     if (prop === "width") {
  //       if (tableProps.width.type === "pct") {
  //         style.width = tableProps.width.size;
  //       } else if (tableProps.width.type === "dxa") {
  //         style.width = `${dxaToPx(parseFloat(tableProps.width.size))}px`;
  //       }
  //     }
  //     if (prop === "alignment") {
  //       switch (tableProps.alignment) {
  //         case "center":
  //           style.marginLeft = "auto";
  //           style.marginRight = "auto";
  //           break;
  //         case "left":
  //           style.marginRight = "auto";
  //           break;
  //         case "right":
  //           style.marginLeft = "auto";
  //           break;
  //       }
  //     }
  //     // if (prop === "borders") {
  //     //   style.border = "1px solid black";
  //     // } else {
  //     //   style.border = "none";
  //     // }
  //     style.border = "none";
  //     if (prop === "shading") {
  //       style.backgroundColor = `#${tableProps.shading.color}`;
  //       style.color = `#${tableProps.shading.fill}`;
  //     }
  //   });
  //   // console.log(style);
  //   return style;
  // } else {
  //   return style;
  // }
  return style;
};
