import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./slices/apiSlice";
// import cartSliceReducer from "./slices/cartSlice";
import authReducer from "./slices/authSlice";
// import productReducer from "./slices/productSlice";
import utilReducer from "./slices/utilSlice";
import documantReducer from "./slices/documentSlice";
import internalDependencyReducer from "./slices/internalDependencySlice";
import documentsReducer from "./slices/docsSlice";
import optionsReducer from "./slices/optionsSlice";
import agencyReducer from "./slices/agency";
import agneciesReducer from "./slices/agencies";
import categoriesReducer from "./slices/categories";
import userReducer from "./slices/userSlice";
import formsReducer from "./slices/formsSlice";
import elementReducer from "./slices/elementSlice";
// import entryFormReducer from "./slices/entryForm";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    // cart: cartSliceReducer,
    auth: authReducer,
    // product: productReducer,
    util: utilReducer,
    user: userReducer,
    document: documantReducer,
    internalDependency: internalDependencyReducer,
    docs: documentsReducer,
    options: optionsReducer,
    agency: agencyReducer,
    agencies: agneciesReducer,
    categories: categoriesReducer,
    forms: formsReducer,
    element: elementReducer,
    // entryForm: entryFormReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

export default store;
